import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';
import {TabLinkify} from '../Generic/LinkifyComponent';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  link: {
    margin: theme.spacing.unit,
    color: theme.palette.primary[600],
  },
});

class ReadMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  render() {
    const {
      text,
      maxLen,
      expandText,
      collapseText,
      variant,
      component,
      className,
      classes,
    } = this.props;

    const display =
      text.length > maxLen && !this.state.expanded ? (
        <span>
          <Typography className={className} variant={variant} component={component} align='left' gutterBottom>
          {text
            .slice(0, text.indexOf(' ', maxLen) !== -1 ? text.indexOf(' ', maxLen) : maxLen)
            .trim()}
          </Typography>
          <a
            href="#"
            className={classes.link}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ expanded: true });
            }}
          >
            <Typography className={className} variant={variant} component={component} align='left' gutterBottom>
              {expandText}
            </Typography>
          </a>
        </span>
      ) : (
        <Typography className={className} variant={variant} component={component} align='left' gutterBottom>
          {text}
        </Typography>
      );
    return (
      <span>
        {display}
        {this.state.expanded && (
          <span>
            {' '}
            <a
              className={classes.link}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ expanded: false });
              }}
            >
              <Typography className={className} variant={variant} component={component} align='left' gutterBottom>
                {collapseText}
              </Typography>
            </a>
          </span>
        )}
      </span>
    );
  }
}

ReadMore.propTypes = {
  text: PropTypes.string.isRequired,
  maxLen: PropTypes.number,
  expandText: PropTypes.string,
  collapseText: PropTypes.string,
  variant: PropTypes.string,
  component: PropTypes.string,
  className: PropTypes.string,
};

ReadMore.defaultProps = {
  maxLen: 200,
  expandText: '... more',
  collapseText: 'less',
  variant: 'title',
  component: 'p',
};

const useStyles = makeStyles(theme => ({
  clampLines: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical',
  }
})
);

export const Clamp = function(props){
  const inputRef = React.useRef();
  const [Ready, setReady] = React.useState(false);
  const [Hidden, setHidden] = React.useState(true);
  const [JustChildren, setJustChildren] = React.useState(props.jschild);
  const classes = useStyles();

  useEffect(() => {
    if (inputRef.current){
      setReady(true)
    } else {

    }
  }, [Hidden, inputRef]);

  const isEllipsisActive = (refrence) => {
    let e = refrence.current;
    if (!e){
      return false;
    }
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  }

  const {children} = props;

  if (JustChildren){
    return (<TabLinkify>{children}</TabLinkify>)
  }

  return (
    <>
    { Hidden ? (
      <TabLinkify>
      <Typography ref={inputRef} className={classes.clampLines}  variant='body1' align='left'>
        {children}
      </Typography>
      {isEllipsisActive(inputRef) && (
        <>
        <strong onClick={e => setHidden(!Hidden)}>[Click here to see more...]</strong>
        <br/>
        </>
      )}
      </TabLinkify>
      ):(
      <TabLinkify>
      <Typography ref={inputRef} variant='body1' align='left'>
        {children}
      </Typography>
      {/*
      {isEllipsisActive(inputRef) && (
        <>
        <strong onClick={e => setHidden(!Hidden)}>[Click here to see less...]</strong>
        <br/>
        </>
      )}
      */}
      </TabLinkify>
      )
    }
    </>
  );
}

export default withStyles(styles)(ReadMore);
