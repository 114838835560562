import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { graphql } from 'react-apollo';
import { Mutation } from "react-apollo";
import gql from 'graphql-tag';


import Linear from '../Loading/Linear.js';
import ToolTipStyle from '../Generic/ToolTipStyle';
import Note, { AddNote } from './PrivateNote'
import CategoryPill from './CategoryPill'
import AddPrintQueue from './PrintQueueButton'
import BookmarkToggle from './Modals/Bookmark'
import ShareResource from './Modals/Share'
import ReadMore from '../Generic/ReadMore'
import {TabLinkify} from '../Generic/LinkifyComponent';
import AddComment from '../GenComments/AddComment.js';
import { DisplayComments } from '../GenComments/Comments.js';
import { GenCommentsFragment } from '../GenComments/Fragments';

var qs = require('qs');


export const ResourceDetailQuery = gql`
  query DetailResourceQuery($id:ID) {
    resource(id: $id) {
      categories{
        edges{
          node{
            category
            id
          }
        }
      }
      privateNotes{
        id
        modifiedAt
        createdAt
        note
      }
      groups{
        id
        name
        slug
      }
      genComments{
        ...genComments
      }
      id
      internalId
      name
      description
      emailContact
      phone
      website
      streetAddress
      city
      state
      latitude
      longitude
      zipCode
      modifiedAt
      createdAt
      bookmarked
      inprintqueue
      keywords
    }
  }
${GenCommentsFragment}
`;

const styles = theme => ({
  wrapper: {
    maxWidth: 800,
    margin: "0 auto",
    align: "center",
  },
  card: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    width: '100%',
  },
  comment: {
    borderColor: `${theme.palette.primary[500]}`,
  },
  button: {
    margin: theme.spacing.unit,
  },
  smallicons: {
  },
  newline:{
    whiteSpace: 'pre-wrap',
  },
  title: {
    margin: theme.spacing.unit,
    color: theme.palette.primary[600],
  },
  pos: {
    marginBottom: 12,
  },
  spancontainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  spanacross: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
  tooltip: ToolTipStyle(theme),
});

class DetailResource extends React.Component  {
  ViewSimilar = (e) => {
    e.preventDefault();
    const resource = this.props.data.resource.categories.edges.map(item => item.node.category)
    const qst = qs.stringify({
      resource: resource,
      page: 1,
    });
    console.log(qst)
    // Quick and dirty hack
    this.props.history.push('/');
    this.props.history.push(`/search/resource#${qst}`);
    window.location.reload(true);
  }
  render() {
    const { data, classes, cbRefetch, showall } = this.props;

    if (data.loading) {
      return <Linear />;
    }

    if (data.error) {
      return <p>{data.error.message}</p>;
    }
    let description;
    if (showall){
      description = (
        <Typography className={classes.newline} >
          { data.resource.description ? data.resource.description : 'No description provided' }
        </Typography>
      );
    } else {
      description = (
        <ReadMore variant={'body1'} className={classes.newline} text={ data.resource.description ? data.resource.description : 'No description provided' } />
      );
    }

    return (
      <TabLinkify>
      <Card className={classes.card}>
        <span className={classes.spancontainer}>
          <span
            className={classes.fleft}>
            <Typography variant='body1' align='left' gutterBottom className={classes.title}>
              <Link to={`/detail/r/${data.resource.id}`} className={classes.title}>
                { data.resource.name }
              </Link>
            </Typography>
          </span>
        </span>
        <span className={classes.spanacross} >
          <span
            className={classes.fleft}>
            <BookmarkToggle resource={data.resource} typename={data.resource.__typename}/>
            {/* <ShareResource resource={data.resource}/> */}
            <AddPrintQueue resource={data.resource} typename="ResourceType"/>
            <Tooltip classes={classes} title="Report issues" placement="bottom">
            <Link to={`/correction/resource/${data.resource.id}`}>
             <IconButton color="secondary" className={classes.button} aria-label="Add an alarm">
                  <Icon>
                    flag
                  </Icon>
              </IconButton>
            </Link>
            </Tooltip>
          </span>
        </span >
        {data.resource.categories && data.resource.categories.edges.map((item, i ) => {
          if (i < 3){
            return (
              <span >
                <CategoryPill key={i} category={item.node.category} />
              </span>
            );
          }
          return null
        })}
        <span >
        {description}
        <br />
        <Typography variant='body1' align='left' gutterBottom>
          Contact Info:
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          Phone: {data.resource.phone}
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          Address: {data.resource.streetAddress}
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          Website:
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          {data.resource.website ?
            data.resource.website
            : 'not provided'}
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          Email:
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          {data.resource.email ?
            data.resource.email
            : 'n/a'}
        </Typography>
        </span >
        <span
          className={classes.spancontainer}>
          <span
            className={classes.spanacross}
          >
          <Typography
            component="p"
            align='left'
            gutterBottom>
            created {moment(data.resource.createdAt).fromNow()} | updated {moment(data.resource.modifiedAt).fromNow()}
          </Typography>
          </span>
          <span
            className={classes.spanacross}>
          <Typography
            color="primary"
            variant='body1'
            onClick={this.ViewSimilar}
            align='right'
            gutterBottom>
            View Similar Resources
          </Typography>
          </span>
        </span>

        {data.resource.privateNotes && data.resource.privateNotes.map(item => {
          return (<Note item={item} cb={cbRefetch}/>);
        })}
        {/*<AddNote resource={ data.resource.id }  cb={cbRefetch}/>*/}
        <AddComment
          first={data.resource.genComments.length}
          owner={data.resource.id}
          refetches={['PagedResourceSearch', 'DetailResourceQuery', 'TimeLine']}>
          {replyTo => (
          <DisplayComments
            classes={classes}
            refetches={['PagedResourceSearch', 'DetailResourceQuery', 'TimeLine']}
            comments={data.resource.genComments}
            replyTo={replyTo} />
          )}
        </AddComment>
      </Card>
      </TabLinkify>
    );
  }
}

DetailResource.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ResourceDetail = graphql(ResourceDetailQuery,{
  options: ({ match }) => {
    return {
      variables: {
        id: match.params.id,
      },
    };
  },
})(DetailResource)

const DetachedResource = withRouter(withStyles(styles)(DetailResource));

export {DetachedResource};
export default withRouter(withStyles(styles)(ResourceDetail));
