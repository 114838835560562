import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';

import Divider from '@material-ui/core/Divider';

import gql from 'graphql-tag';

import Comments from './Comments.js';
import AddComment from './AddComment.js';
import ProfileChip from 'src/components/Profile/ProfileChip.js';
import {InviteLink} from '../Invitation/Invitation';
import EditPanel from './EditPanel';
import CategoryPill from '../Resource/CategoryPill'
import LikeButton from '../LikeSomething/Like';
import WhoLiked from '../LikeSomething/WhoLModal';
import WhoFollowed from '../FollowSomething/WhoFModal';
import FollowButton, {Followed} from '../FollowSomething/Follow';
import {TabLinkify} from '../Generic/LinkifyComponent';
import {ReplyTo} from '../Generic/ReplyTo';
import {JoinTo} from '../Groups/Invite/JoinTo.js';
import {StyledTooltip} from '../Generic/ToolTipStyle';
import {Clamp} from '../Generic/ReadMore.js';

const styles = theme => ({
  card: {
    zIndex: 1100,
    overflow: 'visible',
    width: '100%',
    padding: theme.spacing.unit * 2,
  },
  comment: {
    borderColor: `${theme.palette.primary[500]}`,
  },
  blue: {
    color: 'blue',
  },
  topBlock: {
    display: 'flex',
  },
  urgent: {
    colorColor: 'red',
  },
  label: {
    textTransform: 'none',
  },
  cataction: {
    display: 'block',
  },
  newline:{
    whiteSpace: 'pre-wrap',
    display: 'inline-block',
    width: '100%',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  highAlert: {
    fontSize: theme.typography.fontSize * 3,
    marginRight: theme.spacing.unit * 2,
  },
  leftBlock:{
    display:'flex',
    justifyContent: 'space-between',
    width: '50%',
  },
  rightBlock: {
    display:'flex',
    justifyContent: 'flex-end',
    width: '50%',
    fallbacks: [{justifyContent: 'right'}]
  },
  segment: {
    display: 'flex',
    width: '100%',
    borderWidth: theme.spacing.unit / 6,
    margin: theme.spacing.unit / 2,
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  }
});


const defaultBoxProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 2,
};


export function TrunkatedComments (props) {
  const [Hidden, setHidden] = React.useState(false)

  const { activity, classes, replyTo } = props;
  let comments = activity.subject.comments ? activity.subject.comments: [];

  if (comments.length < 4 || Hidden ){
    return (
      <>
      {activity.subject.comments && activity.subject.comments.map(item => {
      return (
        <Box className={classes.comment} {...defaultBoxProps}>
          <Comments comment={item} replyTo={replyTo}/>
        </Box>
      );
      })}
      </>
    )

  } else {
    let trimmed = comments.slice(-2);
    return (
      <>
      <Typography className={classes.blue} style={{width: '100%'}} variant="body1" align='left' onClick={e => setHidden(!Hidden)}>
        <strong>View {comments.length -2 } more replies ...</strong>
      </Typography>
      {trimmed && trimmed.map(item => {
      return (
        <Box className={classes.comment} {...defaultBoxProps}>
          <Comments comment={item} replyTo={replyTo}/>
        </Box>
      );
      })}
      </>
    )
  }
}


class ActivityCard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    };
  }

  render () {
    const { key, className, classes, activity } = this.props;
    let counted_likes;
    if (activity.subject.likesCount === 0){
      counted_likes = null;
    } else {
      counted_likes = (
      <WhoLiked key={key} instance={activity.subject.id}>
        <Icon
          color={"primary"}
          fontSize={'small'}>
          thumb_up
        </Icon>
        {activity.subject.likesCount}
      </WhoLiked>
      );
    }

    let counted_follows;
    if (activity.subject.followsCount === 0){
      counted_follows = null;
    } else {
      counted_follows = (
      <WhoFollowed key={key} instance={activity.subject.id}>
        <Followed followed={activity.subject.followed} />
        {activity.subject.followsCount}
      </WhoFollowed>
      );
    }

    return(
      <Box className={className}>
        <TabLinkify>
          <Card className={classes.card}>
            <AddComment panel={activity.subject.id}>
              {replyTo => (
                <React.Fragment>
                  <EditPanel
                    panel={activity.subject}
                    owner={activity.subject.author.username}
                    refetches={['RepliedPanels','AuthoredPanels','FollowedPanels','DetailPanel', 'TimeLine', 'UnreadNoti']}
                    >
                  <div>
                  <div
                    style={{
                      display: 'flex', alignContent: 'center', alignCenter: 'center',
                      justifyContent: 'space-between', flexWrap: 'wrap',
                    }}
                    >
                    <ProfileChip user={activity.subject.author}/>
                    <Typography  className={classes.topBlock} component="p" align='right'>
                      <StyledTooltip title="Urgent Request" >
                        <Icon className={classes.highAlert} color="error">
                          report_problem
                        </Icon>
                      </StyledTooltip >
                      <Link style={{flex:1}} to={`/detail/p/${activity.subject.id}`}>
                        Posted {moment(activity.subject.createdAt).fromNow()}
                      </Link>
                    </Typography>
                  </div>
                  <CardContent>
                    <Typography variant="body1" className={classes.newline} align='left'>
                      <Clamp>
                      { activity.subject.text }
                      </Clamp>
                      {activity.subject.zipCode &&
                        <React.Fragment>
                        <Icon>
                          location_on
                        </Icon>
                        {activity.subject.zipCode}
                        </React.Fragment>
                      }
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cataction}>
                    <div style={{display:'flex', flexWrap: 'wrap'}}>
                      {activity.subject.categories && activity.subject.categories.edges.map((item, i ) => {
                      return (
                        <span >
                          <CategoryPill key={i} category={item.node.category} />
                        </span>
                      );
                      })}
                    </div>
                  { activity.subject.group && (
                    <div className={classes.segment}>
                    <Typography style={{width: '100%'}} variant="body1" align='left'>
                      <strong>Originally posted at the following group:{' '}
                        <Link to={`/detail/group/${activity.subject.group.id}`}>
                          { activity.subject.group.name }
                        </Link>
                      </strong>
                    </Typography>
                    </div>
                  )}
                  <div className={classes.segment}>
                    <span className={classes.leftBlock}>
                      <LikeButton
                        refetches={['RepliedPanels','AuthoredPanels','FollowedPanels','DetailPanel', 'TimeLine', 'UnreadNoti']}
                        instance={activity.subject.id}
                        liked={activity.subject.liked}
                        count={activity.subject.likesCount} />
                      <FollowButton
                        refetches={['RepliedPanels','AuthoredPanels','FollowedPanels','DetailPanel', 'TimeLine', 'UnreadNoti']}
                        instance={activity.subject.id}
                        followed={activity.subject.followed}
                        liked={activity.subject.liked}
                        count={activity.subject.likesCount} />
                      <ReplyTo onClick={replyTo(activity.subject.author.username, activity.subject.id)} />
                      { activity.subject.group && (<JoinTo group={activity.subject.group} />) }
                      <InviteLink/>
                    </span>
                    <span className={classes.rightBlock}>
                      {counted_follows}
                      {counted_likes}
                    </span>
                  </div>
                  </CardActions>
                  </div>
                </EditPanel>
                <Divider />
                <TrunkatedComments classes={classes} activity={activity} replyTo={replyTo} />
                </React.Fragment>
              )}
            </AddComment>
          </Card>
        </TabLinkify>
      </Box>
    );
  }
}

export default withStyles(styles)(ActivityCard);
