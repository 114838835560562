import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';

import AsyncSelect from 'react-select/async';
import Select from 'react-select';
//import 'react-select/dist/react-select.css';
import debounce from 'debounce';

import { graphql, Query } from 'react-apollo';
import { Mutation } from "react-apollo";
import gql from 'graphql-tag';

import ToolTipStyle from '../../Generic/ToolTipStyle';
import {UserQuery} from '../../UserSearch/UserSearchComponent'
import { client } from '../../../utils/makeApolloClient';

const addResourceShareMut = gql`
  mutation ManyShares ($resId: ID!, $tt:[InputSelect]!, $message: String!) {
    addResourceShare (multiUser: $tt, resId: $resId, message: $message){
      status
    }
  }
`;

const styles = theme => ({
  root: {
    width:'100%',
    height:'100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  volume: {
    minHeight: theme.spacing.unit * 30,
  },
  tooltip: ToolTipStyle(theme),
});

class Option extends React.Component {
  handleClick = event => {
    this.props.onSelect(this.props.option, event);
  };

  render() {
    const { children, isFocused, isSelected, onFocus } = this.props;

    return (
      <MenuItem
        onFocus={onFocus}
        selected={isFocused}
        onClick={this.handleClick}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {children}
      </MenuItem>
    );
  }
}

class SelectWrapped extends React.Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(value) {
    console.log(value.toLowerCase())
    return value.toLowerCase();
  }

  async getOptions(input) {
    if (input === ''){
      var promise1 = new Promise(function(resolve, reject) {
          setTimeout(resolve, 100, null);
      });
      return promise1;
    }
    const options = await client.query({
      query: UserQuery,
      variables: {
        terms: [input],
        page: 1,
        perpage: 50,
      },
    }).then( result => {
      console.log(result, 'results here');
      const options = result.data.userEsSearch.users.map(o => ({
        value: o.username,
        label: o.fullName,
      }));
      return options
    });
    return options
  }


  render() {
  const { classes, value, name, onChange, ...other } = this.props;
  return (
      <AsyncSelect
        name="resource-search"
        className={classes.root}
        isSearchable
        clearable
        value={value}
        isMulti={ true }
        noOptionsMessage={()=>(<Typography>{'No results found'}</Typography>)}
        filterOptions={options => options.filter(o => !/Create option/i.test(o.label))}
        onChange={ onChange }
        autoload={false}
        onInputChange={this.onInputChange}
        loadOptions={this.getOptions}
      />
  );
}
}


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class UtilityDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      userSelection: [],
      pvmessage: '',
    };
    this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.EventDrivenChange = this.EventDrivenChange.bind(this);
    this.debouncedSearch = debounce(this.search, 550);
  }

  search = () => {
    return
  }

  handleClose = () => {
    this.props.onClose('test');
  };

  EventDrivenChange = prop => event => {
    console.log(event.target)
    this.setState({ [prop]: event.target.value, isBlocking:true });
  };

  handleChange = name => value => {
    console.log(name, value)
    this.setState({
      [name]: value,
    });
    // TODO: Direct to search results page
    this.debouncedSearch()
  };

  render() {
    const { classes, onClose, resource, ...other } = this.props;

    return (
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        scroll={'body'}
        maxWidth={'md'}
        onClose={this.handleClose} aria-labelledby="Utility-dialog-title" {...other}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="Utility-dialog-title">Share with other users</DialogTitle>
        <DialogContent>
          <div className={classes.volume}>
            <Input
              fullWidth
              inputComponent={SelectWrapped}
              value={this.state.userSelection}
              placeholder="Select category"
              name="react-select-chip"
              endAdornment={
                <InputAdornment position="end">
                <IconButton>
                  <Icon>
                    search
                  </Icon>
                </IconButton>
                </InputAdornment>}
              inputProps={{
                onChange: this.handleChange('userSelection'),
                classes,
                instanceId: 'react-select-chip',
                id: 'react-select-chip',
                simpleValue: true,
                fullWidth: true,
              }}
            />
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="Private message">Private Message</InputLabel>
              <Input
                id="comment"
                type={'text'}
                fullwidth={true}
                multiline={true}
                rows={4}
                className={classes.formControl}
                value={this.state.pvmessage}
                onChange={this.EventDrivenChange('pvmessage')}
              />
            </FormControl>
            <Divider/>
            <Mutation mutation={addResourceShareMut}>
              {(shareMany, { data }) => (
                <Button
                  variant="outlined"
                  raised
                  color="primary"
                  className={classes.button}
                  onClick={ e => {
                    shareMany({
                      variables: {
                        resId: resource.id,
                        tt: this.state.userSelection,
                        message: this.state.pvmessage,
                      },
                      refetchQueries: ['searchResourcesPaged', 'searchBookmarksPaged'],
                    })
                    .then( data =>
                      this.handleClose()
                    );
                  }}
                >
                  Share
                </Button>
            )}
          </Mutation>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

UtilityDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const UtilityDialogWrapped = withStyles(styles)(UtilityDialog);

class ManyButton extends React.Component {
  constructor(props){
    super(props);
    this.state = {
          open: false,
        };
  }

  handleClickOpen = () => {
    this.setState({ open: true, });
  };

  handleClose = value => {
    this.setState({ open: false });
  };

  render() {
    const { classes, resource } = this.props;
    return (
      <React.Fragment>
        <Tooltip classes={classes} title="Share this resource" placement="bottom">
        <IconButton
          color="secondary"
          className={classes.button}
          aria-label="Add an alarm"
          onClick={this.handleClickOpen}
        >
          <Icon>
            share
          </Icon>
        </IconButton>
        </Tooltip>
        <UtilityDialogWrapped
          resource={resource}
          open={this.state.open}
          onClose={this.handleClose}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ManyButton);
