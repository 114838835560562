import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { emphasize, fade, darken } from '@material-ui/core/styles/colorManipulator';

const styles = theme => {
  return {
    messageContainer: {
      padding: theme.spacing.unit / 2,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    info: {
      color: darken('#fff', .10),
      backgroundColor: 'green',
    },
    warning: {
      color: darken(theme.palette.primary.dark, .55),
      backgroundColor: 'yellow',
    },
    error: {
      color: darken('#fff', .10),
      backgroundColor: 'red',
    },
    child: {
      backgroundColor: 'inherit',
    },
    jsRight: {
      marginLeft: 'auto',
      justifyContent: 'flex-end',
    },
  }
}

const SiteMessage = (props) => {

  const [Visible, setVisible] = React.useState(false);
  const [Text, setText] = React.useState(`If you need to update your primary email, you may do so in \'${'My Settings'}\'.`);
  const {classes} = props;

  return (
    <>
    { Visible &&
      (
        <div
          className={`${classes.messageContainer} ${classes.info}`}>
          <span>
            {Text}
          </span>
          <span className={classes.jsRight} onClick={e => setVisible(!Visible)}>
            X
          </span>
        </div>
      )
    }
    </>
  )
}

export default withStyles(styles)(SiteMessage);
