import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import UserList from './UserList';

const styles = theme => ({
  button: {
  },
});


class UserScrollDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = async () => {
    const {cb} = this.props;
    if(cb){
      await cb();
    }
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, content, children, title, scroll } = this.props;
    return (
      <React.Fragment>
        <Button className={classes.button} size="small" onClick={this.handleClickOpen}>
          {children}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <UserList content={content} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

UserScrollDialog.defaultProps = {
  scroll: 'paper',
  title: 'Users',
};

export default withStyles(styles)(UserScrollDialog);
