import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import ProfileChip from 'src/components/Profile/ProfileChip.js';
import CategoryPill from '../Resource/CategoryPill'
import Share from './Share';
import {InviteLink} from '../Invitation/Invitation';
import LikeButton from '../LikeSomething/Like';
import WhoLiked from '../LikeSomething/WhoLModal';
import AttachmentButton from '../Generic/Attachment';
import AttachmentPreview from '../Generic/AttachmentPreview';
import AddComment from '../GenComments/AddComment.js';
import { DisplayComments } from '../GenComments/Comments.js';
import { GenCommentsFragment } from '../GenComments/Fragments';
import {TabLinkify} from '../Generic/LinkifyComponent';
import Hide from '../Generic/Hide';

const JobQuery = gql`
  query DetailJob($id:ID!){
    job(id: $id){
    author {
      id
      username
      fullName
      institution
      position
      profilePicSm
    }
    categories {
      edges {
        node {
          id
          category
        }
      }
    }
    attachments{
      attachment
      previewSm
      previewMd
    }
    genComments{
      ...genComments
    }
    createdAt
    modifiedAt
    hidden
    location
    organization
    applicationLink
    title
    description
    id
    likesCount
    liked
    }
  }
${GenCommentsFragment}
`;

const styles = theme => ({
  block: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    width: '100%',
  },
  newline:{
    whiteSpace: 'pre-wrap',
  },
  card: {
    minWidth: 275,
  },
  comment: {
    borderColor: `${theme.palette.primary[500]}`,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  segment: {
    display: 'flex',
    width: '100%',
    borderWidth: theme.spacing.unit / 6,
    margin: theme.spacing.unit / 2,
  },
  leftBlock:{
    display:'flex',
    justifyContent: 'space-between',
    width: '50%',
  },
  rightBlock: {
    display:'flex',
    justifyContent: 'flex-end',
    width: '50%',
    fallbacks: [{justifyContent: 'right'}]
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  }
});

function DetailJob(props) {
  const { key, data, classes, className: BoxClass } = props;

  if (data.loading) {
    return <Linear />;
  }

  if (data.error) {
    return <p>{data.error.message}</p>;
  }

  let counted_likes;
  if (data.job.likesCount === 0){
    counted_likes = null;
  } else {
    counted_likes = (
    <WhoLiked key={key} instance={data.job.id}>
      <Icon
        color={"primary"}
        fontSize={'small'}>
        thumb_up
      </Icon>
      {data.job.likesCount}
    </WhoLiked>
    );
  }

  return (
    <Box className={BoxClass}>
      <TabLinkify>
      <Card className={classes.card}>
        <CardContent>
          <div style={{ display:'flow-root'}}>
            <span className={classes.block}>
              <ProfileChip
                user={data.job.author }
              />
              <Typography style={{width: '100%', flex: 1}} component="p" align='right'>
                <Link to={`/detail/opportunity/${data.job.id}`}>
                  Posted {moment(data.job.createdAt).fromNow()}
                </Link>
              </Typography>
          </span>
          </div>
          <Divider />
            <div className={classes.segment}>
              <span className={classes.leftBlock}>
                <LikeButton
                  refetches={['DetailJob', 'newJob', 'AllJobs']}
                  instance={data.job.id}
                  liked={data.job.liked}
                  count={data.job.likesCount} />
                <InviteLink/>
                <Share item={data.job} />
              </span>
              <span className={classes.rightBlock}>
                <div style={{
                    height: '64px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                { counted_likes }
                </div>
              </span>
            </div>
            <div style={{display:'flex', flexWrap: 'wrap'}}>
            {data.job.categories && data.job.categories.edges.map((item, i ) => {
            return (
              <span >
                <CategoryPill key={i} category={item.node.category} />
              </span>
            );
            })}
            </div>
            <div >
              <Typography className={classes.newline} component='body1' align='left'>
                <strong>Title:</strong> {data.job.title}
              </Typography> <br />
            </div>
            { data.job.organization &&
              <div>
                <Typography className={classes.newline} component='body1' align='left'>
                  <strong>Organization:</strong> {data.job.organization}
                </Typography> <br />
              </div>
            }
            { data.job.location &&
              <div>
                <Typography className={classes.newline} component='body1' align='left'>
                  <strong>Address:</strong> {data.job.location}
                </Typography> <br />
              </div>
            }
            <Hide jschild={props.jschild} >
              <Typography className={classes.newline} component="body1" align='left'>
                <strong>Description:</strong><br />{data.job.description}
              </Typography> <br />
              { data.job.applicationLink &&
                <div>
                  <Typography className={classes.newline} component='body1' align='left'>
                    <strong>Application:</strong> {data.job.applicationLink}
                  </Typography> <br />
                </div>
              }
            <div className={classes.segment}>
              { data.job.attachments &&
                <AttachmentPreview attachments={data.job.attachments} />
              }
            </div>
            { data.job.group && (
              <div className={classes.segment}>
              <Typography style={{width: '100%'}} variant="body1" align='left'>
                <strong>Originally posted at the following group:{' '}
                  <Link to={`/detail/group/${data.job.group.id}`}>
                    { data.job.group.name }
                  </Link>
                </strong>
              </Typography>
              </div>
            )}
            <br/>
            <AddComment
              first={data.job.genComments.length}
              owner={data.job.id}
              refetches={['DetailJob', 'newJob', 'AllJobs', 'TimeLine']}>
              {replyTo => (
              <DisplayComments
                classes={classes}
                refetches={['DetailJob', 'newJob', 'AllJobs', 'TimeLine']}
                comments={data.job.genComments}
                replyTo={replyTo} />
              )}
            </AddComment>
          </Hide>
        </CardContent>
      </Card>
      </TabLinkify>
    </Box>
  );
}

DetailJob.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Job = graphql(JobQuery,{
  options: ({ match }) => {
    return {
      variables: {
        id: match.params.id,
      },
    };
  },
})(DetailJob)

export const DetachedDetailJobs = withStyles(styles)(DetailJob);

export default withStyles(styles)(Job);
