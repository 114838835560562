import React, { Component } from 'react';
import { withRouter, Redirect, Prompt, Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { graphql, ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';

const LoginMut = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user {
        username
      }
      csrf
      validation {
        status
        errors
      }
    }
  }
`;

function delete_cookie( name ) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

class LoginStub extends Component {
    constructor(props){
    super(props);
    this.state = {
      redirectToReferrer: false,
      isBlocking: false,
      errors: null,
      username: '',
      password: '',
      showPassword: false,
    };
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, isBlocking: true});
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handlePost = (client) => {
    const { username, password } = this.state;
    client.resetStore();
    this.props
      .mutate({
        variables: { username, password },
        refetchQueries: ['Me', 'Auth'],
      })
      .then(({ loading, error, data }) => {
        if (data.login.validation.status === 500) {
          this.setState({errors: data.login.validation.errors, redirectToReferrer: false})
          return true;
        }
        if (data.login.validation.status === 200) {
          const { from } = this.props.location.state || { from: { pathname: '/' } };
          this.setState({ redirectToReferrer: true });
          window.location.pathname = from.pathname;
          window.location.reload();
          return null
        }
        if (data.login.validation.status === 420) {
          return null;
        }
      })
      .catch((e) => {
        if( e){
          return null
        }
        window.location.reload();
      });
  };

  render() {
    const { classes } = this.props;
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer, errors } = this.state;
    const { isBlocking } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <ApolloConsumer>
      { client => (
      <div className={classes.wrapper}>
        <form className={classes.root} noValidate autoComplete="off">
          <div>
            { errors ? (
            <Typography color="error" type="headline" component="p">
              { errors.map(e => e)}
            </Typography>

            ): null}
            <Typography type="headline" component="p">
              You must log in to view the page at {from.pathname}
            </Typography>
            <Prompt
              when={isBlocking}
              message={location => `Are you sure you want to leave ${window.location.pathname}`}
            />
            <br />
                <FormControl className={classes.formControl}>
                  <Typography>Email (e.g. Jane.Doe@gmail.com)</Typography>
                  <InputLabel htmlFor="username"> * </InputLabel>
                  <Input
                    autoFocus
                    id="username"
                    type={'text'}
                    value={this.state.username}
                    onChange={this.handleChange('username')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton/>
                      </InputAdornment>
                    }
                    inputProps={{
                      className:classes.input,
                    }}
                  />
                </FormControl>
                <Divider/>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    value={this.state.password}
                    onChange={this.handleChange('password')}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        this.handlePost(client)
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.handleClickShowPasssword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{
                      className:classes.input,
                    }}
                  />
                </FormControl>
                <Divider/>
                <Button raised variant={'outlined'} color="primary" className={classes.button} onClick={e => this.handlePost(client)}>
                  Login
                </Button>
          </div>
        </form>
        <Link
          to={`/password/reset/email`}
        >
          Forgot Password
        </Link>
      </div>
      )}
      </ApolloConsumer>
    );
  }
}

const styles = theme => ({
  input : {
    paddingLeft: theme.spacing(1),
    borderColor: '',
    boxShadow: '',
    transition: theme.transitions.create(
      ['borderColor', 'boxShadow'],
      { duration: theme.transitions.duration.complex }
    ),
    '&:hover, &:active, &:focus': {
      borderColor: '#e4e4e4',
      boxShadow: `inset 4px 0 0 0 ${theme.palette.primary[500]}`,
    }
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  wrapper: {
    margin: "0 auto",
    align: "center",
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const Login = withStyles(styles)(graphql(LoginMut)(LoginStub));

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export { LoginMut };
export default withRouter(Login);
