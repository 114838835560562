import React from 'react';
import PropTypes from 'prop-types';

import FileViewer from 'react-file-viewer';

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const styles = {
  appBar: {
    position: 'relative',
  },
  fullsize: {
    width: '100%',
    height: '100%',
  },
  flex: {
    flex: 1,
  },
};

function Transition(props) {
  return <Slide direction="up" {...props}/>;
}

class ErrorSoDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
  }

  render() {
    const {children, type, attachment} = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <a href={attachment} type={`application/${type}`}>
          No Preview Available: Direct Download Only.
        </a>
      );
    }

    return children;
  }
}

class FullScreenDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onError(e) {
    e.preventDefault();
  }

  render() {
    const { classes, buttonwidth, attachment, children } = this.props;
    var type = attachment.split('.').pop();
    if (type.toLowerCase()=='pdf'){
      return (
        <Button style={{width:buttonwidth}} variant="outlined" color="primary" >
        <a style={{width:buttonwidth}} href={attachment} type="application/pdf" target="_blank">
          {children}
        </a>
        </Button>
      );
    }
    return (
      <React.Fragment>
        <Button style={{width:buttonwidth}} variant="outlined" color="primary" onClick={this.handleClickOpen}>
          {children}
        </Button>
        <Dialog
          id="pg-viewer"
          key={attachment}
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <div className={classes.fullsize}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <a href={attachment} download onClick={this.handleClose}>Download</a>
            </Toolbar>
          </AppBar>
          <ErrorSoDownload type={type.toLowerCase()} attachment={attachment}>
          <FileViewer
            fileType={type.toLowerCase()}
            filePath={attachment}
            errorComponent={() => ( <a href={attachment} download >Download</a>)}
            onError={this.onError}/>
          </ErrorSoDownload>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);
