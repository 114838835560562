import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PrintIcon from '@material-ui/icons/Print';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';


import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { Mutation } from "react-apollo";
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import ToolTipStyle from 'src/components/Generic/ToolTipStyle';
import Linear from 'src/components/Loading/Linear.js';

const PrintQueueQuery = gql`
  query PrintQueue {
    allPrintqueue{
      contentObject{
        ... on ResourceType{
          id
          name
        }
        ... on Service {
          service_id: id
          serviceName: name
          programId {
           id
           name
          }
        }
        __typename
      }
      user {
        id
      }
      createdAt
    }
  }
`;

const styles = theme => ({
  leftright: {
    MinWidth: '325px',
    MaxWidth: '350px',
  },
  menuheader: {
    backgroundColor: 'white',
  },
  badge: {
    top: 'auto',
  },
  red: {
    padding: theme.spacing.unit / 2,
  },
  tooltip: ToolTipStyle(theme),
});

const ITEM_HEIGHT = 48;

class PrintMenu extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, data } = this.props;

    if (data.error){
      return ( <p> error </p>);
    }

    if (data.loading) {
      return <Linear />;
    }

    return (
    <Badge
      color='default'
      badgeContent={
        <div className={(data.allPrintqueue.length ? classes.red : 'hidden')}>
        <strong>{ data.allPrintqueue.length }</strong>
        </div>
      }
      className={classes.badge}>
        <Tooltip classes={classes} title="Print Cart" placement="bottom">
          <IconButton
            aria-label="More"
            aria-owns={anchorEl ? 'long-menu' : null}
            aria-haspopup="true"
            color="inherit"
            onClick={this.handleClick}
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>
        <Menu
          className={classes.leftright}
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 6.5,
              width: 'auto',
            },
          }}
          >
          <ListSubheader className={classes.menuheader}>
            <Link to='/PrintQueue'>
              <MenuItem>
                <ListItemText>
                  View all
                </ListItemText>
              </MenuItem>
            </Link>
          </ListSubheader>
          {data.allPrintqueue.map(item => {
            if (item.contentObject === undefined){
              return null;
            }
            switch (item.contentObject.__typename) {
              case 'ResourceType': return (
                <Link to={`/PrintQueue`} >
                  <MenuItem key={item.contentObject.id} selected={false} >
                    {item.contentObject.name}
                  </MenuItem>
                </Link>
              );
              case 'Service': return (
                <Link to={`/PrintQueue`} >
                  <MenuItem key={item.contentObject.id} selected={false} >
                    {item.contentObject.programId.name} - {item.contentObject.serviceName}
                  </MenuItem>
                </Link>
              );
              default:
                return (
                  <React.Fragment>
                  </React.Fragment>
                )
          }})}
        </Menu>
    </Badge>
    );
  }
}

export {PrintQueueQuery};
export default withStyles(styles)(graphql(PrintQueueQuery)(PrintMenu));
