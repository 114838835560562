import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import NewInvite from './InvitationComponents';
// import InviteList from './ListInvitations.js';
import AcceptedTable from './AcceptedTable';
import PendingTable from './PendingTable';

import Linear from 'src/components/Loading/Linear';
import ToolTipStyle from '../Generic/ToolTipStyle';


const InvitesLeftQuery = gql`
  query InvPretty {
    invitesPretty {
      left
      pending {
        id
        invitee
        createdAt
      }
      accepted{
        id
        invitee
        inviteCode
        createdAt
        acceptedAt
      }
    }
  }
`;

const styles = theme => ({
  autoFlow: {
    overflowX: 'auto',
  },
  pushRight: {
    marginLeft: theme.spacing.unit ,
  },
  button: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 4,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit / 4,
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  tooltip: ToolTipStyle(theme),
});

export function InviteLinkProto(props) {
    const { classes } = props;
    return (
      <React.Fragment>
        <div className={classes.pushRight}>
        <Tooltip classes={classes} title="Invite" placement="bottom">
          <div className={classes.vertical}>
          <IconButton
            className={classes.button}
            aria-label="Invite someone."
            onClick={() =>{
                props.history.push("/invitations");
              }}
            >
            <Icon color={'secondary'} fontSize={'small'}
              >
              person_add
            </Icon>
          </IconButton>
          <Typography variant='caption' gutterBottom aligh='right'>
            Invite
          </Typography>
          </div>
        </Tooltip>
        </div>
      </React.Fragment>
    );
}

const UserSearchContainer = ({ data, classes }) => {
  window.scrollTo(0, 0)
  if (data.eror) {
    console.log(data.error);
    return (<p>error</p>);
  }

  if (data.loading) {
    return <Linear />;
  }

  return (
    <React.Fragment>
    <Grid className={classes.autoFlow} item md={10} sm={12}>
        <NewInvite />
        <Typography variant="subheading" align='left' gutterBottom>
          Invitations left : {data.invitesPretty.left}
        </Typography>
        <PendingTable data={data.invitesPretty.pending} />
    </Grid>
    </React.Fragment>
  );
};

UserSearchContainer.propTypes = {
  data: PropTypes.object.isRequired,
};

let InviteLink = withRouter(withStyles(styles)(InviteLinkProto));

export { InviteLink };
export default withStyles(styles)(graphql(InvitesLeftQuery)(UserSearchContainer));
