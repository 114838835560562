import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles'
import { green, grey, red } from '@material-ui/core/colors';

import { client } from './utils/makeApolloClient';
import GA from './analytics';

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: grey,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    // type: 'dark',
    background: {
      paper: '#fff',
      default: "#e9ebee"
    },
  },
  status: {
    danger: 'red',
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
  },
  //props: {
    //MuiInputLabel: {
      //shrink: true
    //}
  //}
});

const AppProviders = function ({children}){

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <GA>
        {children}
        </GA>
      </ApolloProvider>
    </ThemeProvider>
  )

};


export { AppProviders };
