import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { Mutation } from "react-apollo";
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { client } from '../../utils/makeApolloClient';
import { Error } from '../Generic/forms';

const WhoAmIQuery = gql`
  query {
    me {
      id @client
      username @client
    }
  }
`;

const styles = theme => ({
  spancontainer: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    fallbacks: [{flexWrap: 'wrap'}]
  },
  spanchildren: {
    width: '90%',
  },
  fullchildren: {
    width: '100%',
  },
  spanedit: {
    width: '10%',
  },
  formControl: {
    margin: theme.spacing.unit,
    display: 'flex',
  },
  button: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

class EditATextField extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      text: props.text,
      showEdit: false,
      ready: false,
      username: '',
      errors: {},
    };
    this.editState = this.editState.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.ready) {
      let data;
      var newstate = {}
      data = client.readQuery({ query: WhoAmIQuery });
      newstate.ready = true;
      if (data.me && data.me.username ){
       newstate.username = data.me.username;
      }
      return newstate
    }
  }

  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (nextProps.text !== this.props.text) {
      this.setState({
        text: nextProps.text
      });
    }
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  editState = (prop, val) => {
    this.setState({[prop]: val})
  };

  render() {
    const { classes, children, MasterMut } = this.props;
    const { objid, refetches, owner, graphname } = this.props;
    const { showEdit, username, errors } = this.state;
    const text = this.state.text === null ? this.props.null : this.state.text

    if (showEdit){
      return (
        <Mutation mutation={MasterMut}>
          {(Mark, { data }) => (
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
              >
                <FormControl className={classes.formControl}>
                  <Error errors={errors} name={'__all__'} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="edit-note">Edit:</InputLabel>
                  <Input
                    multiline
                    id="edit-note"
                    type={'text'}
                    value={text}
                    onChange={this.handleChange('text')}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                <span>
                <Button raised
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={ e => {
                    Mark({
                      variables: {id: objid, text: text},
                      refetchQueries: [...refetches],
                    })
                    .then(result =>{
                      console.log(result)
                      if (result.data[graphname].status === 200 ){
                        this.setState({showEdit: false})
                      }
                      if (result.data[graphname].status === 500 ){
                        this.setState({errors: result.data[graphname].errors})
                      }
                    });
                  }}
                >
                  Submit change
                </Button>
                <Button raised
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={ e => {
                    this.setState({showEdit: false})
                  }}
                >
                  Cancel
                </Button>
                </span>
                </FormControl>
              </form>
          )}
        </Mutation>
      );
    }

    let childclass;
    if (owner === username){
      childclass = classes.spanchildren
    } else {
      childclass = classes.fullchildren
    }

    return (
      <React.Fragment>
        <div className={classes.spancontainer}>
          <div className={childclass}>
          { children }
          </div>
        { owner === username &&
          (
          <div className={classes.spanedit}>
            <Button raised
              mini
              size="small"
              color="primary"
              variant="outlined"
              className={classes.button}
              component="p"
              onClick={ e => {
                this.setState({showEdit: true})
                }}
              >
                Edit
              </Button>
          </div>
          )
        }
        </div>
      </React.Fragment>
    );
  };
}

export { WhoAmIQuery };
export default withStyles(styles)(EditATextField);
