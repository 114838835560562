import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';

import { graphql } from "react-apollo";
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import ToolTipStyle from 'src/components/Generic/ToolTipStyle';
import {stringToColor} from 'src/components/Generic/RandomColor';

const DrawUserQuery = gql`
  query DrawUser {
    me {
      id @client
      username @client
      fullName @client
      firstName @client
      zipCode @client
      position @client
      institution @client
      profilePicSm @client
    }
  }
`;

const styles = theme => ({
  noUnderline: {
     textDecoration: 'none',
  },
  separator: {
    marginRight: theme.spacing.unit,
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wordwrap:{
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  listpad: {
    padding: theme.spacing.unit,
  },
  bigIcon: {
    margin: theme.spacing.unit,
    width: '12rem',
    height: '12rem',
    color: 'white',
    alignContent: 'center',
    wordWrap: 'break-word',
    textAlign: 'center',
  },
  button: {
    color: theme.palette.primary.A700,
    fontSize: 6,
  },
  tooltip: ToolTipStyle(theme),
});

class UserDrawer extends React.Component {
  timer = null;

  state = {
  };

  render() {
    const {data, classes, theme} = this.props;

    if (data.error){
      console.log(data.error)
      return ( <p> error </p>);
    }

    if (data.loading) {
      return <Linear />;
    }

    let src = data.me.profilePicSm;

    return (
      <div className={classes.container}>
        <Link to='/settings#tab=1' className={classes.noUnderline}>
          <div style={{width: '100%'}} align='center'>
          <Avatar
            className={classes.bigIcon}
            style={{ backgroundColor: 'gray' }}
            src={`${src ? src : ''}`}
            children={(
              <>
              + Click to Upload Profile Picture
              </>
            )}
            imgProps={{
              style: {
                minWidth: '100%',
                minHeight: '100%',
              }
            }}
            component={(magic) =>
              {
                const {children, ...rest} = magic;
                if (src){
                  return (
                    <Typography
                      style={{ color:'white'}}
                      variant='h1' align="left" noWrap {...rest}>
                      {children}
                    </Typography>
                  );
                }
                return (
                    <div {...rest}>{children}</div>
                );
              }
            }
          />
        </div>
        <Typography variant='subheading' align='left'>
          {data.me.firstName}
        </Typography>
        </Link>
        <Typography align='left'>
          Zip: {data.me.zipCode}
        </Typography>
        <Typography align='left'>
          {data.me.position}
        </Typography>
        <Typography align='left'>
          {data.me.institution}
        </Typography>
        <Tooltip classes={classes} title="My Posts" placement="bottom">
          <Link to='/authored/queries' className={classes.noUnderline}>
            <span style={{display: 'flex'}}>
            <Typography className={classes.flex} align='left'>
              My Posts
            </Typography>
            <IconButton color="secondary" className={classes.button} aria-label="My Query">
              <Icon>
                library_books
              </Icon>
            </IconButton>
            </span>
          </Link>
        </Tooltip>
        <Tooltip classes={classes} title="My Replies" placement="bottom">
          <Link to='/replied/queries' className={classes.noUnderline}>
            <span style={{display: 'flex'}}>
            <Typography className={classes.flex} align='left'>
              My Replies
            </Typography>
            <IconButton color="secondary" className={classes.button} aria-label="My Query">
              <Icon>
                rate_review
              </Icon>
            </IconButton>
            </span>
          </Link>
        </Tooltip>
        <Tooltip classes={classes} title="My Follows" placement="bottom">
          <Link to='/followed/queries' className={classes.noUnderline}>
            <span style={{display: 'flex'}}>
            <Typography className={classes.flex} align='left'>
              My Follows
            </Typography>
            <IconButton color="secondary" className={classes.button} aria-label="My Query">
              <Icon>
                layers
              </Icon>
            </IconButton>
            </span>
          </Link>
        </Tooltip>
        <Tooltip classes={classes} title="My Bookmarks" placement="bottom">
          <Link to='/bookmarks' className={classes.noUnderline}>
            <span style={{display: 'flex'}}>
            <Typography className={classes.flex} align='left'>
              My Bookmarks
            </Typography>
            <IconButton color="secondary" className={classes.button} aria-label="Add an alarm">
              <Icon>
                bookmarks
              </Icon>
            </IconButton>
            </span>
          </Link>
        </Tooltip>
        <Tooltip classes={classes} title="My Groups" placement="bottom">
          <Link to='/joined/groups'  className={classes.noUnderline}>
            <span style={{display: 'flex',  padding: '8px 0px'}}>
            <Typography className={`${classes.flex} ${classes.wordwrap}`} align='left'>
              My Groups
            </Typography>
            </span>
          </Link>
        </Tooltip>
        <Tooltip classes={classes} title="My Settings" placement="bottom">
          <Link to='/settings'  className={classes.noUnderline}>
            <span style={{display: 'flex',  padding: '8px 0px'}}>
            <Typography className={`${classes.flex} ${classes.wordwrap}`} align='left'>
              My Account
            </Typography>
            </span>
          </Link>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(styles, {withTheme: true})(graphql(DrawUserQuery,{
  options: (props) => ({fetchPolicy: 'cache-first'})
})(UserDrawer));
