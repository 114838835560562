import React from 'react';
import gql from 'graphql-tag';


export const GenCommentsFragment = gql`
fragment genComments on GenCommentsType {
  id
  createdAt
  text
  liked
  likesCount
  thanked
  thanksCount
  attachments{
    attachment
    previewSm
    previewMd
  }
  author {
    id
    username
    fullName
    institution
    position
    profilePicSm
  }
}
`;
