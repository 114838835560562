import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import ProfileChip from 'src/components/Profile/ProfileChip.js';
import CategoryPill from '../Resource/CategoryPill'
import Share from './Share';
import {InviteLink} from '../Invitation/Invitation';
import LikeButton from '../LikeSomething/Like';
import WhoLiked from '../LikeSomething/WhoLModal';
import AttachmentButton from '../Generic/Attachment';
import AttachmentPreview from '../Generic/AttachmentPreview';
import AddToCal from './AddToCal';
import AddComment from '../GenComments/AddComment.js';
import {TabLinkify} from '../Generic/LinkifyComponent';
import { DisplayComments } from '../GenComments/Comments.js';
import Hide from '../Generic/Hide';
import { GenCommentsFragment } from '../GenComments/Fragments';

const EventQuery = gql`
  query DetailEvent($id:ID!){
    event(id: $id){
    author {
      id
      username
      fullName
      institution
      position
      profilePicSm
    }
    categories {
      edges {
        node {
          id
          category
        }
      }
    }
    attachments{
      attachment
      previewSm
      previewMd
    }
    genComments{
      ...genComments
    }
    new
    createdAt
    modifiedAt
    hidden
    zipCode
    title
    description
    daysTill
    id
    startsAt
    endsAt
    likesCount
    liked
    googleCal
    yahooCal
    ical
    }
  }
${GenCommentsFragment}
`;

const styles = theme => ({
  block: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    width: '100%',
  },
  newline:{
    whiteSpace: 'pre-wrap',
  },
  card: {
    minWidth: 275,
  },
  comment: {
    borderColor: `${theme.palette.primary[500]}`,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  leftBlock:{
    display:'flex',
    justifyContent: 'space-between',
    width: '50%',
  },
  rightBlock: {
    display:'flex',
    justifyContent: 'flex-end',
    width: '50%',
    fallbacks: [{justifyContent: 'right'}]
  },
  segment: {
    display: 'flex',
    width: '100%',
    borderWidth: theme.spacing.unit / 6,
    margin: theme.spacing.unit / 2,
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  }
});

function DetailEvent(props) {
  const { key, data, classes, className: BoxClass } = props;

  if (data.loading) {
    return <Linear />;
  }

  if (data.error) {
    return <p>{data.error.message}</p>;
  }

  let counted_likes;
  if (data.event.likesCount === 0){
    counted_likes = null;
  } else {
    counted_likes = (
    <WhoLiked key={key} instance={data.event.id}>
      <Icon
        color={"primary"}
        fontSize={'small'}>
        thumb_up
      </Icon>
      {data.event.likesCount}
    </WhoLiked>
    );
  }

  return (
    <Box className={BoxClass}>
      <TabLinkify>
      <Card className={classes.card}>
        <CardContent>
          <div style={{ display:'flow-root'}}>
            <span className={classes.block}>
              <ProfileChip
                user={data.event.author }
              />
              <Typography style={{width: '100%', flex: 1}} component="p" align='right'>
                <Link to={`/detail/event/${data.event.id}`}>
                  Posted {moment(data.event.createdAt).fromNow()}
                </Link>
              </Typography>
          </span>
          </div>
          <Divider />
          <div className={classes.segment} style={{width: '100%', }}>
            <span className={classes.leftBlock}>
              <LikeButton
                refetches={['DetailEvent', 'newEvent', 'AllEvents']}
                instance={data.event.id}
                liked={data.event.liked}
                count={data.event.likesCount} />
              <InviteLink/>
              <div style={{marginLeft: '8px'}}>
              <Share item={data.event} />
              </div>
              <div style={{marginLeft: '8px'}}>
              <AddToCal id={data.event.id} event={data.event} tooltip="Add to calendar"/>
              </div>
            </span>
            <span className={classes.rightBlock}>
              <div style={{
                  height: '64px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
              { counted_likes }
              </div>
            </span>
          </div>
          <div style={{display:'flex', flexWrap: 'wrap'}}>
          {data.event.categories && data.event.categories.edges.map((item, i ) => {
          return (
            <span >
              <CategoryPill key={i} category={item.node.category} />
            </span>
          );
          })}
          </div>
          <br />
          <div >
            <Typography className={classes.newline} variant='body1' align='left'>
              <strong>Title:</strong> {data.event.title} { data.event.new && (<Typography display={'inline'} style={{color: 'red'}}><strong>New!</strong></Typography>)}
            </Typography> <br />
          </div>
          <Typography className={classes.newline} component="body1" align='left'>
          { data.event.startsAt &&
            <React.Fragment>
              <strong>Starts:</strong> {data.event.startsAt} {' '}
            </React.Fragment>
          }
          { data.event.endsAt &&
            <React.Fragment>
              <strong>Ends:</strong> {data.event.endsAt}
            </React.Fragment>
          }
          { data.event.daysTill && (<Typography display={'inline'} style={{color: 'red'}}>{' '}{data.event.daysTill}</Typography>)}
          </Typography><br />
          { data.event.zipCode &&
            <div>
              <Typography className={classes.newline} variant='body1' align='left'>
                <strong>Address:</strong> {data.event.zipCode}
              </Typography> <br />
            </div>
          }
          <Hide jschild={props.jschild} >
            <Typography className={classes.newline} component="body1" align='left'>
              <strong>Description:</strong><br />{data.event.description}
            </Typography> <br />
            <div className={classes.segment}>
              { data.event.attachments &&
                <AttachmentPreview attachments={data.event.attachments} />
              }
            </div>
            { data.event.group && (
              <div className={classes.segment}>
              <Typography style={{width: '100%'}} variant="body1" align='left'>
                <strong>* Originally posted at {' '}
                  <Link to={`/detail/group/${data.event.group.id}`}>
                    { data.event.group.name }
                  </Link>
                </strong>
              </Typography>
              </div>
            )}
          </Hide>
          <br/>
          <AddComment
            owner={data.event.id}
            refetches={['DetailEvent', 'newEvent', 'AllEvents', 'TimeLine']}>
            {replyTo => (
            <DisplayComments
              classes={classes}
              refetches={['DetailEvent', 'newEvent', 'AllEvents', 'TimeLine']}
              comments={data.event.genComments}
              replyTo={replyTo} />
            )}
          </AddComment>
        </CardContent>
      </Card>
      </TabLinkify>
    </Box>
  );
}

DetailEvent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Event = graphql(EventQuery,{
  options: ({ match }) => {
    return {
      variables: {
        id: match.params.id,
      },
    };
  },
})(DetailEvent)

export const DetachedDetailEvents = withStyles(styles)(DetailEvent);

export default withStyles(styles)(Event);
