import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import TimeLine from 'src/components/Timeline/Timeline.js';
import {SponsorGroups} from 'src/partials/Drawers/RightD.js';
import {Basic} from '../Layouts/parent';


export default function(props){
  window.scrollTo(0, 0);
  return (
    <Basic children={(<TimeLine/>)} right={(<SponsorGroups/>)} />
  );
}


