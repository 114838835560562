import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { graphql, Query } from 'react-apollo';
import { Mutation } from "react-apollo";
import gql from 'graphql-tag';

import ToolTipStyle from '../Generic/ToolTipStyle';
import { client } from '../../utils/makeApolloClient';
import { GAContext } from '../../analytics/index';


const AddToCalMutation = gql`
  mutation ($id: ID!) {
    toCalEvent (id: $id) {
      status
      errors
    }
  }
`;


const styles = theme => ({
  root: {
    padding: `5 ${theme.spacing.unit * 3}px`,
    width: '100%',
  },
  button: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 4,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit / 4,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  volume: {
    minHeight: theme.spacing.unit * 30,
  },
  tooltip: ToolTipStyle(theme),
});

class CalendarDialog extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleClick = (ga) => event => {
    ga.event({
      category: 'Event Interaction',
      action: 'Added to Calendar',
      label: 'Event'
    });
    this.props.onClose();
  };

  render() {
    const { classes, onClose, event, ...other } = this.props;

    return (
    <GAContext.Consumer>
    { ga  => (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title">Calendar</DialogTitle>
        <div>
          <List>
            <ListItem>
              <ListItemText primary="Google" />
              <br />
              <a href={event.googleCal} target="_blank">Google</a>
            </ListItem>
            <ListItem>
              <ListItemText primary="Yahoo" />
              <br />
              <a href={event.yahooCal} target="_blank">Yahoo</a>
            </ListItem>
            <ListItem>
              <ListItemText primary="Yahoo" />
              <br />
              <a href={event.ical} target="_blank">Download event</a>
            </ListItem>
          </List>
        </div>
      </Dialog>
    )}
    </GAContext.Consumer>
    );
  }
}

CalendarDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const CalendarDialogWrapped = withStyles(styles)(CalendarDialog);

class ShareButton extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      success: false,
      open: false,
    };
  }

  handleClick = (ga) => event => {
    const {id} = this.props;
    this.setState({open: true})
    //client.mutate({
      //mutation: AddToCalMutation,
      //variables:{
        //id,
      //}
    //}).then( result => {
      //if (result.data.loading) return null;
      //if (result.data.error) return null;
      //if (result.data.toCalEvent === 200) this.setState({success: true});
    //})
  };

  handleClose = event => {
    this.setState({open: false})
  }

  render() {
    const { classes, item, tooltip, event } = this.props;
    const { success } = this.state;
    return (
      <GAContext.Consumer>
      { ga  => (
        <React.Fragment>
          <Tooltip classes={classes} title={tooltip} placement="right">
          <div className={classes.vertical}>
          <IconButton
            color={success ? 'primary':'secondary'}
            className={classes.button}
            aria-label="Add an alarm"
            onClick={this.handleClick(ga)}
          >
            <Icon
              fontSize={'small'}>
              calendar_today
            </Icon>
          </IconButton>
          <Typography variant='caption' gutterBottom aligh='right'>
            Add to calendar
          </Typography>
          </div>
          </Tooltip>
          <CalendarDialogWrapped
            open={this.state.open}
            onClose={this.handleClose}
            event={event}
            />
        </React.Fragment>
      )}
      </GAContext.Consumer>
    );
  }
}

export default withStyles(styles)(ShareButton);
