import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { graphql } from 'react-apollo';
import { Mutation } from "react-apollo";
import gql from 'graphql-tag';

import ToolTipStyle from '../Generic/ToolTipStyle';
import WhoThanked from './WhoTModal';


const ThanksMut = gql`
  mutation ThankSomething ($inst: ID!){
    thankSomething (instance: $inst){
      status
      errors
    }
  }
`;

const styles = theme => ({
  button: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 4,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit / 4,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltip: ToolTipStyle(theme),
});


class ThanksButton extends React.Component {
  constructor(props){
    super(props);
    this.handlePost = this.handlePost.bind(this);
  }

  handlePost = () => {
    const { instance, refetches } = this.props;
    this.props
      .mutate({
        variables: { 'inst': instance },
        refetchQueries: refetches,
      })
      .then(({ data }) => {
        if (data.error) return null;
        if (data.loading) return null;
        if (data.thankSomething.status === 200) {
          console.log('good thanks');
        }
        if (data.thankSomething.status === 500) {
          console.log('Something went wrong while liking');
        }
      }).then(() => {});
  };

  render() {
    const { key, instance, thanked, classes } = this.props;
    return (
      <React.Fragment>
        <Tooltip classes={classes} title="Thanks" placement="bottom">
        <div className={classes.vertical}>
        <IconButton
          className={classes.button}
          aria-label="Add an thanks"
          onClick={this.handlePost}
        >
          <Icon
            color={thanked ? "primary": "secondary"}
            fontSize={'small'}>
            mood
          </Icon>
        </IconButton>
        <Typography variant='caption' gutterBottom aligh='right'>
          Thank
        </Typography>
        </div>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(graphql(ThanksMut)(ThanksButton));
