import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PrintIcon from '@material-ui/icons/Print';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MoreIcon from '@material-ui/icons/MoreVert';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import { Mutation } from "react-apollo";
import gql from 'graphql-tag';

import LongMenu from './notifications.js'
import PrintMenu from './PrintQueue.js'
import Invitations from './invitations'
import CategorySearch from 'src/components/Search/SearchRes'
import ToolTipStyle from 'src/components/Generic/ToolTipStyle';
import { LogoutMut } from '../../screens/Auth/logout'
import { client } from '../../utils/makeApolloClient';

const Auth = gql`
  query Auth {
    me {
      id @client
      isSuperuser @client
    }
  }
`;


const styles = theme => ({
  logo: {
    maxWidth: '125px',
    display: 'none',
    color: 'white',
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  root: {
    margin: 'auto',
    display: 'table',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    fallbacks: [{justifyContent: 'center', display: 'block'}]
  },
  flex: {
    flex: 1,
  },
  paper: {
    backgroundColor: 'grey',
  },
  badge: {
    top: 'auto',
  },
  row: {
    flexGrow: 1,
    float: 'right',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  lrow: {
    float: 'left',
    display: 'flex',
  },
  button: {
    color: 'white',
    fontSize: 6,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 3,
    },
  },
  menuButton: {
    color: 'white',
    marginLeft: -12,
  },
  menuText: {
    color: 'white',
    marginLeft: 12,
    marginRight: 12,
  },
  toolbar: {
    textAlign: 'center',
    padding: '0 8px',
    marginBottom: theme.spacing.unit * 2,
    ...theme.mixins.toolbar,
    backgroundColor: theme.palette.primary['500'],
    [theme.breakpoints.down('md')]: {
      padding: '0 0px',
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    flexGrow: 5,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuMobile: {
    padding: theme.spacing.unit * 3,
  },
  tooltip: ToolTipStyle(theme),
});

class TopAppBar extends React.Component {
  state = {
    superuser: false,
    auth: false,
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  static getDerivedStateFromProps(props, state) {
    let data;
    try{
      console.log('test')
      var state = {}
      data = client.readQuery({ query: Auth });
      if (data.me && data.me.id ){
       state.auth = true;
      }
      if (data.me && data.me.isSuperuser ){
       state.superuser = true;
      }
      return state;
    }
    catch (e){
      return
    }
    return null;
  }

  handleLogout = () => {
    client.mutate({
      mutation: LogoutMut,
    }).then(({ data }) => {
      if ('error' in data) {
        return null;
      }
      if (data.logout.status === 200) {
        window.location.pathname = '/';
        client.resetStore();
      }
    });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { classes, logo } = this.props;
    const { auth, superuser, anchorEl, mobileMoreAnchorEl } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const open = Boolean(anchorEl);

    const renderMobileMenu = (
      <Menu
        classes={classes}
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        {superuser && (
          <MenuItem>
          <Tooltip classes={classes} title="Admin" placement="bottom">
            <a href="/admin">
              <IconButton className={classes.button} aria-label="Add an alarm">
                <Icon>
                  visibility
                </Icon>
              </IconButton>
            </a>
          </Tooltip>
          </MenuItem>
        )}
        {auth ? (
          <React.Fragment>
          <MenuItem className={`${classes.button} ${classes.menuMobile}`} children={<PrintMenu/>}/>
          <MenuItem className={`${classes.button} ${classes.menuMobile}`} children={<Invitations/>}/>
          <MenuItem>
          <Tooltip classes={classes} title="Groups" placement="bottom">
            <Link to={`/all/groups`} style={{ textDecoration: 'none' }}>
              <IconButton color="secondary" className={classes.button} aria-label="All groups">
                <Icon>
                  people_outline
                </Icon>
              </IconButton>
            </Link>
          </Tooltip>
          </MenuItem>
          <MenuItem className={`${classes.button} ${classes.menuMobile}`} children={<LongMenu/>}/>
          <MenuItem>
            <Tooltip classes={classes} title="My Profile" placement="bottom">
            <IconButton
              className={classes.button}
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <Icon>
                settings
              </Icon>
            </IconButton>
            </Tooltip>
          </MenuItem>
          </React.Fragment>
          ):(
          <React.Fragment>
          <MenuItem>
            <Link to='/aboutus/' style={{ textDecoration: 'none' }}><div className={classes.menuText} onClick={this.handleClose}>About Us</div></Link>
          </MenuItem>
          <MenuItem>
            <Link to='/ContactUs/' style={{ textDecoration: 'none' }}><div className={classes.menuText} onClick={this.handleClose}>Contact Us</div></Link>
          </MenuItem>
          </React.Fragment>
        )}
      </Menu>
    );

    return (
        <AppBar className={classes.toolbar} position="sticky">
          <Toolbar>
            <div className={classes.row}>
            <Tooltip classes={classes} title="Home" placement="bottom">
              <Link to={`/`} style={{ textDecoration: 'none' }}>
                <img className={classes.logo} src={logo} />
              </Link>
            </Tooltip>
            {auth && (
              <>
                <Link to='/FAQ/' style={{ textDecoration: 'none'}}><div style={{ top: '25%'}} className={classes.menuText} onClick={this.handleClose}>
                  <Typography align="left" variant="headline" component="h2" gutterBottom>
                    <strong>FAQ</strong>
                  </Typography></div>
                </Link>
                <CategorySearch className={classes.root}/>
              </>
            )}
            </div>
            <div className={classes.sectionDesktop}>
            {auth ? (
              <div className={`${classes.lrow} ${classes.button}`}>
                {superuser && (
                  <Tooltip classes={classes} title="Admin" placement="bottom">
                    <a href="/admin">
                      <IconButton color="secondary" className={classes.button} aria-label="Add an alarm">
                        <Icon>
                          visibility
                        </Icon>
                      </IconButton>
                    </a>
                  </Tooltip>
                )}
                <span className={classes.lrow}>
                <PrintMenu/>
                <Invitations/>
                <Tooltip classes={classes} title="Groups" placement="bottom">
                  <Link to={`/all/groups`} style={{ textDecoration: 'none' }}>
                    <IconButton color="secondary" className={classes.button} aria-label="All groups">
                      <Icon>
                        people_outline
                      </Icon>
                    </IconButton>
                  </Link>
                </Tooltip>
                < LongMenu />
                <Tooltip classes={classes} title="My Profile" placement="bottom">
                <IconButton
                  className={classes.button}
                  aria-owns={open ? 'menu-appbar' : null}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <Icon>
                    settings
                  </Icon>
                </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <Link to='/ContactUs/'><MenuItem onClick={this.handleClose}>Contact Us</MenuItem></Link>
                  <MenuItem onClick={() => this.handleLogout()}>Logout</MenuItem>
                </Menu>
                </span>
              </div>
            ) : (
                  <React.Fragment>
                  <Link to='/aboutus/' style={{ textDecoration: 'none' }}><div className={classes.menuText} onClick={this.handleClose}>About Us</div></Link>
                  <Link to='/ContactUs/' style={{ textDecoration: 'none' }}><div className={classes.menuText} onClick={this.handleClose}>Contact Us</div></Link>
                  </React.Fragment>
            )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
            { renderMobileMenu }
          </Toolbar>
        </AppBar>
    );
  }
}

TopAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopAppBar);
