import { graphql, Query } from 'react-apollo';
import gql from 'graphql-tag';


const FollowSomethingMut = gql`
  mutation FollowSomething ($inst: ID!) {
    subscribeToSomething(instance:$inst){
      status
      errors
    }
  }
`;


export {FollowSomethingMut};
