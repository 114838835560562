import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Route, Redirect } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import ProfileChip from 'src/components/Profile/ProfileChip.js';
import CategoryPill from '../Resource/CategoryPill'
import {InviteLink} from '../Invitation/Invitation';
import LikeButton from '../LikeSomething/Like';
import WhoLiked from '../LikeSomething/WhoLModal';
import WhoFollowed from '../FollowSomething/WhoFModal';
import {Followed} from '../FollowSomething/Follow';
import AttachmentPreview from '../Generic/AttachmentPreview';
import FollowButton from '../FollowSomething/Follow';
import {TabLinkify} from '../Generic/LinkifyComponent';
import AddComment from '../GenComments/AddComment.js';
import {ReplyTo} from '../Generic/ReplyTo';
import { DisplayComments } from '../GenComments/Comments.js';
import Hide from '../Generic/Hide';
import {Clamp} from '../Generic/ReadMore.js';
import { GenCommentsFragment } from '../GenComments/Fragments';

const AnnQuery = gql`
  query DetailAnn($id:ID!){
    announcement(id: $id){
    author {
      id
      username
      fullName
      institution
      position
      profilePicSm
    }
    categories {
      edges {
        node {
          id
          category
        }
      }
    }
    attachments{
      attachment
      previewSm
      previewMd
    }
    genComments{
      ...genComments
    }
    createdAt
    modifiedAt
    hidden
    zipCode
    description
    id
    likesCount
    liked
    followsCount
    followed
    }
  }
${GenCommentsFragment}
`;

const styles = theme => ({
  wrapper: {
    margin: "0 auto",
    align: "center",
    overflow: 'auto',
  },
  block: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
  },
  newline:{
    whiteSpace: 'pre-wrap',
  },
  comment: {
    borderColor: `${theme.palette.primary[500]}`,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  segment: {
    display: 'flex',
    width: '100%',
    borderWidth: theme.spacing.unit / 6,
    margin: theme.spacing.unit / 2,
  },
  leftBlock:{
    display:'flex',
    justifyContent: 'space-between',
    width: '50%',
  },
  rightBlock: {
    display:'flex',
    justifyContent: 'flex-end',
    width: '50%',
    fallbacks: [{justifyContent: 'right'}]
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  }
});

function DetailAnn(props) {
  const { key, data, classes, className: BoxClass } = props;
  const { extraRefetches } = props;

  if (data.loading) {
    return <Linear />;
  }

  if (data.error) {
    console.log( data.error.message );
    return <Redirect to={{ pathname: '/', state: { from: props.location }, }} />;
  }

  let counted_likes;
  if (data.announcement.likesCount === 0){
    counted_likes = null;
  } else {
    counted_likes = (
    <WhoLiked key={key} instance={data.announcement.id}>
      <Icon
        color={"primary"}
        fontSize={'small'}>
        thumb_up
      </Icon>
      {data.announcement.likesCount}
    </WhoLiked>
    );
  }

  let counted_follows;
  if (data.announcement.followsCount === 0){
    counted_follows = null;
  } else {
    counted_follows = (
    <WhoFollowed key={`followed-${key}`} instance={data.announcement.id}>
      <Followed followed={data.announcement.followed} />
      {data.announcement.followsCount}
    </WhoFollowed>
    );
  }

  return (
    <Box className={BoxClass}>
      <TabLinkify>
      <Card>
        <CardContent>
          <AddComment
            key={data.announcement.id}
            label={'Click here to leave a reply'}
            first={data.announcement.genComments.length}
            owner={data.announcement.id}
            refetches={['TimeLine', 'AllAnn', 'DetailAnn', 'newAnn']}>
            {replyTo => (
          <>
          <div style={{ display:'flow-root'}}>
          <span className={classes.block}>
              <ProfileChip
                user={data.announcement.author }
              />
              <Typography style={{width: '100%', flex: 1}} component="p" align='right'>
                <Link to={`/detail/announcement/${data.announcement.id}`}>
                  Posted {moment(data.announcement.createdAt).fromNow()}
                </Link>
              </Typography>
          </span>
          </div>
          <Divider />
          <br />
          <Hide jschild={props.jschild} >
            <Typography className={classes.newline} component="body1" align='left'>
              <Clamp>
              {data.announcement.description}
              </Clamp>
              { data.announcement.zipCode &&
                <React.Fragment>
                  <Icon> location_on </Icon> {data.announcement.zipCode}
                </React.Fragment>
              }
            </Typography> <br />
            <div className={classes.segment}>
              { data.announcement.attachments &&
                <AttachmentPreview attachments={data.announcement.attachments} />
              }
            </div>
            <div style={{display:'flex', flexWrap: 'wrap'}}>
            {data.announcement.categories && data.announcement.categories.edges.map((item, i ) => {
            return (
              <span >
                <CategoryPill key={i} category={item.node.category} />
              </span>
            );
            })}
            </div>
            { data.announcement.group && (
              <div className={classes.segment}>
              <Typography style={{width: '100%'}} variant="body1" align='left'>
                <strong>Originally posted at the following group:{' '}
                  <Link to={`/detail/group/${data.announcement.group.id}`}>
                    { data.announcement.group.name }
                  </Link>
                </strong>
              </Typography>
              </div>
            )}
            <div className={classes.segment}>
              <span className={classes.leftBlock}>
                <LikeButton
                  refetches={['AllAnn', 'DetailAnn', 'newAnn']}
                  instance={data.announcement.id}
                  liked={data.announcement.liked}
                  count={data.announcement.likesCount} />
                <FollowButton
                  refetches={['AllAnn', 'DetailAnn', 'newAnn', 'TimeLine', 'UnreadNoti'].concat(extraRefetches)}
                  instance={data.announcement.id}
                  followed={data.announcement.followed}
                  />
                <ReplyTo onClick={replyTo(data.announcement.author.username, data.announcement.id)} />
                <InviteLink/>
              </span>
              <span className={classes.rightBlock}>
                <div style={{
                    height: '64px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                {counted_likes}
                {counted_follows}
                </div>
              </span>
            </div>
            <br/>
              <DisplayComments
                classes={classes}
                refetches={['TimeLine', 'AllAnn', 'DetailAnn', 'newAnn']}
                comments={data.announcement.genComments}
                replyTo={replyTo} />
          </Hide>
          </>
            )}
          </AddComment>
        </CardContent>
      </Card>
      </TabLinkify>
    </Box>
  );
}

DetailAnn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Ann = graphql(AnnQuery,{
  options: ({ match }) => {
    return {
      variables: {
        id: match.params.id,
      },
    };
  },
})(DetailAnn)

export const DetachedDetailAnn = withStyles(styles)(DetailAnn);

export default withStyles(styles)(Ann);
