import React from 'react';
import PropTypes from 'prop-types';

import gql from 'graphql-tag';

import ShareButton from '../Generic/Share';

const addEventShareMut = gql`
  mutation ManyShares ($Id: ID!, $tt:[InputSelect]!, $message: String!) {
    addEventShare (multiUser: $tt, resId: $Id, message: $message){
      status
    }
  }
`;

class AnnouncementShareButton extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    const { item } = this.props;
    return (
      <React.Fragment>
        <ShareButton tooltip='Share this event' item={item} shareMut={addEventShareMut} />
      </React.Fragment>
    );
  }
}

export default AnnouncementShareButton;
