import React from 'react';
import ReactGA from 'react-ga';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { client } from '../utils/makeApolloClient';
import Circular from '../components/Loading/Circular.js';
import {UserFragment} from '../components/GQL/fragments.js';

const GAQuery = gql`
  {
    currentGa {
      debug
      trackingCode
    }
    me {
      realPk
      isSuperuser
      ...BasicUserInfo
    }
  }
  ${UserFragment}
`;

async function AsyncGA(){
  const GAdata = await client.query({
      query: GAQuery
  }).then(
      result => {
          return result.data;
      });
  const config = {
    GA: {
        debug: GAdata.currentGa.debug,
        trackingCode: GAdata.currentGa.trackingCode,
        userId: GAdata.me === null ? 0 : GAdata.me.realPk,

    },
  };
  return config
}

async function GAConfig(){
    const config = await AsyncGA();
    ReactGA.initialize(config.GA.trackingCode, {
      debug: config.GA.debug,
      titleCase: true,
      gaOptions: {
        userId: config.GA.userId || 0
      }
    });
    return ReactGA
}

const GAContext = React.createContext({});

const GA = (argument) => {
  return class GA extends React.Component {
    constructor(props){
    super(props);
    this.state = { ga: null };
  }
    componentDidMount() {
      this._asyncR = argument()
        .then(cmp => {
          cmp.pageview(window.location.pathname);
          this.setState({ga: cmp});
          this._asyncR.done = true;
        })
        .catch(error => {
          console.log(error)
          this._asyncR.done = true;
        });
    }
    componentWillUnmount() {
      if(!this._asyncR.done){
        this._asyncR.cancel();
      }
    }
    render() {
      const props = this.props;
      const ga = this.state.ga;
      if (ga === null) {
        // Render loading state ...
        return (<Circular/>);
      } else {
        return (
        <GAContext.Provider value={ga}>
            {props.children}
        </GAContext.Provider>
        );
      }
    }
  }
};

export {GAContext};
export default GA(GAConfig);
