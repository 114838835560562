import React, { useState, useRef } from 'react';
import {useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dropzone from 'react-dropzone';

import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

import FullScreenDialog from './PreviewModal';

const useStyles = makeStyles((theme) => ({
  label: {
    textTransform: 'none',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  dragActive:{
    borderColor: theme.palette.primary.A500,
    borderRadius: theme.spacing.unit,
    border: 'solid',
  },
  column: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit + 6}px`,
  },
  imageMarked: {
    height: 3,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

export function UploadHandler(props) {
  const classes = useStyles();
  // This component can handle up to 3 files
  const [bucket, setbucket] = useState([]);

  const [file1, setfile1] = useState();
  const [file2, setfile2] = useState();
  const [file3, setfile3] = useState();

  const {children, setFiles, key} = props;

  useEffect(() => {
    if (bucket.length > 3) {
      let array_of_files = Array.from(bucket);
      setbucket(array_of_files.slice(0,3))
    }
    if (typeof bucket[0] !== undefined || !file1) setfile1(bucket[0])
    if (typeof bucket[1] !== undefined || !file2) setfile2(bucket[1])
    if (typeof bucket[2] !== undefined || !file3) setfile3(bucket[2])
  }, [bucket]);

  useEffect(() => {
    setFiles([file1, file2, file3])
  }, [file1, file2, file3]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    updateBucket(acceptedFiles)
  }

  const updateBucket = (new_files) => {
    if (bucket.length < 3 && bucket.length !== 0 ) {
      let array_of_files = Array.from(bucket);
      for (const element of new_files) {
        array_of_files = array_of_files.concat(element)
      }
      setbucket(array_of_files.slice(0,3))
    } else {
      setbucket(new_files)
    }
  }
  let label_id = `files-add${Math.random().toString(16).slice(2)}`
  return (
    <Dropzone style={{width: "inherit"}} onDrop={onDrop} onClick={e => e.preventDefault()}>
    {({getRootProps, getInputProps, isDragActive}) =>
      <div
        className={`${isDragActive ? classes.dragActive : ''}`}
        {...getRootProps()}
        >
        {children}
        <label htmlFor={label_id} class="btn">
          <Button variant="contained" component="span"
            classes={{
              label: classes.label, // class name, e.g. `classes-nesting-label-x`
            }}
            className={classes.button}>
            <Icon fontSize={'small'}>
              attach_file
            </Icon>
            Attach a resource
          </Button>
        </label>
        {file1 &&
          (
            <Typography variant='body1' gutterBottom className={classes.title}>
            {file1.name}
            <DeleteForeverOutlinedIcon fontSize={'small'} onClick={() => setfile1()} />
            </Typography>
          )}
        {file2 &&
          (
            <Typography variant='body1' gutterBottom className={classes.title}>
            {file2.name}
            <DeleteForeverOutlinedIcon fontSize={'small'} onClick={() => setfile2()} />
            </Typography>
          )}
        {file3 &&
          (
            <Typography variant='body1' gutterBottom className={classes.title}>
            {file3.name}
            <DeleteForeverOutlinedIcon fontSize={'small'} onClick={() => setfile3()} />
            </Typography>
          )}
        <form >
        <input
          id={label_id}
          type="file"
          name="image"
          accept="image/*,.pdf,.doc,.docs,application/msword"
          style={{display: 'None',}}
          multiple
          onChange={({ target: { value, validity, files } }) => {
            value = null;
            console.log(value)
            if (validity.valid) {
              updateBucket(files)
            }
            }
          }
        />
        </form>
      </div>
    }
    </Dropzone>
  )
}


function ProtoPreview(props) {
  const classes = useStyles();

  const { attach, preview, width } = props;

  return (
    <FullScreenDialog buttonwidth={width} key={attach.attachment} attachment={attach.attachment} >
    <ButtonBase
      focusRipple
      key={attach.attachment}
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      style={{
        width: '100%',
      }}
    >
      <span
        className={classes.imageSrc}
        style={{
          backgroundImage: `url(${attach[preview]})`,
          backgroundPosition: '50%',
        }}
      />
      <span className={classes.imageBackdrop} />
      <span className={classes.imageButton}>
        <Typography
          component="span"
          variant="subtitle1"
          color="inherit"
          className={classes.imageTitle}
        >
          Download Attachment
          <span className={classes.imageMarked} />
        </Typography>
      </span>
    </ButtonBase>
    </FullScreenDialog>
  )
}

function ButtonBases(props) {
  const classes = useStyles();

  const { attachments, preview, width } = props;

  if (attachments === undefined || !attachments) {
    return null
  }

  if ( attachments.length !== 0 ){
    let use_width;
    let first_attachment;
    if (attachments.length === 1) {
      use_width = '100%'
    } else if (attachments.length > 1) {
      use_width = '100%'
    }
    first_attachment = attachments[0];

    let remaining_attachments = attachments.slice(1);

    return (
      <div className={classes.root}>
        <ProtoPreview attach={first_attachment} {...{width: use_width, preview}}/>
        <span className={classes.column}>
        {remaining_attachments.map((attach, i) => (
          <ProtoPreview attach={attach} {...{width: '100%', preview}}/>
        ))}
        </span>
      </div>
    );
  } else {
    return null;
  }
}

ButtonBases.propTypes = {
  classes: PropTypes.object.isRequired,
  preview: PropTypes.oneOf(['previewSm', 'previewMd']),
};

ButtonBases.defaultProps = {
  preview: 'previewMd',
  width: '400px',
};

export default ButtonBases;
