import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

var qs = require('qs');

const styles = theme => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing.unit / 2,
    padding: theme.spacing.unit * 3 / 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  row: {
    display: 'flex',
    borderWidth: theme.spacing.unit / 6,
    margin: theme.spacing.unit / 2,
  },
});

function checkCookie(){
  let cookieEnabled;
  cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled){
      document.cookie = "testcookie";
      cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
  }
  return cookieEnabled;
}

function CookieCheck (props) {
  const [Enabled, setEnabled] = React.useState(true);
  useEffect(() => {
    setEnabled(checkCookie())
  }, []);

  const {match, classes} = props;

  return(
    <Grid  item md={12}>
      <div className={classes.row}>
      <span style={{float:'left', width: '50%'}}>
        <Typography variant='subheading' align='left'>
        <strong>{Enabled? null : (<>Please enable cookies</>) }</strong>
        </Typography>
      </span>
      </div>
    </Grid>
  )
}

export default withRouter(withStyles(styles)(CookieCheck));
