import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import { AsyncCreatable } from 'react-select';
//import queryString from 'query-string';
import debounce from 'debounce';

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

//import ReactGA from '../analytics';
import { graphql } from 'react-apollo';
import { client } from '../../utils/makeApolloClient';
import gql from 'graphql-tag';

const UserQuery = gql`
  query ($terms: [String], $page: Int, $perpage: Int){
    userEsSearch(terms: $terms, page: $page, perpage: $perpage) {
      numResults
      numPages
      users{
        id
        zipCode
        username
        firstName
        lastName
        fullName
        institution
        position
        profilePicSm
        __typename
        expertise{
          edges{
            node{
              id
              expertise
            }
          }
        }
      }
    }
  }
`;

const styles = theme => ({
  root: {
    align: "center",
    width: '100%',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {
    maxWidth: 900,
    margin: "0 auto",
  },
  formControl: {
    margin: theme.spacing.unit,
    display: 'flex',
  },
  autoList: {
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

class UserSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userSearchVal: '',
      results: [],
      error: '',
    };
    this.debouncedSearch = debounce(this.search, 550);
  }

  userSearchValChanged(value) {
    this.setState({ userSearchVal: value, loading: true});
    this.debouncedSearch();
  }

  search() {
    const { userSearchVal } = this.state;
    client.query(
        {
          query: UserQuery,
          variables: {
            terms: [userSearchVal],
            page: 1,
            perpage: 50,
          }
        }
      )
      .then(result => {
        const data = result.data;
        console.log(data, 'raw')
        if ('error' in data) {
          console.log(data.error)
          this.setState({ error: data.error, loading: false});
        }
        if (data.loading) {
          return null;
        }
        else {
          this.setState({ results: data.userEsSearch.users, loading: false});
        }
      })
      .catch(err => {
        console.log(err, 'ohoh')
        this.setState({ error: err.toString() }) });
    //ReactGA.event({
      //category: 'Search',
      //action: 'User Search Executed',
      //label: `${this.state.userSearchVal}`,
    //});
  }

  render() {
    const {classes} = this.props;
    const { userSearchVal, results, error, loading } = this.state;
    return (
      <div className={classes.wrapper}>
        <InputLabel htmlFor="text">
          <h1>User Search</h1>
        </InputLabel>
        <Input
          id="comment"
          type={'text'}
          fullwidth={true}
          className={classes.formControl}
          value={userSearchVal}
          onChange={e => this.userSearchValChanged(e.target.value)}
        />
        {loading && <p>Loading...</p>}
        {!error && <p>Search for a user to see results.</p>}
        {!loading &&
          !error &&
          !results.length &&
            (
            <div>
            <br />
            <p>
              would you&#39;d like to{' '}
              <Link to="/invitations">invite a colleague to join Welnity?</Link>
            </p>
            </div>
          )}
        {!loading && error && <p className="text-danger">{error}</p>}
        {!loading && !error && !!results.length && <UserSearchResults data={results} classes={classes.autoList} />}
      </div>
    );
  }
}

const UserSearchResults = ({ data, classes }) => (
  <List className={classes.root}>
    {data.map(d => (
      <List item className={classes.autoList}>
      <Link to={`/profile/${d.username}`} key={d.username} className="list-group-item">
        <strong>
          {d.firstName} {d.lastName}
        </strong>
        {(!!d.institution || !!d.position) && <br />}
        {!!d.institution && !!d.position &&
            (
              <Typography>
              {d.position} at {d.institution}
              </Typography>
            )
        }
        {!!d.institution && !d.position &&
            (
              <Typography>
              {d.institution} (Position not provided)
              </Typography>
            )
        }
        {!d.institution && !!d.position &&
            (
              <Typography>
              {d.position} (Institution not provided)
              </Typography>
            )
        }
        {d.expertise &&
          d.expertise.edges.length && (
            <span>
              <br />
              <strong>Expertise: </strong>
              <Typography>
              {d.expertise.edges.map(n => n.node.expertise)}
              </Typography>
            </span>
          )}
      </Link>
      <Divider/>
      </List>
    ))}
  </List>
);

export {UserQuery};
export default withStyles(styles)(UserSearch);
