import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';

import AsyncSelect from 'react-select/async';
import Select from 'react-select';
//import 'react-select/dist/react-select.css';

import debounce from 'debounce';
//import queryString from 'query-string';

import { Redirect } from 'react-router-dom';

import { graphql, Query } from 'react-apollo';
import gql from 'graphql-tag';

import { client } from '../../utils/makeApolloClient';
import SelectStyle from '../Generic/SelectStyle'
import {isIterable} from '../Generic/SelectStyle.js';

var qs = require('qs');


const CategorySugQuery = gql`
  query ($param: String!) {
    searchCategories(input: $param) {
      category
    }
  }
`;

const ResourceZipSugQuery = gql`
  query ($param: String!) {
    esResourceSuggest(param: $param, field: "zip_code") {
      json
    }
  }
`;


const ITEM_HEIGHT = 48;
const styles = theme => ({
  input: {
    height: '100%'
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  search: {
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '300px',
      maxWidth: '300px',
      minWidth: '300px',
    },
    [theme.breakpoints.up('md')]: {
      width: '600px',
      maxWidth: '600px',
      minWidth: '300px',
    },
  },
  locsearch: {
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '300px',
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
  },
});

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    overflow: 'hidden',
    height: '100%',
    color: state.isSelected ? 'blue' : 'green',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    width: '100%',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    minWidth: '15em',
    height: '100%',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}


export const SelectWrapped =  function (props) {
  const [InputValue, setInputValue] = React.useState();
  const { classes, value, name, onChange, inputRef, ...other } = props;

  const onInputChange = function (input, actionMeta) {
    console.log(input.toLowerCase())
    var res = input.split(/[,;/]+/)
    console.log(res)
    if (res.length > 1){
      if (value){
        onChange([...value,{label: res[0], value: res[0]}])
      } else {
        onChange([{label: res[0], value: res[0]}])
      }
      setInputValue("")
      return "";
    }
    console.log(actionMeta)
    setInputValue(input)
    return input;
  }

  const getOptions = async function (input, kw, cb) {
    const queryT = kw === 'resource' ? CategorySugQuery : ResourceZipSugQuery;
    if (input === ''){
      var promise1 = new Promise(function(resolve, reject) {
          setTimeout(resolve, 100, null);
      });
      return promise1;
    }
    const options = await client.query({
      query: queryT,
      variables: { param: input },
    }).then( result => {
      let options;
      if (result.data.searchCategories){
        options = result.data.searchCategories.map(o => ({
          value: o.category,
          label: o.category,
        }));
      }
      else {
        options = result.data.esResourceSuggest.json.suggestions.map(o => ({
          value: o,
          label: o,
        }));
      }
      options.sort(function(a, b) {
            return a.label.length - b.label.length;
      })
      options.unshift({'value': input, 'label': `${input}`})
      return options
    });
    console.log(options)
    return options;
  }


  return (
      <AsyncSelect
        ref={inputRef}
        name="resource-search"
        clearable
        isSearchable
        value={value}
        inputValue={InputValue}
        isMulti={ true }
        cacheOptions
        noOptionsMessage={() => (<Typography>{'No results found'}</Typography>)}
        onChange={ onChange }
        autoload={false}
        onInputChange={onInputChange}
        loadOptions={async (input) => getOptions(input, name)}
        {...other}
      />
  );
}

const Search = function (props) {

  const [CategoryValue, setCategoryValue] = useState(null);
  const [LocationValue, setLocationValue] = useState(null);
  const [Search, setSearch] = useState('');
  const [Page, setPage] = useState(1);

  useEffect(
    () => {
      // if a query in the url bar, go ahead and populate state.
      if( window.location.pathname === '/r/search'){
        const query = qs.parse(window.location.hash.replace('#', ''));
        if (Object.keys(query).length) {
          const keyMap = {
            location: setLocationValue,
            resource: setCategoryValue,
          };

          Object.keys(keyMap).forEach((key) => {
            if (query[key] && typeof query[key] === 'string') {
              keyMap[key]([{ value: query[key], label: query[key] }]);
            } else if (query[key] && typeof query[key] === 'object') {
              keyMap[key](query[key].map(v => ({ value: v, label: v })));
            }
          });
          setPage(query['page'] ? Number(query['page']) : 1);
        }
      }
    },
    [],
  );

  useEffect(
    () => {
      firesearch()
    },
    [CategoryValue, LocationValue, Page, Search],
  );

  const firesearch = () => {
    let resource_qlst;
    let location_qlst;
    if ( isIterable(CategoryValue)){
      resource_qlst = CategoryValue ?
        CategoryValue.map(o => o.label): null;
    } else {
      resource_qlst = CategoryValue ?
        CategoryValue.label: null;
    }
    if ( isIterable(LocationValue)){
      location_qlst = LocationValue ?
        LocationValue.map(o => o.label): null;
    } else {
      location_qlst = LocationValue ?
        LocationValue.label: null;
    }

    if (resource_qlst == null && location_qlst == null){
      return null
    }

    const qst = qs.stringify({
      location: location_qlst,
      resource: resource_qlst,
      page: 1,
    });
    console.log(qst)
    // Quick and dirty hack
    if (Object.keys(qst).length !== 0 ) {
      props.history.push('/');
      props.history.push(`/search#${qst}`);
    }

    // record search event
    //ReactGA.event({
      //category: 'Search',
      //action: 'Search Executed',
      //label: `Resources: ${resource_qlst.join(', ')}; Locations: ${location_qlst.join(', ')}`
    //});

    //window.location.reload()
  }


  const {classes, className} = props;


  return (
    <span align='center' className={ className }>
      <div className={classes.flex}>
        <SelectWrapped
          classes={classes}
          className={classes.search}
          onChange={setCategoryValue}
          value={CategoryValue}
          name="react-select-chip"
          classes={classes}
          name={'resource'}
          placeholder={(
            <div style={{textAlign: 'center', margin: 'auto', display: 'table'}}>
              "Search Welnity"
            </div>
          )}
          fullWidth={true}
          styles={customStyles}
        />
        <div className={classes.sectionDesktop}>
        {' '}
        </div>
        <SelectWrapped
          classes={classes}
          className={classes.locsearch}
          value={LocationValue}
          name="react-select-chip"
          name={'location'}
          placeholder={"Zip Code (e.g. 77036)"}
          onChange={setLocationValue}
          fullWidth={true}
          styles={customStyles}
        />
        <IconButton onClick={ e =>{
            e.preventDefault();
            firesearch();
          }}>
          <Icon>
            search
          </Icon>
        </IconButton>
      </div>
    </span>
  );
}

export {CategorySugQuery};
export default withRouter(withStyles(styles)(Search));
