import React, { Component } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import {SelectWrapped} from '../Search/SearchRes';
import { Error }  from '../Generic/forms';

const SubmitResourceMut = gql`
  mutation (
    $name: String!,
    $details:String!,
    $newCategories: [String],
    $phone: String,
    $address: String,
    $website: String,
    ) {
    addResourceSubmission(
      name: $name,
      details: $details,
      newCategories: $newCategories,
      phone: $phone,
      address: $address,
      website: $website,
      ) {
      status
      errors
    }
  }
`;

class SubmitResourceStub extends Component {
    constructor(props){
    super(props);
    this.state = {
      loading: false,
      redirectToReferrer: false,
      isBlocking: false,
      name: '',
      phone: '',
      address: null,
      website: null,
      details: '',
      errors: null,
    };
    this.textarea = React.createRef();
  }

  handleCategory = name => value => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, isBlocking: true });
    if (prop == 'details'){
      const resize = () => {
        const textarea = this.textarea.current;
        if (!textarea || textarea === undefined) {
          return
        }
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight+'px';
      }
      resize()
    }
  };

  handlePost = () => {
    const { name, details, phone, address, website } = this.state;
    this.props
      .mutate({
        variables: {
          name,
          newCategories: this.state.categoryValue ? this.state.categoryValue.map(o => o.label): null,
          details,
          phone,
          address,
          website,
        },
      })
      .then(({ data }) => {
        if ('error' in data) {
          console.log(data.error);
          return <p>{data.error.message}</p>;
        }
        if (data.addResourceSubmission.status === 200) {
          this.setState({ redirectToReferrer: true });
          if (this.props.cb) {
            this.props.cb();
          }
        }
        if (data.addResourceSubmission.status === 500) {
          console.log('Something went wrong while adding Event');
          this.setState({errors: data.addResourceSubmission.errors, loading:false})
        }
        console.log('mutation to add Resource', data);
      });
  };

  render() {
    const { classes } = this.props;
    const { redirectToReferrer } = this.state;
    const { errors, loading } = this.state;
    const { isBlocking } = this.state;

    if (redirectToReferrer && this.props.location) {
      const { from } = this.props.location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }

    return (
      <div className={classes.wrapper}>
        <Grid container={ true } wrap="nowrap" spacing={8} alignItems="stretch" alignContent="center" direction="column">
        <Grid item>
        <Error errors={errors} name={'__all__'} />
        <form  noValidate autoComplete="off" onSubmit={() => null}>
          <div>
            <Prompt
              when={isBlocking}
              message={location => `Are you sure you want to leave ${window.location.pathname}`}
            />
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'name'} />
              <Input
                id="name"
                className={classes.input}
                type={'text'}
                value={this.state.name}
                onChange={this.handleChange('name')}
                inputProps={{
                    placeholder: "Resource name (e.g. XYZ Recovery)",
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'categories'} />
              <div>
              <SelectWrapped
                fullWidth
                className={classes.input}
                value={this.state.categoryValue}
                onChange={this.handleCategory('categoryValue')}
                name="react-select-chip"
                name={'resource'}
                placeholder={"Category (e.g. Mental Health, Transportation)"}
                multi={true}
                instanceId={'react-select-chip'}
                id={'react-select-chip'}
                simpleValue={true}
              />
              </div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'phone'} />
              <Input
                id="resPhoneNumber"
                className={classes.input}
                type={'text'}
                value={this.state.phone}
                onChange={this.handleChange('phone')}
                inputProps={{
                    placeholder: "Contact (e.g. xxx-xxx-xxxx)",
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'address'} />
              <Input
                id="resAddress"
                className={classes.input}
                type={'text'}
                value={this.state.address}
                onChange={this.handleChange('address')}
                inputProps={{
                    placeholder: "Location (e.g. street name, city, zip code)",
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'website'} />
              <Input
                id="resWebAddress"
                className={classes.input}
                type={'text'}
                value={this.state.website}
                onChange={this.handleChange('website')}
                inputProps={{
                  placeholder: "Website (e.g. https://www...)",
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'details'} />
              <Input
                id="details"
                className={classes.input}
                type={'text'}
                fullwidth={true}
                multiline={true}
                rows={4}
                value={this.state.details}
                onChange={this.handleChange('details')}
                inputRef={this.textarea}
                inputProps={{
                  placeholder:
                  `Resource description`,
                }}
              />
            </FormControl>
            <Button
              raised
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={this.handlePost}>
              Submit Resource
            </Button>
          </div>
        </form>
        </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  input: {
    width:'100%',
    height:'100%',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  button:{
    //backgroundColor: theme.palette.primary.A100,
    backgroundColor: 'white',
    color: theme.palette.primary[600],
  },
  wrapper: {
    maxWidth: 900,
    margin: "0 auto",
  },
  formControl: {
    margin: theme.spacing.unit,
    flexWrap: 'wrap',
    display: 'flex',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const SubmitResource = withStyles(styles)(graphql(SubmitResourceMut)(SubmitResourceStub));

SubmitResource.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default SubmitResource;
export default SubmitResource;
