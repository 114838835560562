import React, { Component } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { OnlyForAuth } from '../../screens/Auth/utils.js';
import LeftDrawer from '../../partials/Drawers/LeftD';
import Linear from 'src/components/Loading/Linear.js';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  sizer: prop => {
    return (
      {
        width: '90%', maxWidth: '3000px', margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        }
      }
    )
  },
  hidesm: prop => {
    return (
      {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        }
      }
    )
  },
})
);

const Floor = function({left, children, right, ...rest}){

  const classes = useStyles();

  var lazysusp = (<Linear />);

  var childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { ...rest }));

  return (
    <Grid
      className={classes.sizer}
      container={ true }
      wrap="nowrap"
      spacing={2}
      justify="center"
      alignContent="left"
      direction="row"
    >
      <Grid className={classes.hidesm} item md={2} xs={2}>
        <React.Suspense fallback={lazysusp}>
          {left}
        </React.Suspense>
      </Grid>
      <Grid item md={7} xs={12}>
        <React.Suspense fallback={lazysusp}>
          {childrenWithProps}
        </React.Suspense>
      </Grid>
      <Grid className={classes.hidesm} item md={2} xs={0}>
        <React.Suspense fallback={lazysusp}>
          {right}
        </React.Suspense>
      </Grid>
    </Grid>
  );
}


const SoulContent = function({left, children, right, ...rest}){

  const classes = useStyles();

  var lazysusp = (<Linear />);

  var childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { ...rest }));

  return (
    <Grid
      className={classes.sizer}
      container={ true }
      wrap="nowrap"
      spacing={2}
      justify="center"
      alignContent="left"
      direction="row"
    >
      <Grid item md={12} xs={12}>
        <React.Suspense fallback={lazysusp}>
          {childrenWithProps}
        </React.Suspense>
      </Grid>
    </Grid>
  );
}


export const Basic = function({children, right, ...rest}){
  let left = (<OnlyForAuth children={(<LeftDrawer />)} />)
  return (
    <Floor
      left={left}
      children={children}
      right={right}
      {...rest}
      />
  );
}

export const WrapperBasic = function(children){
  const WrapperChildren = children;
  return (props) => (<Basic children={(<><WrapperChildren {...props}/></>)} {...props}/>)
}

export {SoulContent};
export default Floor;
