import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { emphasize, fade, darken } from '@material-ui/core/styles/colorManipulator';

import {stringToColor} from '../Generic/RandomColor';

const styles = theme => ({
  root: {
    height: 'inherit',
    whiteSpace: 'nowrap',
    cursor: 'inherit',
    userSelect: 'text',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chip: {
    padding: theme.spacing.unit/2,
    margin: theme.spacing.unit/2,
  },
  label: {
    userSelect: 'text',
    whiteSpace: 'nowrap',
    cursor: 'inherit',
  },
  clickable: {
    cursor: 'text',
    userSelect: 'text',
  },
  separator: {
    marginRight: theme.spacing.unit,
  },
  pchip: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '20rem',
  },
  shaded: {
    color: theme.palette.grey.A700
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
  bigIcon: {
    margin: theme.spacing.unit,
    width: '12rem',
    height: '12rem',
    color: 'white',
    alignContent: 'center',
    wordWrap: 'break-word',
    textAlign: 'center',
  },
  endChip: {
    alignSelf: 'end',
  },
});

//function Chip(props) {
  //const {component} = props;
  //return (
    //<component>
    //{props.label}
    //</component>
  //);
//}

function ProfileChip(props) {
  const { user, classes, theme } = props;
  if ( user ){
    let src = user.profilePicSm;
    return (
        <div style={{display: 'flex'}}>
        <Link to={`/profile/${ user.username }`} style={{ textDecoration: 'none' }}>
          <span className={classes.container}>
          <Avatar
            style={{ backgroundColor: stringToColor(user.username) }}
            className={`${classes.fleft} ${classes.separator}`}
            src={`${src ? src : ''}`}
            children={user.fullName.charAt(0)}
            imgProps={{
              style: {
                minWidth: '100%',
                minHeight: '100%',
              }
            }}
            component={(magic) =>
              {
                const {children, ...rest} = magic;
                return (
                  <Typography
                    style={{ color:'white'}}
                    variant='body2' align="left" noWrap {...rest}>
                    {children}
                  </Typography>
                );
              }
            }
          />
          <React.Fragment>
          <Chip
            component='div'
            clickable
            variant="outlined"
            classes={classes}
            label={(
              <section>
                <div className={classes.pchip}> <strong>{user.fullName} @{user.username}</strong></div>
                <div className={`${classes.pchip} ${classes.shaded}`}> {user.position}, {user.institution}</div>
              </section>
            )}
            className={`${classes.chip} ${classes.fleft}`}
          />
          </React.Fragment>
          </span>
        </Link>
        </div>
    );
  }
  else {
    return null;
  }
}


ProfileChip.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ProfileChip);
