import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';

import { graphql } from "react-apollo";
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import ToolTipStyle from 'src/components/Generic/ToolTipStyle';

const InvitesLeftQuery = gql`
  query InvPretty {
    invitesPretty {
      left
    }
  }
`;

const styles = theme => ({
  badge: {
    top: 'auto',
  },
  button: {
    color: 'white',
    fontSize: 6,
  },
  red: {
    backgroundColor: 'red',
    padding: theme.spacing.unit / 2,
  },
  tooltip: ToolTipStyle(theme),
});

class Invitations extends React.Component {
  render() {
    const { classes, data } = this.props;

    if (data.error){
      return ( <p> error </p>);
    }

    if (data.loading) {
      return <Linear />;
    }

    return (
    <Badge
      color='default'
      badgeContent={
        <div className={(data.invitesPretty.left ? classes.red : 'hidden')}>
          <strong>{data.invitesPretty.left}</strong>
        </div>
      }
      className={classes.badge}
    >
      <Tooltip classes={classes} title="My Invitations" placement="bottom">
      <Link to='/invitations'>
        <IconButton color="secondary" className={classes.button} aria-label="Add an alarm">
          <Icon>
            person_add
          </Icon>
        </IconButton>
      </Link>
      </Tooltip>
    </Badge>
    );
  }
}
export default withStyles(styles)(graphql(InvitesLeftQuery)(Invitations));
