import React, { Component } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from '@material-ui/core/Card';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { Error }  from '../Generic/forms';
import {SelectWrapped} from '../Search/SearchRes';
import {SelectWrapped as LocationSug} from '../Location/LocationSug';

const AddPanelMut = gql`
  mutation AddPanel($group: ID, $text:String!, $zipCode: String!, $newCategories: [String]){
    addPanel(group: $group, text:$text, zipCode: $zipCode, newCategories: $newCategories){
      panel {
        id
        text
        hidden
        createdAt
        modifiedAt
      }
      status
      errors
    }
  }
`;

class AddPanelStub extends Component {
  constructor(props){
    super(props)
    this.state = {
      isBlocking: false,
      text: '',
      zipCode: '',
      newCategories: [],
      errors: null,
      locationValue: null,
    };
    this.textarea = React.createRef();
  }
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, isBlocking:true });
    if (prop == 'text'){
      const resize = () => {
          const textarea = this.textarea.current;
          if (!textarea || textarea === undefined) {
            return
          }
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight+'px';
      }
      resize()
    }
  };

  handleSug = name => value => {
    console.log(value)
    this.setState({
      [name]: value,
    });
  };

  handlePost = () => {
    const { text, zipCode} = this.state;
    const {cb, group, history} = this.props;
    console.log(this.state);
    this.props
      .mutate({
        variables: {
          'group': group,
          'text':text,
          'zipCode': this.state.locationValue ? this.state.locationValue.label: null,
          newCategories: this.state.categoryValue ? this.state.categoryValue.map(o => o.label): null,
        },
        refetchQueries:['RepliedPanels','AuthoredPanels','FollowedPanels','DetailPanel', 'TimeLine', 'UnreadNoti'],
      })
      .then(({ data }) => {
        console.log(data)
        if ('error' in data) {
          return null;
        }
        if (data.addPanel.status === 200) {
          if (data.addPanel.panel) {
            console.log('testing in panel')
            this.setState({ text: '', isBlocking:false });
            if (cb) {
              cb();
            }
            history.push(`/detail/p/${data.addPanel.panel.id}`);
          }
        }
        if (data.addPanel.status === 500) {
          this.setState({ errors: data.addPanel.errors });
        }
      }).then(() => {
      });
  };

  render() {
    const { classes } = this.props;
    const { isBlocking, errors  } = this.state;

    let postable;
    if (this.state.text.length === 0 || this.state.locationValue === null || this.state.categoryValue === null){
         postable = false;
    } else {
       postable = true;
    }


    return (
      <form className={classes.wrapper} noValidate autoComplete="off" onSubmit={() => null}>
        <div>
          <Prompt
            when={isBlocking}
            message={location => `Are you sure you want to leave ${window.location.pathname}`}
          />
          <Error errors={errors} name={'__all__'} />
          <FormControl className={classes.formControl}>
            <Error errors={errors} name={'new_categories'} />
            <div>
            <SelectWrapped
              classes={classes}
              className={classes.input}
              fullWidth
              value={this.state.categoryValue}
              onChange={this.handleSug('categoryValue')}
              name="react-select-chip"
              name={'resource'}
              placeholder={"Category (e.g. Mental Health, Transportation)"}
              multi={true}
              instanceId={'react-select-chip'}
              id={'react-select-chip'}
              simpleValue={true}
            />
            </div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Input
              id="panel"
              type={'text'}
              fullwidth={true}
              multiline={true}
              inputRef={this.textarea}
              inputProps={{
                placeholder: "Post your urgent request (e.g. Does anyone know any trauma counselor? Please help)",
                style:{
                  resize: 'vertical',
                  },
                }}
              rows={4}
              className={classes.formControl}
              value={this.state.text}
              onChange={this.handleChange('text')}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Error errors={errors} name={'zip_code'} />
            <LocationSug
              classes={classes}
              value={this.state.locationValue}
              name="react-select-chip"
              className={classes.input}
              name={'location'}
              placeholder={"Location (e.g. \"Katy\", or \"77036\", or \"Texas\")"}
              onChange={this.handleSug('locationValue')}
              fullWidth={true}
            />
          </FormControl>
          <div>
            <strong>Do not share any information protected by HIPAA</strong>
          </div>
          { postable && (
            <Button
              raised
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={this.handlePost}
            >
              Post
            </Button>
          )}
        </div>
      </form>
    );
  }
}

const styles = theme => ({
  input: {
    width:'100%',
    height:'100%',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  wrapper: {
    display: 'block',
  },
  card: {
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    display: 'flex',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const AddPanel = withStyles(styles)(graphql(AddPanelMut)(AddPanelStub));

AddPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(AddPanel);
