import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';

import { graphql, ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';

const LogoutMut = gql`
  mutation Logout {
    logout {
      status
    }
  }
`;

class LogoutStub extends Component {
  handlePost = (client) => {
    this.props.mutate().then(({ data }) => {
      if ('error' in data) {
        return null;
      }
      if (data.logout.status === 200) {
        window.location.pathname = '/';
        client.resetStore();
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <ApolloConsumer>
      { client => (
      <div>
        <form
          onSubmit={event => {
            event.preventDefault();
            this.handlePost(client);
          }}
        >
          <p>
            <Button variant="contained" color="primary" className={classes.button} onClick={this.handlePost}>
              Logout
            </Button>
          </p>
        </form>
      </div>
      )}
      </ApolloConsumer>
    );
  }
}

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
});

const Logout = graphql(LogoutMut)(LogoutStub);

Logout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export {LogoutMut};
export default withStyles(styles)(Logout);
