import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';

import Select from 'react-select';
import AsyncSelect from 'react-select/async';
// import 'react-select/dist/react-select.css';
import debounce from 'debounce';

import { graphql, Query } from 'react-apollo';
import { Mutation } from "react-apollo";

import SelectStyle from './SelectStyle'
import ToolTipStyle from './ToolTipStyle';
import {UserQuery} from '../UserSearch/UserSearchComponent'
import { client } from '../../utils/makeApolloClient';


const styles = theme => ({
  root: {
    padding: `5 ${theme.spacing.unit * 3}px`,
    width: '100%',
  },
  button: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 4,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit / 4,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  volume: {
    minHeight: theme.spacing.unit * 30,
  },
  tooltip: ToolTipStyle(theme),
});

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    overflow: 'hidden',
    color: state.isSelected ? 'blue' : 'green',
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    display: 'flex',
    minWidth: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

class Option extends React.Component {
  handleClick = event => {
    this.props.onSelect(this.props.option, event);
  };

  render() {
    const { children, isFocused, isSelected, onFocus } = this.props;

    return (
      <MenuItem
        onFocus={onFocus}
        selected={isFocused}
        onClick={this.handleClick}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {children}
      </MenuItem>
    );
  }
}

class SelectWrapped extends React.Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(value) {
    console.log(value.toLowerCase())
    return value.toLowerCase();
  }

  async getOptions(input) {
    if (input === ''){
      var promise1 = new Promise(function(resolve, reject) {
          setTimeout(resolve, 100, null);
      });
      return promise1;
    }
    const options = await client.query({
      query: UserQuery,
      variables: { terms: [input], page: 1 },
    }).then( result => {
      console.log(result, 'results here');
      const options = result.data.userEsSearch.users.map(o => ({
        value: o.username,
        label: `${o.fullName} @${o.username}`,
      }));
      return options;
    });
    return options;
  }


  render() {
  const { classes, value, name, onChange, ...other } = this.props;
  return (
      <AsyncSelect
        name="user-search"
        className={classes.root}
        clearable
        isSearchable
        value={value}
        isMulti
        noOptionsMessage={()=>(<Typography>{'No results found'}</Typography>)}
        filterOptions={options => options.filter(o => !/Create option/i.test(o.label))}
        onChange={ onChange }
        autoload={false}
        onInputChange={this.onInputChange}
        loadOptions={this.getOptions}
      />
  );
}
}


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class UtilityDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      userSelection: [],
      pvmessage: '',
    };
    this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.EventDrivenChange = this.EventDrivenChange.bind(this);
    this.debouncedSearch = debounce(this.search, 550);
  }

  search = () => {
    return
  }

  handleClose = () => {
    this.props.onClose('test');
  };

  EventDrivenChange = prop => event => {
    console.log(event.target)
    this.setState({ [prop]: event.target.value, isBlocking:true });
  };

  handleChange = name => value => {
    console.log(name, value)
    this.setState({
      [name]: value,
    });
    // TODO: Direct to search results page
    this.debouncedSearch()
  };

  render() {
    const { classes, onClose, item, shareMut, ...other } = this.props;

    return (
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        scroll={'body'}
        maxWidth={'md'}
        onClose={this.handleClose} aria-labelledby="Utility-dialog-title" {...other}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="Utility-dialog-title">Share with other users</DialogTitle>
        <DialogContent>
          <div className={classes.volume}>
            <Input
              fullWidth
              inputComponent={SelectWrapped}
              value={this.state.userSelection}
              placeholder="Select category"
              name="react-select-chip"
              endAdornment={
                <InputAdornment position="end">
                <IconButton>
                  <Icon>
                    search
                  </Icon>
                </IconButton>
                </InputAdornment>}
              inputProps={{
                onChange: this.handleChange('userSelection'),
                classes,
                multi: false,
                simpleValue: true,
                fullWidth: true,
                styles: customStyles,
              }}
            />
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel htmlFor="Private message">Private Message</InputLabel>
              <Input
                id="comment"
                type={'text'}
                fullwidth={true}
                multiline={true}
                rows={4}
                className={classes.formControl}
                value={this.state.pvmessage}
                onChange={this.EventDrivenChange('pvmessage')}
              />
            </FormControl>
            <Divider/>
            <Mutation mutation={shareMut}>
              {(shareMany, { data }) => (
                <Button
                  variant="outlined"
                  raised
                  color="primary"
                  className={classes.button}
                  onClick={ e => {
                    shareMany({
                      variables: {
                        Id: item.id,
                        tt: this.state.userSelection,
                        message: this.state.pvmessage,
                      },
                      refetchQueries: ['searchResourcesPaged', 'searchBookmarksPaged'],
                    })
                    .then( data =>
                      this.handleClose()
                    );
                  }}
                >
                  Share
                </Button>
            )}
          </Mutation>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

UtilityDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const UtilityDialogWrapped = withStyles(styles)(UtilityDialog);

class ShareButton extends React.Component {
  constructor(props){
    super(props);
    this.state = {
          open: false,
        };
  }

  handleClickOpen = () => {
    this.setState({ open: true, });
  };

  handleClose = value => {
    this.setState({ open: false });
  };

  render() {
    const { classes, item, shareMut, tooltip } = this.props;
    return (
      <React.Fragment>
        <Tooltip classes={classes} title={tooltip} placement="right">
        <div className={classes.vertical}>
        <IconButton
          color="secondary"
          className={classes.button}
          aria-label="Add an alarm"
          onClick={this.handleClickOpen}
        >
          <Icon
            fontSize={'small'}>
            share
          </Icon>
        </IconButton>
        <Typography variant='caption' gutterBottom aligh='right'>
          Share
        </Typography>
        </div>
        </Tooltip>
        <UtilityDialogWrapped
          item={item}
          open={this.state.open}
          onClose={this.handleClose}
          shareMut={shareMut}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ShareButton);
