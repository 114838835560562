import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FaceIcon from '@material-ui/icons/Face';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { graphql } from 'react-apollo';
import { Mutation } from "react-apollo";
import gql from 'graphql-tag';


import ProfileChip from '../Profile/ProfileChip.js';
import Linear from '../Loading/Linear.js';
import ToolTipStyle from '../Generic/ToolTipStyle';
import CategoryPill from './CategoryPill'
import ReadMore from '../Generic/ReadMore'
import {TabLinkify} from '../Generic/LinkifyComponent';

var qs = require('qs');


export const SubmittedResourceDetailQuery = gql`
  query DetailSubmittedResourceQuery($id:ID) {
    resourceSubmission(id: $id) {
      id
      name
      categories{
        edges{
          node{
            category
            id
          }
        }
      }
      submittedBy {
        id
        username
        fullName
        institution
        position
        profilePicSm
      }
      phone
      address
      website
      details
      createdAt
      modifiedAt
    }
  }
`;

const styles = theme => ({
  block: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    width: '100%',
  },
  wrapper: {
    maxWidth: 800,
    margin: "0 auto",
    align: "center",
  },
  card: {
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  smallicons: {
  },
  newline:{
    whiteSpace: 'pre-wrap',
  },
  title: {
    margin: theme.spacing.unit,
    color: theme.palette.primary[600],
  },
  pos: {
    marginBottom: 12,
  },
  spancontainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  spanacross: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
  tooltip: ToolTipStyle(theme),
});

class DetailResource extends React.Component  {

  render() {
    const { data, classes, showall, className: BoxClass  } = this.props;

    if (data.loading) {
      return <Linear />;
    }

    if (data.error) {
      return <p>{data.error.message}</p>;
    }
    let details;
    if (showall){
      details = (
        <Typography className={classes.newline} >
          { data.resourceSubmission.details ? data.resourceSubmission.details : 'No details provided' }
        </Typography>
      );
    } else {
      details = (
        <ReadMore variant={'body1'} className={classes.newline} text={ data.resourceSubmission.details ? data.resourceSubmission.details : 'No description provided' } />
      );
    }

    return (
    <Box className={BoxClass}>
      <TabLinkify>
      <Card className={classes.card}>
        <CardContent>
          <div style={{ display:'flow-root'}}>
            <span className={classes.block}>
              <ProfileChip
                user={data.resourceSubmission.submittedBy }
              />
              <Typography style={{width: '100%', flex: 1}} component="p" align='right'>
                <Link to={`/detail/submitted_resource/${data.resourceSubmission.id}`}>
                  Posted {moment(data.resourceSubmission.createdAt).fromNow()}
                </Link>
              </Typography>
          </span>
          </div>
        </CardContent>
        <span className={classes.spancontainer}>
          <span
            className={classes.fleft}>
            <Typography variant='body1' align='left' gutterBottom className={classes.title}>
              <Link to={`/detail/submitted_resource/${data.resourceSubmission.id}`} className={classes.title}>
                { data.resourceSubmission.name }
              </Link>
            </Typography>
          </span>
        </span>
        {data.resourceSubmission.categories && data.resourceSubmission.categories.edges.map((item, i ) => {
          if (i < 3){
            return (
              <span >
                <CategoryPill key={i} category={item.node.category} />
              </span>
            );
          }
          return null
        })}
        <span >
        <Typography variant='body1' align='left' gutterBottom>
          <strong>Contact Info:</strong>
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          <strong>Phone:</strong> {data.resourceSubmission.phone}
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          <strong>Address:</strong> {data.resourceSubmission.address}
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          <strong>Website:</strong>
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          {data.resourceSubmission.website ?
            data.resourceSubmission.website
            : 'not provided'}
        </Typography>

        <Typography variant='body1' align='left' gutterBottom>
          <strong>Details:</strong>
        </Typography>
        <Typography variant='body1' align='left' gutterBottom>
          {details}
        </Typography>

        </span >
      </Card>
      </TabLinkify>
    </Box>
    );
  }
}

DetailResource.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ResourceDetail = graphql(SubmittedResourceDetailQuery,{
  options: ({ match }) => {
    return {
      variables: {
        id: match.params.id,
      },
    };
  },
})(DetailResource)

const DetachedSubmittedResource = withRouter(withStyles(styles)(DetailResource));

export {DetachedSubmittedResource};
export default withRouter(withStyles(styles)(ResourceDetail));
