import gql from 'graphql-tag';

export const AutoSugQuery = gql`
  query AutoComplete ($token: String!){
    usernameSug (username: $token) {
      username
      fullName
    }
  }
`;

export const GroupAutoSugQuery = gql`
  query GroupAutoComplete ($token: String!){
    groupSug (name: $token) {
      name
    }
  }
`;

