import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

// import InviteList from './ListInvitations.js';

import { client } from '../../../utils/makeApolloClient';
import ToolTipStyle from '../../Generic/ToolTipStyle';
import {Error} from '../../Generic/forms.js';
import { JoinGroupMutation } from '../graphql/Mutations.js'

const useStyles = makeStyles(theme => ({
  warning: {backgroundColor: 'white', padding: theme.spacing.unit,},
  autoFlow: {
    overflowX: 'auto',
  },
  pushRight: {
    marginLeft: theme.spacing.unit ,
  },
  button: {
    textTransform: 'none',
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 4,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit / 4,
  },
  positivebutton: prop => ( {
    backgroundColor: theme.palette.primary['500'],
    color: 'white',
  } ),
  actionbutton: prop => ( {
    margin: theme.spacing.unit * 2,
    width: '50%'
  } ),
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  tooltip: ToolTipStyle(theme),
}));

export const JoinTo = function (props) {

  const [Errors, setErrors] = React.useState(null);
  const [Success, setSuccess] = React.useState(false);
  const classes = useStyles();

  const { key, group, match } = props;

  const joinGroup = async (e) => {
    await client.mutate({
      mutation: JoinGroupMutation,
      variables:{
        group: group.id,
      },
      refetchQueries: ['SingleGroup', 'PagedGroups', 'MyGroups'],
    }).then(({data, ...rest}) => {
      if (data.loading) return null;
      if (data.error) console.log(data.error);
      if (data.joinGroup.status === 200){
        setSuccess(true)
        return null
      } else {
        setSuccess(true)
        setErrors(data.joinGroup.errors)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <>
      <div className={classes.pushRight}>
      <Tooltip classes={classes} title="Join Group" placement="bottom">
        <div className={classes.vertical}>
        <IconButton
          onClick={joinGroup}
          className={classes.button}
          aria-label="Join Group"
          >
          <Icon color={Success ? 'primary' : 'secondary'} fontSize={'small'} style={{transform: 'scaleX(-1)'}} fontSize={'small'} > people </Icon>
        </IconButton>
        <Typography variant='caption' gutterBottom aligh='right'>
          Join Group
        </Typography>
        </div>
      </Tooltip>
      </div>
    </>
  );
}

export function GroupJoin(props) {
  const [Errors, setErrors] = React.useState(null);
  const classes = useStyles();
  const { key, group, match } = props;

  const joinGroup = async (e) => {
    await client.mutate({
      mutation: JoinGroupMutation,
      variables:{
        group: group.id,
      },
      refetchQueries: ['SingleGroup', 'PagedGroups', 'MyGroups'],
    }).then(({data, ...rest}) => {
      if (data.loading) return null;
      if (data.error) console.log(data.error);
      if (data.joinGroup.status === 200){
        return null
      } else {
        setErrors(data.joinGroup.errors)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <>
      { group.whatAboutMe ? (<></>):(
        <span style={{display: 'flex'}}>
          <Button size='small' fullWidth={true} onClick={joinGroup} className={`${classes.actionbutton} ${classes.positivebutton}`}>
            + Join
          </Button>
        </span>
      )}
    </>
  )
}
