import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';

import { graphql } from "react-apollo";
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import ToolTipStyle from 'src/components/Generic/ToolTipStyle';

const NewAnnQuery = gql`
  query newAnn {
    newAnnouncements (count:20){
      id
      title
    }
  }
`;

const styles = theme => ({
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  ellipsisContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
  },
  listpad: {
    padding: theme.spacing.unit,
  },
  button: {
    color: 'white',
    fontSize: 6,
  },
  tooltip: ToolTipStyle(theme),
});

class NewAnn extends React.Component {
  state = {
  };

  render() {
    const {data, classes} = this.props;
    if (data.error){
      return ( <p> error </p>);
    }

    if (data.loading) {
      return <Linear />;
    }

    return (
      <div className={classes.container}>
        <Link to={`/all/announcements`} style={{ textDecoration: 'none' }}>
          <Typography variant='subheading' >
            <strong>New announcements</strong>
          </Typography>
        </Link>
        {data.newAnnouncements.map(r =>
          <React.Fragment>
          <Link to={`/detail/announcement/${r.id}`} style={{ textDecoration: 'none' }}>
            <List className={classes.ellipsisContainer}>
              <Typography className={classes.ellipsis} variant='body1' align="left" noWrap>
                { r.title }
              </Typography>
            </List>
          </Link>
          <Divider />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(graphql(NewAnnQuery)(NewAnn));
