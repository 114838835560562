import React, { Component, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

var qs = require('qs');

const useStyles = makeStyles(theme => ({
  example: prop => {
    return (
      {
        color: theme.palette.primary.A100,
        [theme.breakpoints.down('sm')]: {
        }
      }
    )
  },
  center: prop => {
    return (
      {
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
        }
      }
    )
  },
})
);



export default function(props){
  const [Hidden, setHidden] = React.useState(true);
  const [JustChildren, setJustChildren] = React.useState(props.jschild);
  const classes = useStyles();
  const {children} = props;

  if (JustChildren){
    return (<>{children}</>)
  }

  return (
    <>
    { Hidden ? (
      <Grid item>
        <div className={classes.center} onClick={e => setHidden(!Hidden)}>
          <Typography  className={classes.newline}  variant='body1' align='center'>
            <strong>[Click here to see more...]</strong>
          </Typography>
        </div>
      </Grid>
      ):(
      <Grid item>
        {children}
        <div className={classes.center} onClick={e => setHidden(!Hidden)}>
        <Typography  className={classes.newline}  variant='body1' align='center'>
          <strong>[Click here to see less...]</strong>
        </Typography>
        </div>
      </Grid>
      )
    }
    </>
  );
}


