import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';

import { Mutation } from "react-apollo";
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const MarkAllReadMut = gql`
  mutation MarkAllNoti {
     notiMarkAll {
      status
    }
  }
`;

const styles = theme => ({
});

class MarkAllReadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const {classes} = this.props
    return (
      <Mutation mutation={MarkAllReadMut}>
        {(Mark, { data }) => (
          <Button variant="outlined" color="primary"
            className={classes.button}
            onClick={ e => {
            Mark({
              refetchQueries: ['AllNoti', 'UnreadNoti'],
            });
            }}
          >
            Mark All as Read
          </Button>
        )}
      </Mutation>
    );
  }
}

export {MarkAllReadMut};
export default withStyles(styles)(MarkAllReadButton);
