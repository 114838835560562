import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { graphql, Query } from 'react-apollo';
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import Pager from '../Generic/paged';
import ActivityCard from './Panel.js';
import { DetachedDetailEvents } from '../Events/Details';
import { DetachedResource } from '../Resource/DetailResource.js';
import { DetachedSubmittedResource } from '../Resource/DetailSubmittedResource.js';
import { DetachedDetailAnn } from '../Announcements/Details';
import { DetachedDetailJobs } from '../Jobs/Details';
import { AnnFragment } from '../Announcements/fragments';
import { EventFragment } from '../Events/fragments';
import { ResourceFragment, ResourceSubmissionFragment } from '../Resource/fragments';
import { JobFragment } from '../Jobs/fragments';
import ManyButton from './ManyModal';
import { OnlyForAuth } from '../../screens/Auth/utils.js';

import TabedPages from '../PageTabs/Tabs';

const TimelineQuery = gql`
  query TimeLine($page:Int, $filter:String){
    currentTimeline(page:$page, perpage: 50, filterBy: $filter){
      pageInfo {
        Count
        CurrentPage
        NumPages
        hasNext
        hasPrevious
        hasOtherPages
        nextPageNumber
        previousPageNumber
        startIndex
        endIndex
      }
      edges {
        node {
        subject {
          ... on PanelType {
            id
            text
            hidden
            createdAt
            zipCode
            likesCount
            liked
            followsCount
            followed
            group {
              name
              id
            }
            author {
              id
              username
              fullName
              institution
              position
              profilePicSm
            }
            categories {
              edges {
                node{
                  id
                  category
                }
              }
            }
            comments {
              id
              createdAt
              text
              liked
              likesCount
              thanked
              thanksCount
              attachments{
                attachment
                previewSm
                previewMd
              }
              author {
                id
                username
                fullName
                institution
                position
                profilePicSm
              }
            }
            }
            ... on AnnouncementType {
              ...AnnouncementsInfo
            }
            ... on EventType {
              ...EventInfo
            }
            ... on ResourceType {
              ...ResourceInfo
            }
            ... on ResourceSubmissionType {
              ...ResourceSubmissionInfo
            }
            ... on JobType {
              ...JobInfo
            }
          }
        }
      }
    }
  }
  ${AnnFragment}
  ${EventFragment}
  ${ResourceFragment}
  ${ResourceSubmissionFragment}
  ${JobFragment}
`;

export const FilterDrop = props => {

  const { classes, open } = props;
  const { handleClick, handleClickAway } = props;
  const { setFilterBy } = props;

  const handleItemClick = name => event => {
    setFilterBy(name)
    handleClickAway(event)
  }

  return (
    <div className={classes.menu}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <Button nariant={'contained'} className={classes.flat} onClick={handleClick}>
             <Icon>dehaze</Icon> <strong style={{fontSize: '1.2rem'}}>FILTER</strong>
          </Button>
          {open ? (
            <Paper className={classes.dropdown}>
              <div className={classes.menuItem} onClick={handleItemClick('')}>
                show all
              </div >
              <div className={classes.menuItem} onClick={handleItemClick('only_panels')}>
                Show urgent requests only
              </div >
              <div className={classes.menuItem} onClick={handleItemClick('only_anns')}>
                Show conversations only
              </div >
            </Paper>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
}

const styles = theme => ({
  menu: {
    position: 'relative',
  },
  flat: {
    margin: theme.spacing.unit,
    textTransform: 'none',
    backgroundColor: 'white',
  },
  dropdown: {
    zIndex: 11111,
    position: 'absolute',
    top: 36,
    left: -30,
    width: 220,
  },
  menuItem: {
    height: '100%',
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
  },
  spaceThem: {
    overflow: 'visible',
    marginBottom: theme.spacing.unit * 4,
  },
  hidesm:{
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paper: {
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 2,
  },
});

const SimpleCard = function (props) {
  const [Open, setOpen] = React.useState(false)
  const [Page, setPage] = React.useState(1)
  const [FilterBy, setFilterBy] = React.useState(props.data)

  const handleClickAway = () => {
    setOpen(false)
  };

  const handleDDClick = () => {
    setOpen(!Open)
  };

  const { classes } = props;

  return (
    <Query
      query={TimelineQuery}
      variables={{ page: Page, filter: FilterBy}}
      errorPolicy={'all'}
      fetchPolicy={'network-only'} // skip the cache
    >
      {({ loading, error, data, refetch }) => {
        if (loading || !data) return <Linear />;
        if (data == null) return <Linear />;
        if (error) {
          console.log( error.message );
          return (
            <Grid item md={7} xs={12}>
              <Grid item md={12}>
                <ManyButton />
                <p>{'Error'}</p>
              </Grid>
            </Grid>
          );
        }
        return (
          <React.Fragment>
            <TabedPages home={1}/>
            <ManyButton />
            <Grid item>
            <FilterDrop
              classes={classes}
              open={Open}
              handleClickAway={handleClickAway}
              handleClick={handleDDClick}
              setFilterBy={setFilterBy}
              />
            </Grid>
            <br/>
            {data.currentTimeline.edges.map(edge => {
              switch (edge.node.subject.__typename) {
                case 'PanelType': return <ActivityCard key={edge.node.subject.id} className={classes.spaceThem} activity={edge.node}/>
                case 'AnnouncementType': return <DetachedDetailAnn key={edge.node.subject.id} className={classes.spaceThem} jschild data={{announcement: edge.node.subject}} />
                case 'EventType': return <DetachedDetailEvents key={edge.node.subject.id} className={classes.spaceThem} data={{event: edge.node.subject}}/>
                case 'JobType': return <DetachedDetailJobs key={edge.node.subject.id} className={classes.spaceThem} data={{job: edge.node.subject}}/>
                case 'ResourceType': return <DetachedResource key={edge.node.subject.id} className={classes.spaceThem} data={{resource: edge.node.subject}}/>
                case 'ResourceSubmissionType': return <DetachedSubmittedResource key={edge.node.subject.id} className={classes.spaceThem} data={{resourceSubmission: edge.node.subject}}/>
                default:
                  return (
                    <React.Fragment>
                    </React.Fragment>)
              }
            })
            }
            <Pager
              current={Page}
              totalPages={data.currentTimeline.pageInfo.NumPages}
              pageChanged={setPage} />
          </React.Fragment>
        );
      }}
    </Query>
  );
};

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const TimeLine = SimpleCard;
export default withStyles(styles)(TimeLine);
