import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import UserDrawer from './Profile';
import LatestUsers from './LatestUsers';
import WeeksPowerUsers from './PowerUsersWeek';


const styles = theme => ({
  root: {
    height: '100vh',
    top: 'Calc(4rem)',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.default,
    top: 'Calc(10rem)',
    height: '100vh',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  end: {
    backgroundColor: theme.palette.background.default,
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  }
});

class MiniDrawer extends React.Component {
  state = {
    open: true,
  };

  static getDerivedStateFromProps(props, state){
    const {width} = props;
    if (width !== state.width){
      if( width === 'sm' || width === 'xs'){
       return {open: false, width: width}
      } else {
       return {open: true, width: width, ready: true}
      }
    } else {
      return {ready: false}
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, theme } = this.props;
    const { open } = this.state;

    if (!open){
      return null;
    }

    return (
      <div className={`${classes.root} `}>
        <div
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && `${classes.drawerPaperClose} ${classes.fleft}`),
          }}
          open={this.state.open}
        >
          <div className={classes.end} onClick={this.handleDrawerToggle}>
            {theme.direction === 'rtl' ? null : null}
          </div>
          { this.state.open &&
            (
              <React.Fragment>
                <UserDrawer />
                <LatestUsers />
              </React.Fragment>
            )
          }
        </div>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles, { withTheme: true })(MiniDrawer));
