import React from 'react';
import Linkify from 'react-linkify';

export const newTab = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);

export const TabLinkify  = ({children, ...rest}) => (
  <Linkify componentDecorator={componentDecorator} {...rest}>
    {children}
  </Linkify>
);
