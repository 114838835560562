import React from 'react';

import gql from 'graphql-tag';
import { GenCommentsFragment } from '../GenComments/Fragments';

export const AnnFragment = gql`
  fragment AnnouncementsInfo on AnnouncementType {
    author {
      id
      username
      fullName
      institution
      position
      profilePicSm
    }
    categories {
      edges {
        node {
          id
          category
        }
      }
    }
    attachments{
      attachment
      previewSm
      previewMd
    }
    group {
      name
      id
    }
    genComments{
      ...genComments
    }
    createdAt
    modifiedAt
    hidden
    zipCode
    description
    id
    likesCount
    liked
    followsCount
    followed
  }
  ${GenCommentsFragment}
`;

