import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { graphql } from 'react-apollo';
import { Mutation } from "react-apollo";
import gql from 'graphql-tag';

import ToolTipStyle from '../Generic/ToolTipStyle';

const AddPrintQueueMut = gql`
  mutation AddPrintQueue($resId:String!, $typename: String!){
    addPrintQueue (resId: $resId, typename: $typename) {
      status
    }
  }
`;

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  tooltip: ToolTipStyle(theme),
});

class AddPrintQueue extends React.Component {
  render() {
    const {classes, resource, typename} = this.props;
    return (
      <Mutation mutation={AddPrintQueueMut}>
        {(addQueue, { data }) => (
          <Tooltip classes={classes} title="Add to print cart" placement="bottom">
          <IconButton
            color={resource.inprintqueue? "primary" : "secondary" }
            className={classes.button}
            aria-label="Add an alarm"
            onClick={ e => {
              if (resource.inprintqueue) {
                return
              }
              addQueue({
                variables: { resId: resource.id, typename },
                refetchQueries: [
                  'PagedResourceSearch',
                  'DetailResourceQuery',
                  'searchBookmarksPaged',
                  'AllPrintQueue',
                  'PrintQueue'
                ],
                });
            }}
          >
            <Icon>
              print
            </Icon>
          </IconButton>
          </Tooltip>
        )}
      </Mutation>
    );
  }
};

export default withStyles(styles)(AddPrintQueue);
