import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';

import { graphql } from "react-apollo";
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import ToolTipStyle from 'src/components/Generic/ToolTipStyle';
import {stringToColor} from 'src/components/Generic/RandomColor';

const LatestUserQuery = gql`
  query latestUsers {
    latestUsers(many: 5){
      username
      firstName
      institution
      profilePicSm
    }
    totalUsers
  }
`;

const styles = theme => ({
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
  avatar: {
    height: '1.5rem',
    width: '1.5rem',
    margin: theme.spacing.unit,
    ...theme.typography.body1,
    color: 'white',
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  ellipsisContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
  },
  listpad: {
    padding: theme.spacing.unit,
  },
  button: {
    color: 'white',
    fontSize: 6,
  },
  tooltip: ToolTipStyle(theme),
});

class LatestUsers extends React.Component {
  state = {
  };

  render() {
    const {data, classes, theme} = this.props;
    if (data.error){
      return ( <p> error </p>);
    }

    if (data.loading) {
      return <Linear />;
    }

    return (
      <div className={classes.container}>
        <Link to={`/discoverusers`} style={{ textDecoration: 'none' }}>
          <Typography variant='subheading' >
            <strong>New Peers</strong>
          </Typography>
        </Link>
        <Typography variant='body1' >
          (Total Peers: {data.totalUsers})
        </Typography>
        {data.latestUsers.map(r =>
          <React.Fragment>
          <Link to={`/profile/${r.username}`} style={{ textDecoration: 'none' }}>
            <List className={classes.ellipsisContainer}>
              <Avatar
                style={{ backgroundColor: stringToColor(r.username) }}
                className={classes.avatar}
                src={`${r.profilePicSm ? r.profilePicSm : ''}`}
                children={r.firstName.charAt(0)}
                imgProps={{
                  style: {
                    minWidth: '100%',
                    minHeight: '100%',
                  }
                }}
                component={(magic) =>
                  {
                    const {children, ...rest} = magic;
                    return (
                      <Typography noWrap {...rest}>
                        {children}
                      </Typography>
                    );
                  }
                }
              />
              <Typography className={classes.ellipsis} variant='body1' align="left" noWrap>
                { r.firstName }, { r.institution }
              </Typography>
            </List>
          </Link>
          <Divider />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(graphql(LatestUserQuery)(LatestUsers));
