import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import { AsyncCreatable, Async } from 'react-select';
import Select from 'react-select';
//import 'react-select/dist/react-select.css';

import debounce from 'debounce';
import { AutoAffix } from 'react-overlays';
//import queryString from 'query-string';
//import { qs } from 'qs';

import { Redirect } from 'react-router-dom';

import { graphql, Query } from 'react-apollo';
import gql from 'graphql-tag';

import { client } from '../../utils/makeApolloClient';
import { DetachedResource } from './DetailResource';
import Pager from '../Generic/paged';
import Map from 'src/components/Map/Map';
import Linear from 'src/components/Loading/Linear.js';
import CategoryPill from './CategoryPill';
import BackButton from '../Generic/BackButton';
import { GenCommentsFragment } from '../GenComments/Fragments';

var qs = require('qs');


const PageResourceSearchQuery = gql`
  query PagedResourceSearch($cat: [String!], $zips: [String], $page:Int) {
    searchResourcesPaged(categories: $cat, zipCodes: $zips, page: $page, perpage: 50) {
      resources {
        categories{
          edges{
            node{
              category
              id
            }
          }
        }
        privateNotes{
          id
          modifiedAt
          createdAt
          note
        }
        groups{
          id
          name
          slug
        }
        genComments{
          ...genComments
        }
        id
        internalId
        name
        description
        emailContact
        phone
        website
        streetAddress
        city
        state
        latitude
        longitude
        zipCode
        modifiedAt
        createdAt
        bookmarked
        inprintqueue
      }
      numResults
      numPages
      keywords
    }
  }
${GenCommentsFragment}
`;


const styles = theme => ({
  map: {
    padding: theme.spacing.unit,
    top: '5rem',
    position: 'sticky !important',
    display: 'block',
  },
  plump: {
    padding: theme.spacing.unit,
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
});

const getQs = (query, key) => {
  if (query[key] && typeof query[key] === 'string') {
    return [{ value: query[key], label: query[key] }]
  } else if (query[key] && typeof query[key] === 'object') {
    return query[key].map(v => ({ value: v, label: v }))
  }
  return []
}

function Search (props) {
  const query = qs.parse(window.location.hash.replace('#', ''));
  const [Page, setPage] = React.useState(1);
  const [ShowMap, setShowMap] = React.useState(1);
  const [LocationValue, setLocationValue] = React.useState(getQs(query, 'location'));
  const [CategoryValue, setCategoryValue] = React.useState(getQs(query, 'resource'));

  useEffect(() => { search() }, [Page]);


  const search = () => {
    const resource_qlst = CategoryValue.map(o => o.label);
    const location_qlst = LocationValue.map(o => o.label);
    const qst = qs.stringify({
      location: location_qlst,
      resource: resource_qlst,
      page: Page,
    });
    props.history.push(`/search/resource#${qst}`);

    // record search event
    //ReactGA.event({
      //category: 'Search',
      //action: 'Search Executed',
      //label: `Resources: ${resource_qlst.join(', ')}; Locations: ${location_qlst.join(', ')}`
    //});

    //window.location.reload()
  }


  const {classes} = props;
  let cat = CategoryValue ? Array.from(CategoryValue, (i) => i.value): false;
  let loc = LocationValue ? Array.from(LocationValue, (i) => i.value): false;

  let spacing;
  let Vizmap;

  if (ShowMap){
    Vizmap = data => (
      <Grid item md={3} xm={12} alignItems={"stretch"} zeroMinWidth >
        <span className={classes.map}>
          { data.searchResourcesPaged.resources ? (
            <Map data={data.searchResourcesPaged.resources} />
            ): null
          }
        </span>
      </Grid>
    );
  } else {
    Vizmap = data => (
      <Grid item md={3} xs={0} alignItems={"stretch"} zeroMinWidth >
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Query
        query={PageResourceSearchQuery}
        variables={{ cat: cat, zips: loc, page: Page}}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Linear />;
          if (error) return `Error!: ${error}`;
          return (
            <React.Fragment>
            <Grid item md={6} xs={12}>
                <span style={{float: 'left'}}>
                  <BackButton/>
                  <br/>
                </span>
                <br/>
                <Typography color="primary" variant='body1' align="left">
                  People also searched:
                </Typography>
                <div>
                  {data.searchResourcesPaged.keywords.map((e, i) => {
                    if (i < 3){
                      return (
                          <CategoryPill key={i} category={e} />
                      );
                    }
                    else{
                      return null;
                    }
                  })}
                </div>
                <Typography color="primary" variant='body1' align="left">
                  Total results: {data.searchResourcesPaged.numResults}
                </Typography>
                <Typography onClick={ e => setShowMap(!ShowMap)} variant='body1' gutterBottom>
                  <strong>
                  <input type="checkbox" checked={ShowMap}/>
                  {' '}Toggle map
                  </strong>
                </Typography>
            {data.searchResourcesPaged.resources.map(e => {
              return (
                <div key={e.id}>
                  <DetachedResource
                    key={ e.id }
                    data={{ resource: e }}
                    cbRefetch={ ()=> refetch() }
                  />
                </div>
              );
            })}
            <Pager
              current={Page}
              totalPages={data.searchResourcesPaged.numPages}
              pageChanged={setPage} />
            </Grid>
            {Vizmap(data)}
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
}
export {PageResourceSearchQuery};
export default withStyles(styles)(Search);
