import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  BrowserRouter as Router,
  Route,
  Switch } from 'react-router-dom';
import logo from '../Welnity_logo_Reverse.png';
import {WrapperBasic} from '../pages/Layouts/parent';

import { PrivateRoute } from '../screens/Auth/utils';
import TimeLine from '../pages/timeline/timeline';
import Logout from '../pages/Auth/logout';
const DetailPanel = React.lazy(() => import('../pages/details/panel'));
const DetailResource = React.lazy(() => import('../pages/details/resource'));
const DetailService = React.lazy(() => import('../pages/details/service'));
const SubmitResource = React.lazy(() => import('../components/Resource/SubmitResource'));
const DetailSubmitedResource = React.lazy(() => import('../pages/details/SubmittedResource.js'));
const ResourceCorrection = React.lazy(() => import('../components/Resource/CorrectResource'));
const ServiceCorrection = React.lazy(() => import('src/components/Openreferral/Resource/ReportServiceIssue'));
const AllShares = React.lazy(() => import('../components/Shares/AllShares'));
const VerticalRegistrationStepper = React.lazy(() => import('../components/Registration/StepperRegistration'));
const UserSearch = React.lazy(() => import('../pages/all/newUsers.js'));
const Invitation = React.lazy(() => import('../pages/invitations/invitations'));
const Profile = React.lazy(() => import('../pages/profile/profile.js'));
const ProfileRedirect = React.lazy(() => import('../components/Profile/ProfileRedirect'));
const PreferenceTabs = React.lazy(() => import('../pages/prefrences/account'));
const NotificationsTable = React.lazy(() => import('../pages/notifications/notifications'));
const PanelSearch = React.lazy(() => import('../pages/search/PanelSearch'))
const EsUserSearch = React.lazy(() => import('../components/UserSearch/EsUsersSearch'))
const ResourceSearch = React.lazy(() => import('../pages/search/resourceSearch'))
const ServiceSearch = React.lazy(() => import('../pages/search/serviceSearch'))
const AnnSearch = React.lazy(() => import('../pages/search/announcementsSearch'))
const EventsSearch = React.lazy(() => import('../pages/search/eventsSearch'))
const OppSearch = React.lazy(() => import('../pages/search/jobsSearch'))
const Search = React.lazy(() => import('../pages/search/amalgamate'))
const ListBookmarks = React.lazy(() => import('../pages/bookmarks/bookmarks'))
const Print = React.lazy(() => import('../pages/print/print'))
const ContactUs = React.lazy(() => import('../components/Feedback/ContactUs'))
const Welcome = React.lazy(() => import('../components/Welcome/home'))
//const AboutUs = React.lazy(() => import('../components/AboutUs/AboutUs'))
const AboutUs = React.lazy(() => import('../pages/AboutUs/AboutUs.js'));
const FAQ = React.lazy(() => import('../components/FAQ/FAQ'))
const EmailPassToken = React.lazy(() => import('../components/PasswordReset/EmailToken'))
const PassConfirm = React.lazy(() => import('../components/PasswordReset/ConfirmPassword'))
const EmailConfirm = React.lazy(() => import('../components/EmailConfirm/PrimaryEConfirm'))
const Announcements = React.lazy(() => import('../pages/details/announcements'));
const Event = React.lazy(() => import('../pages/details/event'));
const Job = React.lazy(() => import('../pages/details/jobs'));
const PageAnn = React.lazy(() => import('../pages/all/announcements'));
const PageEvents = React.lazy(() => import('../pages/all/events'));
const PageJobs = React.lazy(() => import('../pages/all/jobs'));
const PageGroups = React.lazy(() => import('../pages/groups/all'));
const Group = React.lazy(() => import('../pages/groups/group'));
const AuthoredPanelsPaged = React.lazy(() => import('../pages/authored/queries'));
const ForeignRPanelsPaged = React.lazy(() => import('../pages/replied/queries'));
const FollowedPanelsPaged = React.lazy(() => import('../pages/followed/queries'));
const MyGroups = React.lazy(() => import('../pages/groups/myGroups.js'));
const InvitesPaged = React.lazy(() => import('../pages/groups/InvitesPaged.js'));
const SignUpPaged = React.lazy(() => import('../pages/Reg/SignUp.js'));
const ActivatePaged = React.lazy(() => import('../pages/Reg/Activate.js'));



export default function(props){
  window.scrollTo(0, 0);
  return (
    <Switch>
      <PrivateRoute exact path="/" component={TimeLine} />
      <Route path="/login" component={Welcome} />
      <PrivateRoute path="/logout" component={Logout} />
      <Route path="/Activate/:code" component={ActivatePaged} />
      <Route path="/register/:code" component={VerticalRegistrationStepper} />
      <Route path="/register/" component={VerticalRegistrationStepper} />
      <Route path="/SignUp" component={SignUpPaged} />
      <Route path="/ContactUs/" render={WrapperBasic(ContactUs)} />
      <Route path="/AboutUs/" component={AboutUs} />
      <Route path="/FAQ/" render={WrapperBasic(()=>(<FAQ/> ))} />
      <Route path="/password/reset/email" component={EmailPassToken} />
      <Route path="/password/reset/:id?/:token?" component={PassConfirm} />
      <Route path="/pemail/confirm/:id?/:token?" component={EmailConfirm} />
      <PrivateRoute path="/resource/submission" component={SubmitResource} />
      <PrivateRoute path="/correction/resource/:id" component={ResourceCorrection} />
      <PrivateRoute path="/correction/service/:id" component={ServiceCorrection} />
      <PrivateRoute path="/search/users" component={EsUserSearch} />
      <PrivateRoute path="/search/queries" component={PanelSearch} />
      <PrivateRoute path="/search/resource" component={ResourceSearch} />
      <PrivateRoute path="/search/service" component={ServiceSearch} />
      <PrivateRoute path="/search/announcements" component={AnnSearch} />
      <PrivateRoute path="/search/events" component={EventsSearch} />
      <PrivateRoute path="/search/opportunities" component={OppSearch} />
      <PrivateRoute path="/search" component={Search} />
      <PrivateRoute path="/detail/announcement/:id" component={Announcements} />
      <PrivateRoute path="/detail/event/:id" component={Event} />
      <PrivateRoute path="/detail/opportunity/:id" component={Job} />
      <PrivateRoute path="/detail/service/:id" component={DetailService} />
      <PrivateRoute path="/detail/p/:id" component={DetailPanel} />
      <PrivateRoute path="/detail/r/:id" component={DetailResource} />
      <PrivateRoute path="/detail/submitted_resource/:id" component={DetailSubmitedResource} />
      <PrivateRoute path="/detail/profile/:id?" component={ProfileRedirect} />
      <PrivateRoute path="/detail/group/:gid" component={Group} />
      <PrivateRoute path="/discoverusers/:page?" component={UserSearch} />
      <PrivateRoute path="/profile/:id?" component={Profile} />
      <PrivateRoute path="/settings" component={PreferenceTabs} />
      <PrivateRoute path="/shares" component={AllShares} />
      <PrivateRoute path="/authored/queries" component={AuthoredPanelsPaged} />
      <PrivateRoute path="/replied/queries" component={ForeignRPanelsPaged} />
      <PrivateRoute path="/followed/queries" component={FollowedPanelsPaged} />
      <PrivateRoute path="/joined/groups" component={MyGroups} />
      <PrivateRoute path="/invited/groups" component={InvitesPaged} />
      <PrivateRoute path="/all/announcements/" component={PageAnn} />
      <PrivateRoute path="/all/events/" component={PageEvents} />
      <PrivateRoute path="/all/opportunities/" component={PageJobs} />
      <PrivateRoute path="/all/groups/" component={PageGroups} />
      <PrivateRoute path="/bookmarks" component={ListBookmarks} />
      <PrivateRoute path="/notifications" component={NotificationsTable} />
      <PrivateRoute path="/invitations" component={Invitation} />
      <PrivateRoute path="/PrintQueue" component={Print} />
    </Switch>
  );
}

