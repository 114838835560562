import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Circular from 'src/components/Loading/Circular.js';
import { client } from '../../utils/makeApolloClient';

const me = gql`
    query Me {
      me {
        id
      }
    }
`;

const Auth = gql`
  query Auth {
    me {
      id @client
      isSuperuser @client
    }
  }
`;

class OnlyForAuth extends React.Component {
  state = {
    superuser: false,
    auth: false,
  };

  static getDerivedStateFromProps(props, state) {
    let data;
    try{
      console.log('test')
      var state = {}
      data = client.readQuery({ query: Auth });
      if (data.me && data.me.id ){
       state.auth = true;
      }
      if (data.me && data.me.isSuperuser ){
       state.superuser = true;
      }
      return state;
    }
    catch (e){
      return
    }
    return null;
  }
  render() {
    const {auth} = this.state;
    const {children} = this.props;
    if (auth){
      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      );
    }
    return (null);
  }
}

const PrivateRouteNQ = ({ data: { loading, error, me }, component: Component, ...rest }) => {
  if (loading) {
    return <Circular />;
  }
  if (error) {
    return <p></p>;
  }
  return (
    <Route
      {...rest}
      render={props =>
        me ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = graphql(me)(PrivateRouteNQ);

export { PrivateRoute, OnlyForAuth };
