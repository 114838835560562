import React from 'react';

import gql from 'graphql-tag';
import { GenCommentsFragment } from '../GenComments/Fragments';

export const EventFragment = gql`
  fragment EventInfo on EventType {
    author {
      id
      username
      fullName
      institution
      position
      profilePicSm
    }
    categories {
      edges {
        node {
          id
          category
        }
      }
    }
    attachments{
      attachment
      previewSm
      previewMd
    }
    genComments{
      ...genComments
    }
    new
    createdAt
    modifiedAt
    hidden
    zipCode
    title
    description
    daysTill
    id
    startsAt
    endsAt
    likesCount
    liked
    googleCal
    yahooCal
    ical
  }
  ${GenCommentsFragment}
`;

