import React, { Component } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { Error }  from '../Generic/forms';
import {SelectWrapped} from '../Search/SearchRes';
import Circular from '../Loading/Circular';

const AddJobMut = gql`
  mutation AddJob($group: ID, $details:JobInput!){
    createJob(group: $group, details: $details){
      job{
        id
      }
      status
      errors
    }
  }
`;

class AddJobStub extends Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      hidden: false,
      anonymous: false,
      location: '',
      title: '',
      organization: '',
      description: '',
      applicationLink: '',
      attachment: null,
      errors: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.textarea = React.createRef();
  }

  handleCategory = name => value => {
    this.setState({
      [name]: value,
    });
  };

  handleChange = prop => job => {
    this.setState({ [prop]: job.target.value });
    if (prop == 'description'){
      const resize = () => {
          const textarea = this.textarea.current;
          if (!textarea || textarea === undefined) {
            return
          }
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight+'px';
      }
      resize()
    }
  };

  handleCheckboxChange = prop => event => {
    this.setState({[prop]: event.target.checked});
  };

  handlePost = () => {
    this.setState({loading: true})
    const { group, history } = this.props;
    this.props
      .mutate({
        variables: {
          'group': group,
          'details':{
            hidden: this.state.hidden,
            anonymous: this.state.anonymous,
            location: this.state.location,
            title: this.state.title,
            organization: this.state.organization,
            description: this.state.description,
            applicationLink: this.state.applicationLink,
            newCategories: this.state.categoryValue ? this.state.categoryValue.map(o => o.label): null,
            attachment: this.state.attachment,
          },
        },
        refetchQueries: ['AllJobs', 'newJob', 'jobsThisWeek'],
      })
      .then(({ data, loading }) => {
        if (loading) {
          return "Loading...";
        }
        if (data.error) {
          this.setState({loading: false})
          return null;
        }
        if (data.createJob.status === 200) {
          console.log(data)
          this.setState({text: '', loading: false});
          if (this.props.cb) {
            this.props.cb();
          }
          if (data.createJob.job) {
            history.push(`/detail/opportunity/${data.createJob.job.id}`);
          }
        }
        if (data.createJob.status === 500) {
          console.log('Something went wrong while adding Job');
          this.setState({errors: data.createJob.errors, loading:false})
        }
      }).then(() => {});
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ attachment: acceptedFiles[0]})
  }

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    if (loading){
      return (<Circular/>);
    }

    let postable;
    if (this.state.title.length === 0){
       postable = false;
    } else {
       postable = true;
    }

    return (
      <div>
        <Grid wrap="nowrap" spacing={8}
          syle={{overflow: 'visible'}}
          alignItems="stretch" >
        <Grid item>
        <form
          syle={{overflow: 'visible'}}
          className={classes.wrapper} noValidate autoComplete="off">
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.anonymous}
                onChange={this.handleCheckboxChange('anonymous')}
              />
            }
            label="Post anonymously"
          />
          <div>
            <Error errors={errors} name={'__all__'} />
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'new_categories'} />
              <div>
              <SelectWrapped
                fullWidth
                className={classes.input}
                value={this.state.categoryValue}
                onChange={this.handleCategory('categoryValue')}
                name="react-select-chip"
                name={'resource'}
                placeholder={"Category (e.g. mental health, nursing)"}
                multi={true}
                instanceId={'react-select-chip'}
                id={'react-select-chip'}
                simpleValue={true}
              />
              </div>
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'location'} />
              <Input
                id="zips"
                type={'text'}
                fullwidth={true}
                className={classes.formControl}
                value={this.state.location}
                onChange={this.handleChange('location')}
                inputProps={{
                  placeholder: "Location (e.g. Street name, city, zip code)",
                  className:classes.input,
                }}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'title'} />
              <Input
                id="title"
                type={'text'}
                fullwidth={true}
                inputProps={{
                  placeholder: "Job title (e.g. Social Worker, Counselor)",
                  }}
                className={classes.formControl}
                value={this.state.title}
                onChange={this.handleChange('title')}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'organization'} />
              <Input
                id="title"
                type={'text'}
                fullwidth={true}
                inputProps={{
                  placeholder: "Organization (e.g. U.T. Health)",
                  }}
                className={classes.formControl}
                value={this.state.organization}
                onChange={this.handleChange('organization')}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Error errors={errors} name={'description'} />
              <Dropzone style={{width: "inherit"}} onDrop={this.onDrop.bind(this)} onClick={e => e.preventDefault()}>
              {({getRootProps, getInputProps, isDragActive}) =>
                <div
                  className={`${isDragActive ? classes.dragActive : ''}`}
                  {...getRootProps()}
                  >
                <Input
                  id="Job"
                  type={'text'}
                  fullwidth={true}
                  multiline={true}
                  inputRef={this.textarea}
                  inputProps={{
                    placeholder: "Job description",
                    }}
                  rows={4}
                  className={classes.formControl}
                  value={this.state.description}
                  onChange={this.handleChange('description')}
                />
                </div>
              }
              </Dropzone>
              <FormControl className={classes.formControl}>
                <Error errors={errors} name={'application_link'} />
                <Input
                  id="title"
                  type={'text'}
                  fullwidth={true}
                  inputProps={{
                    placeholder: "Website (http://)",
                    }}
                  className={classes.formControl}
                  value={this.state.applicationLink}
                  onChange={this.handleChange('applicationLink')}
                />
              </FormControl>
              <label htmlFor="files-add-job" class="btn">
                <Button variant="contained" component="span"
                  classes={{
                    label: classes.label, // class name, e.g. `classes-nesting-label-x`
                  }}
                  className={classes.button}>
                  <Icon fontSize={'small'}>
                    attach_file
                  </Icon>
                  Attach a flyer
                </Button>
                {this.state.attachment &&
                  (
                    <Typography variant='body1' gutterBottom className={classes.title}>
                    {this.state.attachment.name}
                    </Typography>
                  )}
              </label>
              <input
                id='files-add-job'
                type="file"
                name="image"
                accept="image/*,.pdf,.doc,.docs,application/msword"
                style={{display: 'None',}}
                onChange={({ target: { validity, files: [file] } }) =>
                   validity.valid &&
                   this.setState({
                   attachment: file,
                })
                }
              />
            </FormControl>
            { postable && (
              <Button
                size="small"
                variant="outlined"
                raised
                color="primary"
                className={classes.button}
                onClick={this.handlePost}
              >
                Post
              </Button>
            )}
          </div>
        </form>
        </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  dragActive:{
    borderColor: theme.palette.primary.A500,
    borderRadius: theme.spacing.unit,
    border: 'solid',
  },
  label: {
    textTransform: 'none',
  },
  input: {
    width:'100%',
    height:'100%',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  wrapper: {
    display: 'block',
  },
  formControl: {
    margin: theme.spacing.unit,
    display: 'flex',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    //zIndex: '2000 !important',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  }
});

const AddJob = withStyles(styles)(graphql(AddJobMut)(AddJobStub));

AddJob.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(AddJob);
