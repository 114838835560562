import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    [theme.breakpoints.down('sm')]: {
      //minWidth: 700,
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

function AcceptedTable({ classes, data }) {
  return (
    <Paper className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <CustomTableCell >Invitee</CustomTableCell>
            <CustomTableCell numeric>Invite Sent On</CustomTableCell>
            <CustomTableCell numeric>Accepted On</CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(n => (
            <TableRow className={classes.row} key={n.id}>
              <CustomTableCell component="th" scope="row">
                {n.invitee}
              </CustomTableCell>
              <CustomTableCell numeric>{moment(n.createdAt).fromNow()}</CustomTableCell>
              <CustomTableCell numeric>{moment(n.acceptedAt).fromNow()}</CustomTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

AcceptedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(AcceptedTable);
