import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ClearIcon from '@material-ui/icons/Clear';
import Chip from '@material-ui/core/Chip';

import AsyncSelect from 'react-select/async';
import Select from 'react-select';
//import 'react-select/dist/react-select.css';

import debounce from 'debounce';
//import queryString from 'query-string';

import { Redirect } from 'react-router-dom';

import { graphql, Query } from 'react-apollo';
import gql from 'graphql-tag';

import { client } from '../../utils/makeApolloClient';
import SelectStyle from '../Generic/SelectStyle'
import {isIterable} from '../Generic/SelectStyle.js';

var qs = require('qs');

const LocationSugQuery = gql`
  query ($param: String!){
    esLocationSuggest(param: $param) {
      json
    }
  }
`;


const ITEM_HEIGHT = 48;
const styles = theme => ({
  root: {
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
  },
});

const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: 'pointer',
    color: state.isFocused ? 'blue' : 'black',
});

const CustomClearText = () => 'X';
const ClearIndicator = props => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

export const SelectWrapped =  function (props) {
  const { classes, value, name, onChange, inputRef, ...other } = props;

  const onInputChange = function (value, actionMeta) {
    console.log(value.toLowerCase())
    console.log(actionMeta)
    return value;
  }

  const getOptions = async function (input, kw, cb) {
    const queryT = LocationSugQuery;
    if (input === ''){
      var promise1 = new Promise(function(resolve, reject) {
          setTimeout(resolve, 100, null);
      });
      return promise1;
    }
    const options = await client.query({
      query: queryT,
      variables: { param: input },
    }).then( result => {
      let options;
        options = result.data.esLocationSuggest.json.suggestions.map(o => ({
          value: o,
          label: o,
        }));
      /*
      options.sort(function(a, b) {
            return b.label.localeCompare(input) - a.label.localeCompare(input);
      })
        */
      return options
    });
    console.log(options)
    return options;
  }

  return (
      <AsyncSelect
        ref={inputRef}
        components={{ ClearIndicator }}
        name="location-sug"
        isClearable
        isSearchable
        value={value}
        noOptionsMessage={() => (<Typography>{'No results found'}</Typography>)}
        onChange={ onChange }
        autoload={false}
        onInputChange={onInputChange}
        loadOptions={async (input) => getOptions(input, name)}
        inputProps={{
         className: classes.root
        }}
        {...other}
      />
  );
}
