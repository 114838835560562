import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object';
//import 'core-js/es7/object';
//import 'core-js/fn/string/ends-with';
//import 'core-js/fn/string/starts-with';
import 'core-js/es/array';
import 'core-js/es/string';
import 'core-js/es/promise';
//import 'core-js/fn/array/includes';
import 'core-js/es/number';
import 'unfetch/polyfill/index.js';
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  StaticRouter, // for server rendering
  Route,
  Link,
  Switch
} from 'react-router-dom';


import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {AppProviders} from './providers.js'
import Login from './screens/Auth/login.js';
import { PrivateRoute, OnlyForAuth } from './screens/Auth/utils.js';

import logo from './Welnity_logo_Reverse.png';
import './App.css';

import Linear from './components/Loading/Linear.js';
import CookieCheck from './components/CookieChecker/CookieChecker.js';

import SiteMessage from './components/SiteMessage/SiteMessage';
import TopAppBar from './partials/TopBar/topbar.js';

import CoreRoutes from './routes/core.js'

require('nodep-date-input-polyfill');

//function browserSupportsDateInput() {
  //var i = document.createElement('input');
  //i.setAttribute('type', 'date');
  //return i.type !== 'text';
//}
//if (!browserSupportsDateInput) {
//}



class App extends Component {
  componentDidUpdate(prevProps) {
    //if(this.props.location){
      //if (this.props.location !== prevProps.location) {
        //this.onRouteChanged();
      //}
    //}
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
  }

  render() {
    const {classes} = this.props;
    const hold = (props) => (
      <div>
        { props.match.params ?
          Object.entries(props.match.params).map(( n, k ) => <p> {k}: {n[0]}: {n[1]} </p> )
          : null
        }
      </div>);
    return (
        <AppProviders>
          <React.Fragment>
          <CssBaseline />
          <SiteMessage/>
          <div className="App">
            <Router basename="/r">
              <div>
                <TopAppBar logo={logo} />
                <main
                  style={{alignContent: 'center', aligh: 'center', justify: 'center'}}
                >
                  <React.Suspense fallback={(
                    <Grid container={ true } wrap="nowrap" alignItems="stretch" alignContent="center" direction="column">
                      <Grid item><CookieCheck /></Grid>
                      <Grid item><Linear /></Grid>
                    </Grid>
                  )}>
                    <CoreRoutes />
                  </React.Suspense>
                </main>
                <footer />
              </div>
            </Router>
          </div>
          </React.Fragment>
        </AppProviders>
    );
  }
}

const styles = theme => {
  console.log(theme)
  return ({
  hidesm:{
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

};

App.propTypes = {
};

export default withStyles(styles)(App);
