import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import gql from 'graphql-tag';

import ProfileChip from 'src/components/Profile/ProfileChip.js';
import EditATextField from '../Generic/EditTextField';
import LikeButton from '../LikeSomething/Like';
import WhoLiked from '../LikeSomething/WhoLModal';
import ThanksButton from '../ThankSomething/Thank.js';
import WhoThanked from '../ThankSomething/WhoTModal';
import AttachmentPreview from '../Generic/AttachmentPreview';
import {TabLinkify} from '../Generic/LinkifyComponent';
import {ReplyTo} from '../Generic/ReplyTo';
import {EditGenCommentMut} from './Mutations.js';
import {circuitBreaker} from '../Generic/StyleGenerics.js';
import {Clamp} from '../Generic/ReadMore.js';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  label: {
    textTransform: 'none',
  },
  chip: {
    margin: theme.spacing.unit,
  },
  newline:{
    whiteSpace: 'pre-wrap',
    display: 'inline-block',
    width: '100%',
  },
  segment: {
    display: 'flex',
    width: '100%',
    borderWidth: theme.spacing.unit / 6,
    margin: theme.spacing.unit / 2,
  },
  leftBlock:{
    display:'flex',
    justifyContent: 'space-between',
    width: '50%',
  },
  rightBlock: {
    display:'flex',
    justifyContent: 'flex-end',
    width: '50%',
    fallbacks: [{justifyContent: 'right'}]
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
  ... circuitBreaker(),
});

class CommentsProto extends React.Component {
  render () {
    const { key, classes, refetches, comment, replyTo } = this.props;
    let counted_likes;
    if (comment.likesCount === 0){
      counted_likes = null;
    } else {
      counted_likes = (
      <WhoLiked key={key} instance={comment.id}>
      <Icon
        color={"primary"}
        fontSize={'small'}>
          thumb_up
        </Icon>
        {comment.likesCount}
      </WhoLiked>
      );
    }
    let counted_thanks;
    if (comment.thanksCount === 0){
      counted_thanks = null;
    } else {
      counted_thanks = (
      <WhoThanked key={key} instance={comment.id}>
      <Icon
        color={"primary"}
        fontSize={'small'}>
          mood
        </Icon>
        {comment.thanksCount}
      </WhoThanked>
      );
    }
    return(
      <TabLinkify>
        <EditATextField
          objid={comment.id}
          text={comment.text}
          graphname={'editGenComment'}
          owner={comment.author.username}
          MasterMut={EditGenCommentMut}
          refetches={refetches}
        >
        <Paper className={classes.root} elevation={1}>
          <Typography component="p">
            <span style={{display: 'flex', flexWrap: 'wrap',}}>
            <ProfileChip
              user={ comment.author }
              />
            <Typography style={{width: '100%', flex:1}} align={'right'}>
              {moment(comment.createdAt).fromNow()}
            </Typography>
            </span>
          </Typography>
          <Typography className={`${classes.newline} ${classes.dontBreakOut}`} variant="body1" component="p" align="left">
            <Clamp>
              { comment.text }
            </Clamp>
          </Typography>
          <div className={classes.segment}>
            <AttachmentPreview attachments={comment.attachments} width='100%' />
          </div>
          <div className={classes.segment}>
            <span className={classes.leftBlock}>
              <ThanksButton
                refetches={refetches}
                instance={comment.id}
                thanked={comment.thanked}
                count={comment.thanksCount} />
              <LikeButton
                refetches={refetches}
                instance={comment.id}
                liked={comment.liked}
                count={comment.likesCount} />
              <ReplyTo onClick={replyTo(comment.author.username, comment.id)} />
            </span>
            <span className={classes.rightBlock}>
              { counted_thanks }
              { counted_likes }
            </span>
          </div>
        </Paper>
        </EditATextField>
      </TabLinkify>
    );
  }
}

const defaultBoxProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 2,
};

export function DisplayComments (props) {
  const [Hidden, setHidden] = React.useState(false)

  const { comments, classes, replyTo, refetches, commentProps } = props;
  if (comments == null) return null;
  if (comments.length < 4 || Hidden ){
    return (
      <>
      {comments && comments.map(item => {
      return (
        <Box className={classes.comment} { ...(commentProps ? commentProps: defaultBoxProps)}>
          <Comments refetches={refetches} comment={item} replyTo={replyTo}/>
        </Box>
      );
      })}
      </>
    )

  } else {
    let trimmed = comments.slice(-2);
    return (
      <>
      <Typography className={classes.blue} style={{width: '100%', color: 'blue'}} variant="body1" align='left' onClick={e => setHidden(!Hidden)}>
        <strong>View {comments.length -2 } more replies ...</strong>
      </Typography>
      {trimmed && trimmed.map(item => {
      return (
        <Box className={classes.comment} { ...(commentProps ? commentProps: defaultBoxProps)}>
          <Comments refetches={refetches} classes={classes} comment={item} replyTo={replyTo}/>
        </Box>
      );
      })}
      </>
    )
  }
}
const Comments = withStyles(styles)(CommentsProto)
export default Comments;
