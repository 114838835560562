import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { graphql } from 'react-apollo';
import { client } from 'src/utils/makeApolloClient';
import gql from 'graphql-tag';

const ResendInvitationMut = gql`
  mutation ResendInv($invid:ID!){
     resendInvitation (id: $invid){
      status
    }
  }
`;

const RevokeInvitationMut = gql`
  mutation RevokeInv($invid:ID!){
    removeInvitation (id: $invid){
      status
    }
  }
`;



const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    [theme.breakpoints.up('sm')]: {
      //minWidth: 600,
    },
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class PendingTable extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      disabled: false,
    };
  }

  handleRevoke = (id) => () => {
    console.log(id)
    client.mutate({
        mutation: RevokeInvitationMut,
        variables: {invid: id},
        refetchQueries: ['InvPretty'],
      })
      .then(({ data }) => {
        if ('error' in data) {
          return
        }
        if (data.removeInvitation.status === 200) {
          return
        }
      })
  }

  handleResend = (id) => () => {
    this.setState({disabled:true});
    console.log(id)
    client.mutate({
        mutation: ResendInvitationMut,
        variables: {invid: id},
        refetchQueries: ['InvPretty'],
      })
      .then(({ data }) => {
        if ('error' in data) {
          // test
        }
        if (data.resendInvitation.status === 200) {
          this.setState({disabled:false});
        }
      })
  }

  render() {
  const {classes, data} = this.props
  return (
      <Paper className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell >Invitee</CustomTableCell>
              <CustomTableCell numeric>Invite Sent On</CustomTableCell>
              <CustomTableCell>Remind to Join</CustomTableCell>
              <CustomTableCell>Revoke</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(n => {
              return (
                <TableRow className={classes.row} key={n.id}>
                  <CustomTableCell component="th" scope="row">
                    {n.invitee}
                  </CustomTableCell>
                  <CustomTableCell numeric>{moment(n.createdAt).fromNow()}</CustomTableCell>
                  <CustomTableCell>
                    <Button
                      variant="outlined"
                      raised
                      disabled={this.state.disabled}
                      color="primary"
                      onClick={this.handleResend(n.id)}>
                      Remind to join
                    </Button>
                  </CustomTableCell>
                  <CustomTableCell>
                    <Button
                      raised
                      color="primary"
                      onClick={this.handleRevoke(n.id)}>
                      Revoke
                    </Button>
                  </CustomTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

PendingTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PendingTable);
