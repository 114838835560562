import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import { emphasize, fade, darken } from '@material-ui/core/styles/colorManipulator'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

var qs = require('qs');

const styles = theme => ({
  button:{
    backgroundColor: theme.palette.primary[500],
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    color: 'white',
    fontSize: theme.typography.pxToRem(11),
    '&:hover': {
        backgroundColor: fade(theme.palette.primary[500], .5),
      },
  },
});

class CategoryPill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      short: true,
    };
  }

  search = (e) => {
    e.preventDefault();
    const qst = qs.stringify({
      resource: this.props.category,
      page: 1,
    });
    console.log(qst)
    // Quick and dirty hack
    this.props.history.push('/');
    this.props.history.push(`/search/#${qst}`);
    window.location.reload(true);

    // record search event
    //ReactGA.event({
      //category: 'Search',
      //action: 'Search Executed',
      //label: `Resources: ${resource_qlst.join(', ')}; Locations: ${location_qlst.join(', ')}`
    //});

    //window.location.reload()
  }

  render() {
    const { classes, category} = this.props;
    return (
        <Button
          size="small"
          variant="outlined"
          onClick={this.search}
          className={classes.button}>
          <strong>{category}</strong>
      </Button>
    );
  }
}

export default withRouter(withStyles(styles)(CategoryPill));
