import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { emphasize, fade, darken } from '@material-ui/core/styles/colorManipulator';

import AddPanel from './AddPanel.js';
import AddAnn from '../Announcements/MakeAnn';
import AddEvent from '../Events/MakeEvent';
import AddJob from '../Jobs/MakeJob';
import AddRes from '../Resource/SubmitResource';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary['500'],
    textTransform: 'none',
  },
  wrapped: {
    borderRadius: theme.shape.borderRadius * 10,
    borderColor: theme.palette.primary['500'],
  },
  selected: ({index}) => ({
    backgroundColor: 'white',
    color: index == 1 ? theme.palette.error['500'] : theme.palette.primary['500'],
    borderStyle: 'groove',
    borderWidth: theme.shape.borderRadius,
    borderColor: index == 1 ? theme.palette.error['500'] :theme.palette.primary['500'],
  }),
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'inline-block',
    width: '100%',
  },
  Bigbutton: {
    //color: theme.palette.primary['500'],
    color: 'white',
    display: 'inline-block',
    width: 'auto',
    backgroundColor: '#3299FF',
    textTransform: 'none',
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    borderRadius: theme.shape.borderRadius * 10,
    borderColor: '#3299FF',
    '&:hover': {
        backgroundColor: fade('#3299FF', .5),
        borderColor: '#3299FF',
        borderRadius: theme.shape.borderRadius * 10,
      },
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  redAlert: {
    color: theme.palette.error['500']
  },
  overflowShow: {
    overflow: 'visible',
  },
  indicator: ({index}) => ({
    backgroundColor: index == 1 ? theme.palette.error['500'] : theme.palette.primary['500'],
  }),
}));

function TabContainer({ children, hidden, dir }) {
  if (hidden) return null;
  return (
    <div style={{ padding: 8 * 3 }}>
      {children}
    </div>
  );
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const UtilityDialog = function (props) {
  const [Value, setValue] = React.useState(0);
  const classes = useStyles({'index': Value, ...props});
  const theme = withTheme((theme)=> theme);

  const handleClose = () => {
    setValue(0)
    props.onClose('test');
  }

  const handleListItemClick = value => {
    props.onClose(Value);
  }

  const handleChange = (event, value) => {
    setValue(value)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const { onClose, ...other } = props;

  return (
    <Dialog
      classes={{root: classes.overflowShow, paper: classes.overflowShow }}
      TransitionComponent={Transition}
      scroll={'body'}
      maxWidth={'md'}
      onClose={handleClose} aria-labelledby="Utility-dialog-title" {...other}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent
        className={classes.overflowShow}>
        <div>
          <Tabs
            classes={classes}
            value={Value}
            onChange={handleChange}
            indicatorColor="primary"
            fullWidth
          >
            <Tab
              classes={classes}
              icon={
                <Icon className={classes.icon} color="action">
                  forum
                </Icon>
              }
              label="resource/conversation" />
            <Tab
              classes={classes}
              icon={
                <Icon className={classes.redAlert} color="action">
                  report_problem
                </Icon>
              }
              label="Urgent Request" />
            <Tab
              classes={classes}
              icon={
                <Icon className={classes.icon} color="action">
                  event
                </Icon>
              }
              label="event" />
            {/*
            <Tab
              classes={classes}
              icon={
                <Icon className={classes.icon} color="action">
                  chrome_reader_mode
                </Icon>
              }
              label="resource" />
              */}
            <Tab
              classes={classes}
              icon={
                <Icon className={classes.icon} color="action">
                  work
                </Icon>
              }
              label="job opening" />
          </Tabs>
        </div>
        <TabContainer hidden={Value != 0} dir={theme.direction}>
          <AddAnn cb={handleClose} group={{ value: null, label: 'main page' }}/>
        </TabContainer>
        <TabContainer hidden={Value != 1} dir={theme.direction}>
          <AddPanel cb={handleClose}/>
        </TabContainer>
        <TabContainer hidden={Value != 2} dir={theme.direction}>
          <AddEvent cb={handleClose}/>
        </TabContainer>
        {/*<TabContainer hidden={Value != 3} dir={theme.direction}>
          <AddRes cb={handleClose}/>
        </TabContainer>*/}
        <TabContainer hidden={Value != 3} dir={theme.direction}>
          <AddJob cb={handleClose}/>
        </TabContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UtilityDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};


const ManyButton = function (props) {
  const [Open, setOpen] = React.useState(0);
  const classes = useStyles(props);

  const handleClickOpen = () => {
    setOpen(true)
  };

  const handleClose = value => {
    setOpen(false)
  };

  return (
    <div className={classes.container} align='center'>
      <Button
        variant="outlined"
        size="large"
        color="primary"
        className={classes.Bigbutton}
        onClick={handleClickOpen}>
        <Typography className={classes.flex} variant='h5' gutterBottom>
          <Icon
            className={classes.extendedIcon}
            fontSize={'large'}>
            control_point
          </Icon>
          <strong>
          {'Click here to share resources or create a post.'}
          </strong>
        </Typography>
      </Button>
      <UtilityDialog
        open={Open}
        onClose={handleClose}
      />
    </div>
  );
}

export default ManyButton;
