import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { graphql, Mutation } from "react-apollo";
import gql from 'graphql-tag';

import Linear from 'src/components/Loading/Linear.js';
import ToolTipStyle from 'src/components/Generic/ToolTipStyle';
import {MarkAllReadMut} from 'src/components/Notifications/MarkAllRead';

const MarkReadMut = gql`
  mutation ChangeNoti($noti:ID!, $read:Boolean!){
     notiMark (id: $noti, read:$read){
      status
    }
  }
`;

const UnreadNotiQuery = gql`
  query UnreadNoti{
     notifications {
       unreadNotifications {
         id
         data
         unread
         description
         verb
       }
    }
  }
`;

const styles = theme => ({
  leftright: {
    MinWidth: '325px',
    MaxWidth: '350px',
  },
  menuheader: {
    backgroundColor: 'white',
  },
  badge: {
    top: 'auto',
  },
  button: {
    fontSize: 6,
  },
  red: {
    backgroundColor: 'red',
    padding: theme.spacing.unit / 2,
  },
  tooltip: ToolTipStyle(theme),
});

const ITEM_HEIGHT = 48;

class LongMenu extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, data } = this.props;

    if (data.error){
      return ( <p> error </p>);
    }

    if (data.loading) {
      return <Linear />;
    }

    return (
    <Badge
      color='default'
      badgeContent={
        <div className={(data.notifications.unreadNotifications.length ? classes.red : 'hidden')}>
          <strong>{data.notifications.unreadNotifications.length}</strong>
        </div>
      }
      className={classes.badge}>
        <Tooltip classes={classes} title="My Notifications" placement="bottom">
          <Mutation mutation={MarkAllReadMut}>
            {(MarkAll, { data }) => {
              return (
              <IconButton
                className={classes.button}
                aria-label="More"
                aria-owns={anchorEl ? 'long-menu' : null}
                aria-haspopup="true"
                color="inherit"
                onClick={
                  e =>{
                    this.handleClick(e)
                    MarkAll({
                    });
                  }}
                >
                <NotificationsIcon />
              </IconButton>
              );
            }}
          </Mutation>
        </Tooltip>
        <Menu
          className={classes.leftright}
          id="long-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 6.5,
              width: 'auto',
            },
          }}
          >
          <ListSubheader className={classes.menuheader}>
            <Link to='/notifications'>
              <MenuItem>
                <ListItemText>
                  View all
                </ListItemText>
              </MenuItem>
            </Link>
          </ListSubheader>
          {data.notifications.unreadNotifications.map(item => (
            <Mutation mutation={MarkReadMut}>
              {(notiMark, { data }) => {
                return (
                <Link
                  to={`${item.data.link.slice(2)}`}
                  onClick={ e => {
                    notiMark({
                      variables: { noti: item.id, read: true },
                      refetchQueries: ['UnreadNoti', 'AllNoti'],
                    });
                  }
              }
                >
                <MenuItem key={item.id} selected={false}
              >
              {item.description}
              </MenuItem>
              </Link>
                );
              }}
            </Mutation>
            ))}
        </Menu>
    </Badge>
    );
  }
}

export {MarkReadMut, UnreadNotiQuery};
export default withStyles(styles)(graphql(UnreadNotiQuery, {
  options: ()=> {return {pollInterval:30000}}
})(LongMenu));
