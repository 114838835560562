import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  BackButton: {
    color: 'white',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));


const BackButton = function(props){
  const history = useHistory();
  const classes = useStyles();

  const handleClick = () => {
    history.goBack();
  };

  return (
    <Button className={classes.BackButton} variant={'contained'} onClick={handleClick} color="primary">
      Back
    </Button>
  );
}
export default BackButton;
