import React from 'react';

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { Mutation } from "react-apollo";
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { client } from '../../utils/makeApolloClient';
import { Error } from '../Generic/forms';
import { WhoAmIQuery } from '../Generic/EditTextField';

const EditPanelMut = gql`
  mutation EditPanel ($id: ID!, $text: String!, $location: String){
    editPanel(panel: $id, text: $text, location: $location) {
      status
      errors
    }
  }
`;

const styles = theme => ({
  spancontainer: {
    display: 'flex',
  },
  spanchildren: {
    flex: '0 90%',
    width: '90%',
  },
  fullchildren: {
    flex: '0 100%',
    width: '100%',
  },
  spanedit: {
    flex: '0 10%',
    width: '10%',
  },
  formControl: {
    margin: theme.spacing.unit,
    display: 'flex',
  },
  button: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

class EditPanel extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      text: props.panel.text,
      location: props.panel.zipCode,
      showEdit: false,
      ready: false,
      username: '',
      errors: {},
    };
    this.editState = this.editState.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.ready) {
      let data;
      var newstate = {}
      data = client.readQuery({ query: WhoAmIQuery });
      newstate.ready = true;
      if (data.me && data.me.username ){
       newstate.username = data.me.username;
      }
      return newstate
    }
  }

  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    //if (nextProps.text !== this.props.text) {
      //this.setState({
        //text: nextProps.text
      //});
    //}
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  editState = (prop, val) => {
    this.setState({[prop]: val})
  };

  render() {
    const { classes, children } = this.props;
    const { refetches, panel, owner } = this.props;
    const { showEdit, username, errors, location } = this.state;
    const text = this.state.text === null ? this.props.null : this.state.text

    if (showEdit){
      return (
        <Mutation mutation={EditPanelMut}>
          {(Mark, { data }) => (
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
              >
                <FormControl className={classes.formControl}>
                  <Error errors={errors} name={'__all__'} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="edit-note">Edit:</InputLabel>
                  <Input
                    multiline
                    id="edit-note"
                    type={'text'}
                    value={text}
                    onChange={this.handleChange('text')}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="edit-note">Edit Location:</InputLabel>
                  <Input
                    multiline
                    id="edit-note"
                    type={'text'}
                    value={location}
                    onChange={this.handleChange('location')}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                <span>
                <Button raised
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={ e => {
                    Mark({
                      variables: {id: panel.id, text: text, location: location},
                      refetchQueries: [...refetches],
                    })
                    .then(result =>{
                      if (result.data.editPanel.status === 200 ){
                        this.setState({showEdit: false})
                      }
                      if (result.data.editPanel.status === 500 ){
                        this.setState({errors: result.data.editPanel.errors})
                      }
                    });
                  }}
                >
                  Submit change
                </Button>
                <Button raised
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={ e => {
                    this.setState({showEdit: false})
                  }}
                >
                  Cancel
                </Button>
                </span>
                </FormControl>
              </form>
          )}
        </Mutation>
      );
    }

    let childclass;
    if (owner === username){
      childclass = classes.spanchildren
    } else {
      childclass = classes.fullchildren
    }

    return (
      <React.Fragment>
        <div>
        <div className={classes.spancontainer}>
          <div className={childclass}>
          { children }
          </div>
        { owner === username &&
          (
          <div className={classes.spanedit}>
            <Button raised
              mini
              size="small"
              color="primary"
              variant="outlined"
              className={classes.button}
              component="p"
              onClick={ e => {
                this.setState({showEdit: true})
                }}
              >
                Edit
              </Button>
          </div>
          )
        }
        </div>
        </div>
      </React.Fragment>
    );
  };
}

export default withStyles(styles)(EditPanel);
