import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

class Error extends Component {
  render() {
    const {errors, name} = this.props;
    return (
      <span>
      { errors && errors[name] ?
        (
          <Typography variant='body1' align='left' gutterBottom color={'error'}>
            {
              errors[name].map(e => e)
            }
          </Typography>
        ):
        ( null )
      }
      </span>
    );
  }
}

export {Error};
