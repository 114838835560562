import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import ToolTipStyle from '../Generic/ToolTipStyle';


const styles = theme => ({
  button: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 4,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit / 4,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  tooltip: ToolTipStyle(theme),
});


class AttachmentButton extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    const { classes, path } = this.props;
    if (path){
      return (
        <React.Fragment>
          <Tooltip classes={classes} title="View attachment" placement="bottom">
          <a href={path} target="_blank" download>
          <IconButton
            className={classes.button}
            aria-label="Download an Attachment"
          >
            <Icon fontSize={'small'}>
              attach_file
            </Icon>
          </IconButton>
          <Typography variant='caption' gutterBottom aligh='right'>
            Attachment
          </Typography>
          </a>
          </Tooltip>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(AttachmentButton);
