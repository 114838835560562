import React from 'react';

import gql from 'graphql-tag';


export const FileToGroupMut = gql`
  mutation AddFileToGroup ($gid:ID!, $attachment:Upload!, $desc: String!){
    uploadIntoGroup (group: $gid, attachment: $attachment, description: $desc) {
      status
      errors
    }
  }
`;


export const FileToGroupFolderMut = gql`
  mutation AddFileToGroup ($gid:ID!, $gfid: ID!, $attachment:Upload!, $desc: String!){
    uploadIntoGroupFolder (group: $gid, groupFolder: $gfid, attachment: $attachment, description: $desc) {
      status
      errors
    }
  }
`;


export const MakeGroupFolderMut = gql`
  mutation MakeGroupFolder ($gid:ID!, $parent: ID!, $name: String!){
    makeGroupFolder (group: $gid, parent: $parent, name: $name) {
      status
      errors
    }
  }
`;


export const CreateGroupMutation = gql`
mutation ($data : InputForGroup) {
  createGroup (data: $data) {
    status
    errors
  }
}
`

export const InviteGroupMutation = gql`
  mutation ManyGInvites ($group: ID!, $recipients:[InputSelect]!) {
    createGroupInvite (group: $group, recipients: $recipients){
      status
      errors
    }
  }
`;

export const JoinGroupMutation = gql`
mutation ($group : ID!) {
  joinGroup(group: $group){
    status
    errors
  }
}`

export const AcceptIntoGroupMutation = gql`
mutation ($group : ID!, $user: ID!) {
  acceptIntoGroup(group: $group, user: $user){
    status
    errors
  }
}`

export const RejectIntoGroupMutation = gql`
mutation ($group : ID!, $user: ID!) {
  rejectIntoGroup(group: $group, user: $user){
    status
    errors
  }
}`

export const LeaveGroupMutation = gql`
mutation ($group : ID!) {
  leaveGroup(group: $group){
    status
    errors
  }
}`


export const RespondGInviteMutation = gql`
mutation ($invite: ID!, $choice: RESPONSE_CHOICES) {
  decideGroupInvite(invite: $invite, choice: $choice) {
    status
    errors
  }
}
`
