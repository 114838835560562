import React, { useState, Component, useEffect } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { Error }  from '../Generic/forms';
import {UploadHandler} from '../Generic/AttachmentPreview';
import {SelectWrapped} from '../Search/SearchRes';
import Circular from '../Loading/Circular';

const AddEventMut = gql`
  mutation AddEvent($group: ID, $details:EventInput!){
    createEvent(group: $group, details: $details){
      event {
        id
      }
      status
      errors
    }
  }
`;

const AddEventStub = function (props) {

  const classes = useStyles();
  const TextArea = React.useRef();

  const [Type, setType] = useState('text');
  const [Loading, setLoading] = useState(false);
  const [Hidden, setHidden] = useState(false);
  const [ZipCode, setZipCode] = useState('');
  const [Title, setTitle] = useState('');
  const [StartsAt, setStartsAt] = useState('');
  const [EndsAt, setEndsAt] = useState('');
  const [Description, setDescription] = useState('');
  const [Attachments, setAttachments] = useState([]);
  const [Errors, setErrors] = useState(null);
  const [CategoryValue, setCategoryValue] = useState(null);

  useEffect(() => {
    if (TextArea){
      console.log(TextArea)
      let text_area = TextArea.current;
      if (text_area === undefined || !text_area) {
        return
      }
      text_area.style.height = 'auto';
      text_area.style.height = text_area.scrollHeight+'px';
    }
  }, [Description]);

  const handlePost = () => {
    const { panel, group, history } = props;
    setLoading(true)

    props
      .mutate({
        variables: {
          'group': group,
          'details':{
            hidden: Hidden,
            zipCode: ZipCode,
            title: Title,
            startsAt: StartsAt,
            endsAt: EndsAt,
            description: Description,
            newCategories: CategoryValue ? CategoryValue.map(o => o.label): null,
            attachments: Attachments,
          },
        },
        refetchQueries: ['newEvent', 'eventsThisWeek'],
      })
      .then(({ data, loading }) => {
        if (loading) {
          return "Loading...";
        }
        if (data.error) {
          setLoading(false)
          return null;
        }
        if (data.createEvent.status === 200) {
          console.log(data)
          setDescription('')
          setLoading(false)
          if (props.cb) {
            props.cb();
          }
          if (data.createEvent.event) {
            history.push(`/detail/event/${data.createEvent.event.id}`);
          }
        }
        if (data.createEvent.status === 500) {
          console.log('Something went wrong while adding Event');
          setErrors(data.createEvent.errors)
          setLoading(false)
        }
      }).then(() => {});
  };


  if (Loading){
    return (<Circular/>);
  }

  let postable;
  if (Title.length === 0){
     postable = false;
  } else {
     postable = true;
  }

  return (
    <div>
      <Grid wrap="nowrap" spacing={8}
        syle={{overflow: 'visible'}}
        alignItems="stretch" >
      <Grid item>
      <form
        syle={{overflow: 'visible'}}
        className={classes.wrapper} noValidate autoComplete="off">
        <div>
          <Error errors={Errors} name={'__all__'} />
          <FormControl className={classes.formControl}>
            <Error errors={Errors} name={'new_categories'} />
            <div>
            <SelectWrapped
              fullWidth
              className={classes.input}
              value={CategoryValue}
              onChange={setCategoryValue}
              name="react-select-chip"
              name={'resource'}
              placeholder={"Category (e.g. Mental Health, Transportation)"}
              multi={true}
              instanceId={'react-select-chip'}
              id={'react-select-chip'}
              simpleValue={true}
            />
            </div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Error errors={Errors} name={'zip_code'} />
            <Input
              id="zips"
              type={'text'}
              fullwidth={true}
              className={classes.formControl}
              value={ZipCode}
              onChange={e => setZipCode(e.target.value)}
              inputProps={{
                placeholder: "Location (e.g. Street name, city, zip code)",
                className:classes.input,
              }}
            />
          </FormControl>
          <FormControl
            syle={{overflow: 'visible'}}
            className={classes.formControl}>
            <Error errors={Errors} name={'starts_at'} />
            <Error errors={Errors} name={'ends_at'} />
            <span>
              <Icon color="action">
                date_range
              </Icon>
             <TextField
                id="date"
                type={ Type }
                onFocus={ () => setType('date') }
                onBlur={ () => setType('text') }
                className={classes.textField}
                onChange={e => setStartsAt(e.target.value)}
                InputProps={{
                  onChange: e => setStartsAt(e.target.value),
                  placeholder: "Start date mm/dd/yyyy",
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                  }}
              />
              <TextField
                id="date"
                type={ Type }
                onFocus={ () => setType('date') }
                onBlur={ () => setType('text') }
                className={classes.textField}
                onChange={e => setEndsAt(e.target.value)}
                InputProps={{
                  onChange: e => setEndsAt(e.target.value),
                  placeholder: "End date mm/dd/yyyy",
                  className:classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </span>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Error errors={Errors} name={'title'} />
            <Input
              id="title"
              type={'text'}
              fullwidth={true}
              inputProps={{
                placeholder: "Event title (e.g. FREE CEU - Understanding trauma survivors)",
                }}
              className={classes.formControl}
              value={Title}
              onChange={e => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Error errors={Errors} name={'description'} />
            <UploadHandler
              setFiles={setAttachments}
              >
              <Input
                id="Event"
                type={'text'}
                fullwidth={true}
                multiline={true}
                inputRef={TextArea}
                inputProps={{
                  placeholder: "Event description",
                  }}
                rows={4}
                className={classes.formControl}
                value={Description}
                onChange={e => setDescription(e.target.value)}
              />
            </UploadHandler>
          </FormControl>
          { postable && (
            <Button
              size="small"
              variant="outlined"
              raised
              color="primary"
              className={classes.button}
              onClick={handlePost}
            >
              Post
            </Button>
          )}
        </div>
      </form>
      </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  dragActive:{
    borderColor: theme.palette.primary.A500,
    borderRadius: theme.spacing.unit,
    border: 'solid',
  },
  label: {
    textTransform: 'none',
  },
  input: {
    width:'100%',
    height:'100%',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  wrapper: {
    display: 'block',
  },
  formControl: {
    margin: theme.spacing.unit,
    display: 'flex',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    //zIndex: '2000 !important',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  }
}));

const AddEvent = graphql(AddEventMut)(AddEventStub);

AddEvent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(AddEvent);
