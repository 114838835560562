import React from 'react';

import gql from 'graphql-tag';

export const UserFragment = gql`
  fragment BasicUserInfo on UserType {
      id
      dateJoined
      username
      firstName
      lastName
      fullName
      zipCode
      isActive
      institution
      position
      expertiseStr
      profilePicSm
  }
`;
