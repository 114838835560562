import React from 'react';
import PropTypes from 'prop-types';
import Creatable from 'react-select/creatable';

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';

import { graphql } from 'react-apollo';
import { Mutation } from "react-apollo";
import gql from 'graphql-tag';

import ToolTipStyle from '../../Generic/ToolTipStyle';

const GroupsOptionsQuery = gql`
  query EasyGroups {
    allUserBookmarkGroups{
      name
      slug
    }
  }
`;

const DeleteBookmarkMut = gql`
  mutation DeleteBookmark($resourceId:String!, $typename:String!){
    removeBookByRes (resourceId: $resourceId, typename: $typename ) {
      status
    }
  }
`;

const addBookManyGroupsMut = gql`
  mutation ManyGroupsBook ($resourceId: String!, $typename:String!, $tt:[InputSelect]!) {
    addBookManyGroups (multiGroup: $tt, resourceId: $resourceId, typename:$typename){
      status
    }
  }
`;

const styles = theme => ({
  root: {
    padding: `5 ${theme.spacing.unit * 3}px`,
  },
  button: {
    margin: theme.spacing.unit,
  },
  volume: {
    minHeight: theme.spacing.unit * 20,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  tooltip: ToolTipStyle(theme),
});


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class UtilityDialog extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      multi: true,
      multiValue: [],
      defaults: [
                { value: 'transportation', label: 'Transportation' },
                { value: 'food', label: 'Food' },
                { value: 'utilities', label: 'Utilities' },
      ],
      value: undefined,
    };
    this.handleClose.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }
  handleClose = () => {
    this.props.onClose('test');
  };

  handleOnChange(value) {
    const multi = this.state;
    if (multi) {
      this.setState({ multiValue: value });
    } else {
      this.setState({ value });
    }
  }

  render() {
    const { classes, onClose, resource, typename, data, ...other } = this.props;
    const { multi, multiValue, defaults, value } = this.state;

    if (data.eror) {
      console.log(data.error);
      return (<p>error</p>);
    }

    if (data.loading) {
      return (<spand>Loading ...</spand>);
    }
    const options = data.allUserBookmarkGroups.map(group => ({ value: group.slug, label: group.name }));

    return (
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        scroll={'body'}
        maxWidth={'md'}
        onClose={this.handleClose} aria-labelledby="Utility-dialog-title" {...other}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="Utility-dialog-title">Bookmark</DialogTitle>
        <DialogContent>
          <div className={classes.volume}>
            <Creatable
              isMulti={multi}
              options={options}
              onChange={this.handleOnChange}
              value={multi ? multiValue : value}
              placeholder={ 'Select group(s), or type to create your own' }
            />
          </div>
        </DialogContent>
        <DialogActions>
        <Mutation mutation={addBookManyGroupsMut}>
          {(addAll, { data }) => (
              <Button
                style={{ marginTop: 5 }}
                onClick={ e => {
                  addAll({
                    variables: { resourceId: resource.id, typename, tt: multi ? multiValue.map(v => ({value: v.value, label: v.label})) : {value: value.value, label: value.label}},
                    refetchQueries: [
                      'PagedResourceSearch',
                      'DetailResourceQuery',
                      'searchBookmarksPaged',
                      'UserBookmarks'
                    ],
                  })
                  .then( data =>
                    this.handleClose()
                  );
                }}
              >
                Create Bookmark
              </Button>
            )}
          </Mutation>
          <Mutation mutation={DeleteBookmarkMut}>
            {(remAll, { data }) => (
              <Button
                style={{ marginTop: 5 }}
                onClick={ e => {
                  remAll({
                    variables: { resourceId: resource.id, typename },
                    refetchQueries: ['PagedResourceSearch', 'UserBookmarks'],
                  })
                  .then( data =>
                    this.handleClose()
                  );
                }}
              >
                Remove Bookmark
              </Button>
            )}
          </Mutation>
          <Button onClick={this.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

UtilityDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

const UtilityDialogWrapped = withStyles(styles)(graphql(GroupsOptionsQuery)(UtilityDialog));

class ManyButton extends React.Component {
  constructor(props){
    super(props);
    this.state = {
          open: false,
        };
  }

  handleClickOpen = () => {
    this.setState({ open: true, });
  };

  handleClose = value => {
    this.setState({ open: false });
  };

  render() {
    const { classes, resource, ...rest} = this.props;
    return (
      <React.Fragment>
        <Tooltip classes={classes} title="Add to bookmarks" placement="bottom">
        <IconButton
          color={resource.bookmarked ? "primary": "secondary"}
          className={classes.button}
          aria-label="Add an alarm"
          onClick={this.handleClickOpen}
        >
          <Icon>
            bookmarks
          </Icon>
        </IconButton>
        </Tooltip>
        <UtilityDialogWrapped
          open={this.state.open}
          onClose={this.handleClose}
          resource={resource}
          {...rest}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ManyButton);

