import { ApolloClient } from 'apollo-client';
import { ApolloLink, concat } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
//import { withClientState } from 'apollo-link-state';
import { HttpLink } from 'apollo-link-http';
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});


const httpLink = createUploadLink({
  uri: `/graphql`,
  credentials: 'same-origin',
});

function filtercookie(name) {
  var filter = new RegExp(name + '=([^;]*)(;|$)');
  let cookies = document.cookie;
  let found = cookies.match(filter);
  if (found) {
    return decodeURIComponent(found[1]);
  }
  return false;
}

// https://dev-blog.apollodata.com/apollo-client-2-0-beyond-graphql-apis-888807b53afe

const hasSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
  );

const csrfMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'X-CSRFTOKEN': filtercookie('csrftoken') ? filtercookie('csrftoken') : null,
    }
  }));

  return forward(operation);
});

// This part will allow live chat and other live Notifications, once we switch
// over too daphne
//const splitlink = ApolloLink.split(
  //hasSubscriptionOperation,
  //concat(
    //csrfMiddleware,
    //new WebSocketLink({
      //uri: 'ws://' + window.location.hostname + ':8000/gql',
      //options: { reconnect: false },
    //}),
  //),
  //concat(csrfMiddleware, httpLink),
//);

const cache = new InMemoryCache({ fragmentMatcher }).restore(window.__APOLLO_STATE__);

// How to set default values.
//const emptyvalues = {
  //RegInviteCode: {
      //code: null,
      //__typename: 'RegInviteCode',
    //},
//};
//const resolvers = { [> ... <] };
//const defaults = Object.assign({}, emptyvalues);
//const stateLink = withClientState({
      //resolvers,
      //cache,
      //defaults
//});

function delete_cookie( name ) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const client = new ApolloClient({
  link: concat(csrfMiddleware, httpLink),
  cache,
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      delete_cookie('csrftoken');
      window.location.reload();
    }
  },
});
