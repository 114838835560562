import React, { useState } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Circular from '../Loading/Circular';
import {UploadHandler} from '../Generic/AttachmentPreview';
import { client } from '../../utils/makeApolloClient';
import './AutoSug.css';

const AddCommentMut = gql`
  mutation AddComment($text:String!, $pid:ID!, $attachments:[Upload], $replied: ID){
    addComment (text: $text, panel: $pid, attachments: $attachments, replied: $replied) {
      comments{
        createdAt
        text
        id
        author{
          id
          username
          email
        }
        hidden
      }
      status
    }
  }
`;

const AutoSugQuery = gql`
  query AutoComplete ($token: String!){
    usernameSug (username: $token) {
      username
      fullName
    }
  }
`;

const GroupAutoSugQuery = gql`
  query GroupAutoComplete ($token: String!){
    groupSug (name: $token) {
      name
    }
  }
`;

const Item = ({ entity: { name, char } }) => <div style={{paddingBottom: '10px'}}><strong>{char}</strong> <span style={{float: 'right',}}><span style={{margin: '1rem'}}/>@{name}</span></div>;
const GroupItem = ({ entity: { name } }) => <div style={{paddingBottom: '10px'}}>#<strong>{name}</strong></div>;

const Loading = ({ data }) => <div>Loading</div>;
const InPo = React.forwardRef((props, ref) => {
  const { onChange, onBlur, onClick, onKeyDown, onScroll, onSelect } = props;
  return (
    <Input
      id="comment"
      type={"text"}
      multiline={true}
      rows={2}
      style={{
        display: 'flex',
        width: '100%',
        resize: 'vertical',
      }}
      inputProps={{
        style:{
          resize: 'vertical',
        },
        onChange,
        // onBlur, disabled because the callback inside Input has been passed wrongly
        onClick,
        onKeyDown,
        onScroll,
        onSelect
      }}
      value={props.value}
      inputRef={ref}
    />
  );
});


const AddCommentStub = function (props) {
  const [IsLoading, setIsLoading] = useState(false);
  const [IsBlocking, setIsBlocking] = useState(false);
  const [Text, setText] = useState('');
  const [TextArea, setTextArea] = useState(null);
  const [Attachments, setAttachments] = useState(null);
  const [Replied, setReplied] = useState(null);

  const handleText = event => {
    setText(event.target.value)
    const resize = () => {
        const textarea = TextArea;
        if (!textarea || textarea === undefined) {
          return
        }
        textarea.style.height = 'auto';
        textarea.style.height = TextArea.scrollHeight+'px';
    }
    resize()
  };

  const handleReplyTo = (username, replied)=> event => {
    event.preventDefault();
    setText("@" + username + " ");
    setReplied(replied);
    setIsBlocking(true);
    const resize = () => {
        const textarea = TextArea;
        if (!textarea || textarea === undefined) {
          return
        }
        textarea.style.height = 'auto';
        textarea.style.height = TextArea.scrollHeight+'px';
        textarea.focus();
    }
    resize()
  };

  const handlePost = () => {
    const { panel } = props;
    if (Text.length === 0){
      return null
    }
    setIsLoading(true);
    props
      .mutate({
        variables: { 'text': Text, 'pid':panel, 'attachments': Attachments, 'replied': Replied},
        refetchQueries: ['DetailPanel', 'TimeLine', 'UnreadNoti'],
      })
      .then(({ data }) => {
        if (data.error) {
          setIsLoading(false);
          return <p>{data.error.message}</p>;
        }
        if (data.addComment.status === 200) {
          console.log(data)
          setText('');
          setIsBlocking(false);
          setIsLoading(false);
          if (props.cb) {
            props.cb();
          }
        }
        if (data.addComment.status === 420) {
          setIsLoading(false);
          console.log('Something went wrong while adding comment');
          return <p>{data.comments.status}</p>;
        }
      }).then(() => window.scrollTo(0, 0));
  };

  const { classes, theme, panel } = props;

  if (IsLoading){
    return (<Circular/>);
  }

  let postable;
  if (Text.length === 0){
     postable = false;
  } else {
     postable = true;
  }

  const empty = () => null;


  return (
    <>
    {(props.children || empty)(handleReplyTo)}
    <div>
      <Grid wrap="nowrap" spacing={8} alignItems="stretch" >
      <Grid item>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={() => null}>
        <div>
          <Prompt
            when={IsBlocking}
            message={location => `Are you sure you want to leave ${window.location.pathname}`}
          />
          <InputLabel htmlFor="comment"><strong>Click here to leave a reply</strong></InputLabel>
          <FormControl className={classes.formControl}>
            <UploadHandler
              setFiles={setAttachments}
              >
            <ReactTextareaAutocomplete
              className={classes.fullwidth}
              itemStyle={{background: theme.palette.primary.main}}
              innerRef={textarea => {
                setTextArea(textarea)
              }}
              loadingComponent={Loading}
              value={Text}
              onChange={handleText}
              textAreaComponent={InPo}
              dropdownStyle={{zIndex: 2, marginTop: '2rem'}}
              minChar={1}
              trigger={{
                "@": {
                  afterWhitespace: true,
                  dataProvider: async token => {
                    const usernames = await client.query({
                        query: AutoSugQuery,
                        variables: { token }
                      }).then( result => {
                        const sug = result.data.usernameSug.map(u => ({ name: u.username, char: u.fullName}))
                        return sug; });

                   return usernames;
                  },
                  component: Item,
                  output: (item, trigger) => `${trigger}${item.name}`
                },
                "#": {
                  allowWhitespace: true,
                  dataProvider: async token => {
                    var lastIndex = token.lastIndexOf('#')
                    console.log(lastIndex)
                    var sanitized_token = token.slice(lastIndex + 1)
                    console.log(token, sanitized_token)
                    const names = await client.query({
                        query: GroupAutoSugQuery,
                        variables: { token: sanitized_token }
                      }).then( result => {
                        const sug = result.data.groupSug.map(u => ({ name: u.name}))
                        return sug; });

                   return names;
                  },
                  component: GroupItem,
                  output: (item, trigger) => `${trigger}${item.name.replace(/ /g, "_")}`
                }
              }}
            />
            </UploadHandler>
          </FormControl>
          { postable && (
            <React.Fragment>
            <span style={{paddingLeft:'4px', paddingRight: '4px'}} />
            <Button
              size="small"
              variant="outlined"
              raised
              color="primary"
              className={classes.button}
              onClick={handlePost}
            >
              Post Reply
            </Button>
            </React.Fragment>
          )}
        </div>
      </form>
      </Grid>
      </Grid>
    </div>
  </>
  );
}

const styles = theme => ({
  dragActive:{
    borderColor: theme.palette.primary.A500,
    borderRadius: theme.spacing.unit,
    border: 'solid',
  },
  autoitem:{
    backgroundColor: theme.palette.primary.A500,
  },
  fullwidth: {
    width: '100%',
  },
  label: {
    textTransform: 'none',
  },
  root: {
    display: 'block',
  },
  formControl: {
    margin: theme.spacing.unit,
    display: 'flex',
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const AddComment = withStyles(styles, { withTheme: true })(graphql(AddCommentMut)(AddCommentStub));

AddComment.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default Login;
export default AddComment;
