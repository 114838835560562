import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import AsyncSelect from 'react-select/async';

import { client } from '../../utils/makeApolloClient';
import gql from 'graphql-tag';

const GroupAutoSugQuery = gql`
  query GroupAutoComplete ($token: String!, $num: Int){
    groupSug (name: $token, show: $num) {
      id
      name
    }
  }
`;


export const SingleGroupSug =  function (props) {
  const { classes, value, name, onChange, inputRef, ...other } = props;

  const fetchOptions = async function(input, def_options){
    const groups = await client.query({
        query: GroupAutoSugQuery,
        variables: { token: input, num: 100}
    }).then( result => {
      let options;
      if (result.data.groupSug){
        options = result.data.groupSug.map(o => ({
          value: o.id,
          label: o.name,
        }));
        options.sort(function(a, b) { return a.label.length - b.label.length; })
      }
      else {
        options = def_options;
      }
      return options
    });
    return groups
  }

  const getOptions = async function (input) {
    let def_options = [{ value: null, label: 'main page', }];
    let groups;

    if (input === ''){
      var promise1 = new Promise(async function(resolve, reject) {
          groups = await fetchOptions(input, def_options)
          resolve(def_options.concat(groups));
      });
      return promise1;
    }

    groups = fetchOptions(input, def_options)
    if (groups.length == 0){
        return def_options;
    }
    return groups;
  }


  return (
      <AsyncSelect
        ref={inputRef}
        name="group-autosuggest"
        clearable
        isSearchable
        value={value}
        cacheOptions
        defaultOptions
        noOptionsMessage={() => (<Typography>{'No results found'}</Typography>)}
        onChange={ onChange }
        autoload={false}
        loadOptions={getOptions}
        {...other}
      />
  );
}
