import React, { Component } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { graphql } from 'react-apollo';
import { Mutation } from "react-apollo";
import gql from 'graphql-tag';

import {PageResourceSearchQuery} from './ResourceSearch';


const AddPrivateNoteMut = gql`
  mutation AddPrivateNote($text: String!, $id: ID!) {
    addNote(note: $text, resource: $id){
      notes{
        id
        note
        createdAt
        modifiedAt
      }
      status
    }
  }
`;

const EditPrivateNoteMut = gql`
  mutation EditPrivateNote($text: String!, $id: ID!) {
    editNote(text: $text, note: $id) {
      status
      note {
        id
        note
        createdAt
        modifiedAt
      }
    }
  }
`;

const DeletePrivateNoteMut = gql`
  mutation EditPrivateNote($id: ID!) {
    removeNote (note: $id){
      status
    }
  }
`;

class AddNoteStub extends Component {
  constructor(props){
    super(props);
    this.state = {
      isBlocking: false,
      text: '',
      showEdit: false,
    };
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handlePost = addNote => event => {
      event.preventDefault();
      const {resource, cb} = this.props;
      console.log('here')
      addNote({
        variables: { text: this.state.text, id: resource },
        refetchQueries: [
          'DetailResourceQuery',
        ],
      })
      .then(result =>{
        if (result.data.addNote.status ===200 ){
          this.setState({showEdit: false, text: ''})
          if(cb){
            cb();
          }
        }
      });
  }

  render() {
    const { classes, text } = this.props;
    const { showEdit } = this.state;
    let edit;
    if (showEdit){
      edit = (
       <Mutation mutation={AddPrivateNoteMut}>
        {(addNote, { data }) => (
          <form
            className={classes.root}
            noValidate
            onSubmit={this.handlePost(addNote)}
            autoComplete="off"
          >
          <div className={classes.wrapper}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="add-note">Add Note:</InputLabel>
              <Input
                id="add-note"
                type={'text'}
                fullwidth={true}
                multiline={true}
                rows={4}
                value={this.state.text}
                onChange={this.handleChange('text')}
              />
              <Button raised
                color="primary"
                className={classes.button}
                onClick={this.handlePost(addNote)}
              >
                Submit change
              </Button>
            </FormControl>
            </div>
          </form>
        )}
       </Mutation>
      );
    }

    return (
      <div>
      {edit}
      <Typography variant='body1' align='left' gutterBottom>
        <Button variant="text" className={classes.button} onClick={e => {
          e.preventDefault();
          this.setState({ showEdit: true });
        }}>
          Private Notes <AddIcon />
        </Button>
      </Typography>
      </div>
    );

  };
}

class NoteStub extends Component {
  constructor(props){
    super(props);
    this.state = {
      isBlocking: false,
      text: '',
      showEdit: false,
    };
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handlePost = editNote => event => {
      event.preventDefault();
      const { item, cb } = this.props;
      console.log('here')
      editNote({
        variables: { text: this.state.text, id: item.id },
        refetchQueries: ['resource', 'DetailResourceQuery'],
      })
      .then(result =>{
        if (result.data.editNote.status ===200 ){
          this.setState({showEdit: false})
          if(cb){
            cb();
          }
        }
      });
  }

  handleDelete = removeNote => event => {
    event.preventDefault();
    const { item, cb } = this.props;
    removeNote({
      variables: { id: item.id },
      refetchQueries: ['resource', 'DetailResourceQuery'],
    })
    .then(result =>{
      if (result.data.removeNote.status ===200 ){
        this.setState({showEdit: false, isBlocking: false})
        if(cb){
          cb();
        }
      }
    });
  }

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowEdit = () => {
    this.setState({ showEdit: !this.state.showEdit });
  };

  render() {
    const { classes, item } = this.props;
    const { isBlocking, showEdit } = this.state;

    if (showEdit) {
      return (
       <Mutation mutation={EditPrivateNoteMut}>
        {(editNote, { data }) => (
        <div className={classes.wrapper}>
          <form
            className={classes.root}
            noValidate
            onSubmit={this.handlePost(editNote)}
            autoComplete="off"
          >
              <Prompt
                when={isBlocking}
                message={location => `Are you sure you want to leave ${window.location.pathname}`}
              />
              <br />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="edit-note">Edit Note:</InputLabel>
                <Input
                  multiline
                  id="edit-note"
                  type={'text'}
                  value={this.state.text}
                  onChange={this.handleChange('text')}
                />
              </FormControl>
              <Button raised
                onClick={e => {
                }}
                color="primary"
                className={classes.button}
                onClick={this.handlePost(editNote)}
              >
                Submit change
              </Button>
          </form>
        </div>
        )}
       </Mutation>
      );
    }
    return (
      <Paper className={classes.notes}>
        <Typography className={classes.newline} variant='body1' align='left' gutterBottom>
        {item.note}
        <span className={classes.fright}>
         <IconButton
          color="secondary"
          className={classes.button}
          aria-label="Add an alarm"
          onClick={e => {
            e.preventDefault();
            this.setState({showEdit: true, text: item.note})
          }}
        >
            <Icon>
              edit
            </Icon>
          </IconButton>
       <Mutation mutation={DeletePrivateNoteMut}>
        {(removeNote, { data }) => (
         <IconButton
          color="secondary"
          className={classes.button}
          aria-label="delete an alarm"
          onClick={this.handleDelete(removeNote)}
        >
            <Icon>
              delete
            </Icon>
          </IconButton>
        )}
       </Mutation>
        </span>
        </Typography>
        <Typography variant='caption' align='right' gutterBottom>
          Posted by you |
          Created {moment(item.createdAt).fromNow()} |
          Last Modified{' '} {moment(item.modifiedAt).fromNow()}
        </Typography>
      </Paper>
    );

  }
}

const styles = theme => ({
  root: {
    flexWrap: 'wrap',
  },
  notes: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  wrapper: {
    margin: "0 auto",
    align: "center",
  },
  formControl: {
    display: 'flex',
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  },
  newline:{
    whiteSpace: 'pre-wrap',
  },
});

const Note = withStyles(styles)(NoteStub);
const AddNote = withStyles(styles)(AddNoteStub);

Note.propTypes = {
  classes: PropTypes.object.isRequired,
};

export {AddNote};
export default Note;
