import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

// import InviteList from './ListInvitations.js';

import ToolTipStyle from './ToolTipStyle';



const styles = theme => ({
  autoFlow: {
    overflowX: 'auto',
  },
  pushRight: {
    marginLeft: theme.spacing.unit ,
  },
  button: {
    textTransform: 'none',
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 4,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit / 4,
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  tooltip: ToolTipStyle(theme),
});

function replyToProto(props) {
    const { classes, onClick } = props;
    return (
      <React.Fragment>
        <div className={classes.pushRight}>
        <Tooltip classes={classes} title="Invite" placement="bottom">
          <div className={classes.vertical}>
          <IconButton
            onClick={onClick}
            className={classes.button}
            aria-label="Invite someone."
            >
            <Icon color={'secondary'} fontSize={'small'} style={{transform: 'scaleX(-1)'}}fontSize={'small'} > reply </Icon>
          </IconButton>
          <Typography variant='caption' gutterBottom aligh='right'>
            Reply To
          </Typography>
          </div>
        </Tooltip>
        </div>
      </React.Fragment>
    );
}

let ReplyTo = withRouter(withStyles(styles)(replyToProto));

export { ReplyTo };
