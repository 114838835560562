import React from 'react';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles'
import { emphasize, fade, darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';

const styles = theme => {
  return {
    typhy:{
      width: '100%',
      height: '100%',
      fontSize: '1vmax'
    },
    white: {
        color: 'white',
    },
    green: {
      color: theme.palette.primary[500],
      '&:hover': {
        color: 'white !important',
      }
    },
    messageContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%',
      marginBottom: theme.spacing.unit * 2,
    },
    tab: {
      flexGrow: 100,
      overflow: 'hidden',
      border: '1px solid #ccc',
      backgroundColor: 'white',
    },
    active:{
      backgroundColor: `${theme.palette.primary[500]} !important`,
    },
    fullinks: {
      width: '100%',
    },
    tablinks: {
      width: '100%',
      flexGrow: 100,
      float: 'left',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      padding: '14px 16px',
      transition: '0.3s',
      fontSize: '17px',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.dark, .55),
        color: 'white !important',
      }
    },
    child: {
      backgroundColor: 'inherit',
    },
  }
}

const SiteMessage = (props) => {

  const [Text, setText] = React.useState(``);
  const {classes, home, events, announ, opport} = props;

  return (
    <div className={`${classes.messageContainer} ${classes.info} ${classes.tab}`}>
     <Link to={`/`} className={classes.fullinks}>
      <button className={`${home ? classes.active: null} ${classes.tablinks}`}>
      <Typography variant='body1' className={`${home ? classes.white : classes.green} ${classes.typhy}`}>
        <strong>
        Home
        </strong>
      </Typography>
      </button>
      </Link>
      <Link to={`/all/events`} className={classes.fullinks}>
      <button className={`${events ? classes.active: null} ${classes.tablinks}`}>
      <Typography variant='body1' className={`${events ? classes.white : classes.green } ${classes.typhy}`}>
        <strong>
        Events
        </strong>
      </Typography>
      </button>
      </Link>
      <Link to={`/all/opportunities`} className={classes.fullinks}>
      <button className={`${opport ? classes.active: null} ${classes.tablinks}`}>
      <Typography variant='body1' className={`${opport ? classes.white : classes.green } ${classes.typhy}`}>
        <strong>
        Opportunities
        </strong>
      </Typography>
      </button>
      </Link>
    </div>
  )
}

export default withStyles(styles)(SiteMessage);

