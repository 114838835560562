import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}));

function Pager (props) {

  //const [current, setCurrent] = React.useState(props.current);
  const [totalPages, settotalPages] = React.useState(props.totalPages);
  const classes = useStyles();

  useEffect(() => {
    settotalPages(props.totalPages)
  },[props])

  return (
    <span className={classes.root}>
      <Pagination
        count={totalPages}
        variant="outlined"
        shape="rounded" showFirstButton showLastButton page={props.current}
        onChange={(e, value) => {
          if (props.pageChanged){
            props.pageChanged(value)
            console.log('page changing', value)
          }
          window.scrollTo(0, 0);
        }}/>
    </span>
  );
}

Pager.defaultProps = {
  totalDisplayed: 5,
  current: 1,
};

export default Pager;

