import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const ToolTipStyle = theme => (
  {
    backgroundColor: theme.palette.grey.A700,
    color: 'white',
    fontFamily: theme.typography.fontFamily,
    padding: '4px 8px',
    fontSize: theme.typography.pxToRem(20),
    lineHeight: `${theme.typography.round(14 / 10)}em`,
    maxWidth: 300,
  }
)

const useStyles = makeStyles(theme => ({
  tooltip: ToolTipStyle(theme),
}));

export function StyledTooltip (props) {
  const classes = useStyles()
  const {children, ...rest} = props;
  return (
    <Tooltip classes={classes} {...rest}>
      {children}
    </Tooltip>
  );
}

export default ToolTipStyle;
