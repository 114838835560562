import React from 'react';
import PropTypes from 'prop-types';

import { Link, useParams, useHistory } from 'react-router-dom';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import NewAnn from './NewAnnouncements';
//import NewEvent from './NewEvents';
//import NewJobs from './NewJobs';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    top: 'Calc(4rem)',
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
}));

const styles = theme => ({
  root: {
    height: '100vh',
    top: 'Calc(4rem)',
  },
  menuButton: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: 12,
    marginRight: 36,
  },
  drawerPaper: {
    backgroundColor: theme.palette.background.default,
    top: 'Calc(10rem)',
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  end: {
    padding: '0 8px',
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing.unit * 10,
  },
  fright: {
    float: 'right',
  },
  fleft: {
    float: 'left',
  }
});

class MiniDrawer extends React.Component {
  state = {
    width: '',
    open: true,
    ready: false,
  };

  static getDerivedStateFromProps(props, state){
    const {width} = props;
    if (width !== state.width){
      if( width === 'sm' || width === 'xs'){
       return {open: false, width: width}
      } else {
       return {open: true, width: width, ready: true}
      }
    } else {
      return {ready: false}
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <div
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && `${classes.drawerPaperClose} ${classes.fright}`),
          }}
          open={this.state.open}
        >
          <div className={classes.end} onClick={this.handleDrawerToggle}>
            {theme.direction === 'rtl' ? null: null}
          </div>
          { this.state.open &&
            (
              <React.Fragment>
              {/**<NewEvent />**/}
              <NewAnn />
              {/**}<NewJobs />**/}
              </React.Fragment>
            )
          }
        </div>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export function SponsorGroups(props) {

  const classes = useStyles();

  const { loading, error, data, refetch: ReStagedPaged, fetchMore} = useQuery(
    gql`
    query ($page: Int){
      sponsorGroups (page: $page){
        pageInfo {
          Count
          CurrentPage
          NumPages
        }
        edges {
          node {
            id
            name
            groupLogo
          }
        }
      }
    }

    `,
    {
      variables: { page: 1},
      fetchPolicy:'cache-and-network' // skip the cache
    }
  );

  return (
    <>
    {data ? data.sponsorGroups.edges.map(edge => (
      <div className={classes.root}>
      <ButtonBase
      focusRipple
      key={edge.node.name}
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      >
      <Link to={`/detail/group/${edge.node.id}/search/SP/`} className={classes.noUnderline}>
      <img
        src={edge.node.groupLogo}
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
      </Link>
      <span className={classes.imageBackdrop} />
      </ButtonBase>
      </div>

    )) : (null) }
    </>
  )
}

export default withWidth()(withStyles(styles, { withTheme: true })(MiniDrawer));
