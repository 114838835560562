import gql from 'graphql-tag';

export const EditGenCommentMut = gql`
  mutation EditGenComment ($id: ID!, $text: String!){
    editGenComment(comment: $id, text: $text) {
      status
      errors
    }
  }
`;

export const AddGenCommentMut = gql`
  mutation AddGenComment($text:String!, $id:ID!, $attachments: [Upload], $replied: ID){
    addGenComment (text: $text, owner: $id, attachments: $attachments, replied: $replied) {
      comments{
        createdAt
        text
        id
        author{
          id
          username
          email
        }
        hidden
      }
      status
    }
  }
`;
