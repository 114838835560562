import React, { useState, useRef } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { Error }  from '../Generic/forms';
import {UploadHandler} from '../Generic/AttachmentPreview';
import { SingleGroupSug }  from '../Generic/GroupSuggest.js';
import {SelectWrapped} from '../Search/SearchRes';
import Circular from '../Loading/Circular';

const AddAnnMut = gql`
  mutation AddAnn($group: ID, $details:AnnouncementInput!){
    createAnnouncement(group: $group, details: $details){
      announcement {
        id
      }
      status
      errors
    }
  }
`;

const AddAnnStub = function (props) {
  const classes = useStyles();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsHidden, setIsHidden] = useState(false);
  const [IsBlocking, setIsBlocking] = useState(false);
  const [Location, setLocation] = useState('');
  const [Title, setTitle] = useState('');
  const [Description, setDescription] = useState('');
  const [CategoryVal, setCategoryVal] = useState(null);
  const [Group, setGroup] = useState(props.group);
  const TextArea = useRef(null);
  const [Attachments, setAttachments] = useState([]);
  const [Errors, setErrors] = useState(null);

  const handleDescription = event => {
    setDescription(event.target.value)
    const resize = () => {
      let text_area = TextArea.current;
      if (text_area === undefined || !text_area) {
        return
      }
      text_area.style.height = 'auto';
      text_area.style.height = text_area.scrollHeight+'px';
    }
    if (event.target.value.endsWith('\n')){
      resize()
    }
  };

  const handlePost = () => {
    const { panel, history } = props;
    setIsLoading(true)
    props
      .mutate({
        variables: {
          'group': Group.value,
          'details':{
          hidden: IsHidden,
          zipCode: Location,
          description: Description,
          attachments: Attachments,
          newCategories: CategoryVal ? CategoryVal.map(o => o.label): null,
        }},
        refetchQueries: ['newAnn', 'TimeLine'],
      })
      .then(({ data }) => {
        if (data.error) {
          setIsLoading(false)
          return null;
        }
        if (data.createAnnouncement.status === 200) {
          console.log(data)
          setIsLoading(false)
          setDescription('')
          if (props.cb) {
            props.cb();
          }
          if (data.createAnnouncement.announcement) {
            history.push(`/detail/announcement/${data.createAnnouncement.announcement.id}`);
          }
        }
        if (data.createAnnouncement.status === 500) {
          console.log('Something went wrong while adding Ann');
          setErrors(data.createAnnouncement.errors)
          setIsLoading(false)
        }
      }).then(() => {});
  };

  if (IsLoading){
    return (<Circular/>);
  }

  let postable;
  if (Description.length === 0){
     postable = false;
  } else if (Group) {
     postable = true;
  }

  return (
    <div>
      <Grid wrap="nowrap" spacing={8} alignItems="stretch" >
      <Grid item>
      <form className={classes.wrapper} noValidate autoComplete="off" onSubmit={() => null}>
        <div>
          <span className={classes.urgent}>
            <strong style={{ display: 'flex', alignItems: 'center' }}>
              Is this urgent? Click "URGENT REQUEST" tab.</strong>
          </span>
          <Error errors={Errors} name={'__all__'} />
          <FormControl className={classes.formControl}>
            <Error errors={Errors} name={'new_categories'} />
            <div>
            <SelectWrapped
              fullWidth
              className={classes.input}
              value={CategoryVal}
              onChange={setCategoryVal}
              name="react-select-chip"
              name={'resource'}
              placeholder={"Category (e.g. Mental Health, Transportation)"}
              multi={true}
              instanceId={'react-select-chip'}
              id={'react-select-chip'}
              simpleValue={true}
            />
            </div>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Error errors={Errors} name={'description'} />
            <UploadHandler
              setFiles={setAttachments}
              >
            <Input
              id="Ann"
              type={'text'}
              fullwidth={true}
              multiline={true}
              style={{
                display: 'flex',
                width: '100%',
                resize: 'vertical',
              }}
              inputRef={TextArea}
              inputProps={{
                style:{
                  resize: 'vertical',
                },
                placeholder: "Post your resource/non-urgent conversation",
              }}
              rows={4}
              className={classes.formControl}
              value={Description}
              onChange={handleDescription}
            />
            </UploadHandler>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Error errors={Errors} name={'zip_code'} />
            <Input
              id="zips"
              type={'text'}
              fullwidth={true}
              className={classes.formControl}
              value={Location}
              onChange={e => setLocation(e.target.value)}
              inputProps={{
                placeholder: "Location (e.g. Street name, city, zip code)"
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <SingleGroupSug placeholder={'Select where you would like to start the conversation'} value={Group} onChange={setGroup}/>
          </FormControl>
          { postable && (
            <Button
              size="small"
              variant="outlined"
              raised
              color="primary"
              className={classes.button}
              onClick={handlePost}
            >
              Post
            </Button>
          )}
        </div>
      </form>
      </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  dragActive:{
    borderColor: theme.palette.primary.A500,
    borderRadius: theme.spacing.unit,
    border: 'solid',
  },
  label: {
    textTransform: 'none',
  },
  urgent: {
    color: theme.palette.error[500],
  },
  input: {
    width:'100%',
    height:'100%',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  wrapper: {
    display: 'block',
  },
  formControl: {
    margin: theme.spacing.unit,
    display: 'flex',
    fontSize: theme.typography.body1.fontSize + '!important',
    '&::-webkit-input-placeholder': {
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&::-moz-placeholder': { /* Firefox 19+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-ms-input-placeholder': { /* IE 10+ */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
    '&:-moz-placeholder': { /* Firefox 18- */
      color: 'hsl(0,0%,50%)',
      opacity: 1,
    },
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
}));

const AddAnn = (graphql(AddAnnMut)(AddAnnStub));

AddAnn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(AddAnn);
