import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import Logout from '../../screens/Auth/logout.js';
import {Basic} from '../Layouts/parent';


export default function(props){
  window.scrollTo(0, 0);
  return (
    <Basic children={(<Logout/>)} />
  );
}


