import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import ProfileChip from '../Profile/ProfileChip';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

class UserList extends React.Component {

  render() {
    const { classes, content } = this.props;
    if(content){
    return (
        <div className={classes.root}>
          <List dense>
            {content.map((detail, i) => (
              <ListItem key={i} button>
                <ProfileChip
                  user={detail.user}
                  />
                <ListItemText
                  primary={`${moment(detail.createdAt).fromNow()}`} />
              </ListItem>
            ))}
          </List>
        </div>
      );
    }
    return null;
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserList);
