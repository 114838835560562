import React, { Component } from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { client } from '../../utils/makeApolloClient';
import { Error } from '../Generic/forms';

const ValidInvitee = gql`
  query ValidInvitation (
    $invitee: String,
  ){
    valInvitation(
      invitee: $invitee,
    ){
      status
      errors
    }
  }
`;

const InviteMut = gql`
  mutation ($invitee: String!) {
    addInvitation(invitee: $invitee) {
      status
    }
  }
`;

class NewInviteStub extends Component {
    constructor(props){
    super(props);
    this.state = {
      isBlocking: false,
      invitee: '',
      error: null,
      errors: null,
    };
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, isBlocking: true });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleValidation = (e) => {
    const {invitee} = this.state;
    client.query({
      query: ValidInvitee,
      variables: {
        invitee
      }
    }).then( result => {
      if (result.data.loading) return null;
      if (result.data.error) return null;
      if (result.data && result.data.valInvitation.status === 500 ) {
        this.setState({errors: result.data.valInvitation.errors})
      }
      if (result.data && result.data.valInvitation.status === 200 ) {
        this.handlePost(e)
      }
    });
  }

  handlePost = (e) => {
    e.preventDefault()
    const { invitee } = this.state;
    this.props
      .mutate({
        variables: { invitee },
        refetchQueries: ['InvPretty'],
      })
      .then(({ data }) => {
        if ('error' in data) {
          this.setState({ error: data.error });
        }
        if (data.addInvitation.status === 200) {
          const { from } = { from: { pathname: '/' } };
          this.setState({invitee: '', isBlocking: false });
          //window.location.pathname = from.pathname;
          return null
        }
      });
  };

  render() {
    const { classes } = this.props;
    const { from } = { from: { pathname: '/' } };
    const { isBlocking, errors } = this.state;

    return (
      <div className={classes.wrapper}>
        <Error errors={errors} name={'__all__'} />
        <Error errors={errors} name={'invitee'} />
        <form className={classes.root} noValidate autoComplete="off" onSubmit={this.handlePost}>
          <div>
            <Prompt
              when={isBlocking}
              message={location => `Are you sure you want to leave ${window.location.pathname}`}
            />
            <br />
            <div>
            <span className={classes.container}>
              <InputLabel focused={true} >
              Invite someone to join Welnity by entering their email here
              </InputLabel>
            <FormControl className={classes.formControl} required variant="filled">
              <Input
                classes={{input: { background: 'white', color: 'white'}}}
                id="invitee"
                type={'text'}
                value={this.state.invitee}
                onChange={this.handleChange('invitee')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton/>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={this.handleValidation}
            >
              Invite
            </Button>
            </span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    alignContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    margin: "0 auto",
    align: "center",
    alignContent: 'center',
  },
  formControl: {
    margin: theme.spacing.unit,
    backgroundColor: 'white',
    width: '60%',
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

const NewInvite = withStyles(styles)(graphql(InviteMut)(NewInviteStub));

NewInvite.propTypes = {
  classes: PropTypes.object.isRequired,
};

//export default Login;
export default NewInvite;
