import React from 'react';

import gql from 'graphql-tag';
import { GenCommentsFragment } from '../GenComments/Fragments';

export const ResourceFragment = gql`
  fragment ResourceInfo on ResourceType {
      categories{
        edges{
          node{
            category
            id
          }
        }
      }
      privateNotes{
        id
        modifiedAt
        createdAt
        note
      }
      groups{
        id
        name
        slug
      }
      genComments{
        ...genComments
      }
      id
      internalId
      name
      description
      emailContact
      phone
      website
      streetAddress
      city
      state
      latitude
      longitude
      zipCode
      modifiedAt
      createdAt
      bookmarked
      inprintqueue
      keywords
  }
${GenCommentsFragment}
`;

export const ResourceSubmissionFragment = gql`
  fragment ResourceSubmissionInfo on ResourceSubmissionType {
      categories{
        edges{
          node{
            category
            id
          }
        }
      }
      submittedBy {
        id
        username
        fullName
        institution
        position
        profilePicSm
      }
      id
      name
      phone
      address
      website
      details
      createdAt
      modifiedAt
  }
`;
