import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import UserScrollDialog from '../UserListModal/muser';
import {UserFragment} from '../GQL/fragments.js';
import { client } from '../../utils/makeApolloClient';

const WhoThankedQuery = gql`
  query WhoThanked ($inst: ID!){
    whoThanked(instance: $inst) {
      createdAt
      user {
        ...BasicUserInfo
      }
    }
  }
  ${UserFragment}
`;

class WhoThanked extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      thanks: [],
    };
    this.getusers = this.getusers.bind(this);
  }

  getusers = async () => {
    const {instance} = this.props;
    const thanks = await client.query({
      query: WhoThankedQuery,
      variables: {
        inst: instance,
      },
      fetchPolicy: 'network-only',
      cachePolicy: { query: true, data: false },
    }).then( result => {
      let users;
      if (result.data.loading) return [];
      if (result.data.error) return [];
      if (result.data && result.data.whoThanked) {
        console.log(result.data)
        return result.data.whoThanked
      }
    });
    this.setState({thanks})
  }

  render() {
    const { children } = this.props;
    const { thanks } = this.state;
    return (
      <UserScrollDialog
        title='Thanks'
        children={children}
        content={thanks}
        cb={this.getusers}/>
    );
  }
}

export default WhoThanked;
