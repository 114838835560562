import React from 'react';

import gql from 'graphql-tag';
import { GenCommentsFragment } from '../GenComments/Fragments';

export const JobFragment = gql`
  fragment JobInfo on JobType {
    author {
      id
      username
      fullName
      institution
      position
      profilePicSm
    }
    categories {
      edges {
        node {
          id
          category
        }
      }
    }
    attachments{
      attachment
      previewSm
      previewMd
    }
    genComments{
      ...genComments
    }
    createdAt
    modifiedAt
    hidden
    location
    organization
    applicationLink
    title
    description
    id
    likesCount
    liked
  }
${GenCommentsFragment}
`;

